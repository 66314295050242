import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import BuyersTable from 'components/admin/NPH/BuyersTable'
import LeadsTable from 'components/admin/NPH/LeadsTable'
import PensionTotals from 'components/admin/NPH/PensionTotals'
import PensionTypePie from 'components/admin/NPH/PensionTypePie'
import PensionSizePie from 'components/admin/NPH/PensionSizePie'
import PensionTypeBar from 'components/admin/NPH/PensionTypeBar'
import PensionSizeBar from 'components/admin/NPH/PensionSizeBar'
import { getMoments } from 'config/helpers/Helpers'
import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'
import moment from 'moment-timezone'
import PeriodSelect from 'components/general/PeriodSelect'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function NationalPensionHelpline() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const theme = useTheme()
    const api = new AdminAPI()

    const [totals, setTotals] = useState(null)
    const [allBuyers, setAllBuyers] = useState([])
    const [buyers, setBuyers] = useState([])
    const [leads, setLeads] = useState([])
    const [showLeads, setShowLeads] = useState([])
    const [periodLeads, setPeriodLeads] = useState([])
    const [showLeadsStatus, setShowLeadsStatus] = useState('Unallocated')
    const [period, setPeriod] = useState('thisWeek')
    const [companies, setCompanies] = useState([])
    const [returns, setReturns] = useState([])
    const [periodReturns, setPeriodReturns] = useState([])
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    async function getData() {
        try {
            const buyers = await api.getActiveNPHBuyers()
            console.log('buyers: ', buyers)
            const activeBuyers = buyers.filter((buyer) => (buyer.active))

            const leads = await api.getAllNPHLeads()
            console.log('all leads: ', leads)
            leads.sort((a, b) => {
                return b.createdAt - a.createdAt
            })

            let companies = await api.getCompanies()
            const activeCompanies = companies.filter((company) => (company.active))
            activeCompanies.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
            console.log('companies: ', activeCompanies)

            const returns = await api.getReturnRequests()
            console.log('returns: ', returns)
            const pensionReturns = returns.filter((request) => (request.product === 'pensions' && request.status === 'accepted'))

            let dates = getMoments('thisWeek')
            // let dates = {
            //     fromDate: moment('2024-05-01').set('hour', 0).set('minute', 0).set('second', 0),
            //     toDate: moment('2024-05-31').set('hour', 23).set('minute', 59).set('second', 59)
            // }

            let weekLeads = []
            let totals = {
                all: 0,
                allocated: 0,
                rejected: 0
            }
            for (let lead of leads) {
                let created = moment(lead.createdAt);
                if (created.isBetween(dates.fromDate, dates.toDate)) {
                    weekLeads.push(lead)
                    totals.all += 1
                    if (lead.companyId === null && !lead.isValid) {
                        totals.rejected += 1
                    }
                    if (lead.companyId) {
                        totals.allocated += 1
                    }
                    for (let buyer of buyers) {
                        //console.log(lead.companyId)
                        if (buyer.companyId === lead.companyId) {
                            if (buyer['leadCount']) {
                                buyer['leadCount'] += 1
                            }
                            else {
                                buyer['leadCount'] = 1
                            }
                        }
                    }
                }
            }
            console.log('week leads: ', weekLeads)
            console.log('buyers: ', buyers)

            let weekReturns = []
            for (let request of pensionReturns) {
                let created = moment(request.leadCreatedAt)
                if (created.isBetween(dates.fromDate, dates.toDate)) {
                    weekReturns.push(request)
                    for (let buyer of buyers) {
                        //console.log(lead.companyId)
                        if (buyer.companyId === request.companyId) {
                            if (buyer['returnCount']) {
                                buyer['returnCount'] += 1
                            }
                            else {
                                buyer['returnCount'] = 1
                            }
                        }
                    }
                }
            }
            console.log('week returns: ', weekReturns)

            setAllBuyers(buyers)
            setBuyers(activeBuyers)
            setLeads(leads)
            setShowLeads(leads.filter((lead) => lead.companyId === null && lead.isValid))
            setPeriodLeads(weekLeads)
            setCompanies(activeCompanies)
            setTotals(totals)
            setReturns(pensionReturns)
            setPeriodReturns(weekReturns)
            setFromDate(dates.fromDate)
            setToDate(dates.toDate)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updatePeriod(period, fromDate=null, toDate=null) {
        setPeriod(period)
        setLoading(true)
        // if custom period and dates supplied, use them, otherwise get based on period
        let dates = period === 'custom' ? {
            fromDate: moment(fromDate).startOf('day'),
            toDate: moment(toDate).endOf('day')
        } : getMoments(period)
        
        let newLeads = []
        let newBuyers = []
        for (let buyer of buyers) {
            let newBuyer = {...buyer}
            newBuyer['leadCount'] = 0
            newBuyer['returnCount'] = 0
            newBuyers.push(newBuyer)
        }
        console.log('new buyers: ', newBuyers)
        let totals = {
            all: 0,
            allocated: 0,
            rejected: 0
        }
        for (let lead of leads) {
            let created = moment(lead.createdAt);
            if (created.isBetween(dates.fromDate, dates.toDate)) {
                newLeads.push(lead)
                totals.all += 1
                if (lead.companyId === null && !lead.isValid) {
                    console.log('rejected: ', lead.firstName + ' ' + lead.lastName)
                    console.log('createdAt: ', moment(lead.createdAt).format('DD/MM hh:mm:ss'))
                    totals.rejected += 1
                }
                if (lead.companyId) {
                    totals.allocated += 1
                }
                for (let buyer of newBuyers) {
                    if (buyer.companyId === lead.companyId) {
                        buyer['leadCount'] += 1
                    }
                }
            }
        }
        console.log('new buyers: ', newBuyers)

        let newReturns = []
        for (let request of returns) {
            let created = moment(request.leadCreatedAt)
            if (created.isBetween(dates.fromDate, dates.toDate)) {
                newReturns.push(request)
                for (let buyer of newBuyers) {
                    //console.log(lead.companyId)
                    if (buyer.companyId === request.companyId) {
                        buyer['returnCount'] += 1
                    }
                }
            }
        }
        console.log('new returns: ', newReturns)

        setBuyers(newBuyers)
        setPeriodLeads(newLeads)
        setTotals(totals)
        setPeriodReturns(newReturns)
        setFromDate(dates.fromDate)
        setToDate(dates.toDate)
        setLoading(false)
    }

    function updateShowLeadsStatus(showLeadsStatus) {
        // if companyId === null lead is unallocated, if companyId does not exist it is from Start Pension
        let showLeads = []
        if (showLeadsStatus === 'Unallocated') {
            showLeads = leads.filter((lead) => lead.companyId === null && lead.isValid)
        }
        else if (showLeadsStatus === 'Allocated') {
            showLeads = leads.filter((lead) => lead.companyId !== null)
        }
        else if (showLeadsStatus === 'Rejected') {
            //showLeads = leads.filter((lead) => lead.companyId === null && !lead.isValid)
            showLeads = leads.filter((lead) => !lead.companyId && !lead.isValid)
        }
        setShowLeads(showLeads)
        setShowLeadsStatus(showLeadsStatus)
    }

    function updateLead(lead) {
        const index = leads.findIndex(oldLead => (oldLead.leadId === lead.leadId))
        console.log('lead index: ', index)

        let newLeads = [...leads]
        newLeads[index] = lead
        console.log(newLeads)
        //setLeads(newLeads.filter((lead) => lead.companyId === null))
        let showLeads = []
        if (showLeadsStatus === 'Unallocated') {
            showLeads = newLeads.filter((lead) => lead.companyId === null && lead.isValid)
        }
        else if (showLeadsStatus === 'Allocated') {
            showLeads = newLeads.filter((lead) => lead.companyId !== null)
        }
        else if (showLeadsStatus === 'Rejected') {
            showLeads = newLeads.filter((lead) => lead.companyId === null && !lead.isValid)
        }
        console.log('show leads: ', showLeads)
        setShowLeads(showLeads)
        setLeads(newLeads)
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    component={Box}
                                    alignItems='center'
                                    justifyContent='flex-start'
                                    style={{marginBottom: '2rem'}}
                                    direction='row'
                                >
                                    <Grid item xs={12} md={2}>
                                        <PeriodSelect
                                            period={period}
                                            updatePeriod={(period, fromDate, toDate) => updatePeriod(period, fromDate, toDate)}
                                            fromDate={fromDate}
                                            toDate={toDate}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <PensionTotals 
                                    totals={totals}
                                    // all non rejected leads
                                    leads={periodLeads.filter((lead) => lead.companyId || lead.isValid)}
                                    returns={periodReturns}
                                    buyers={buyers}
                                    allBuyers={allBuyers}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BuyersTable 
                                    buyers={buyers}
                                    total={totals.all}
                                    direction='column'
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PensionTypePie
                                    // all leads
                                    leads={periodLeads}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PensionSizePie
                                    // all leads
                                    leads={periodLeads}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PensionTypeBar
                                    // all non rejected leads
                                    leads={periodLeads.filter((lead) => lead.companyId || lead.isValid)} 
                                    returns={periodReturns}
                                    buyers={buyers}
                                    allBuyers={allBuyers}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PensionSizeBar
                                    // all non rejected leads
                                    leads={periodLeads.filter((lead) => lead.companyId || lead.isValid)} 
                                    returns={periodReturns}
                                    buyers={buyers}
                                    allBuyers={allBuyers}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    component={Box}
                                    alignItems='center'
                                    justifyContent='flex-start'
                                    style={{marginBottom: '2rem'}}
                                    direction='row'
                                >
                                    <Grid item xs={12} md={2}>
                                        <FormGroup style={{marginBottom: '0'}}>
                                            <FormControl variant='outlined' fullWidth>
                                                <Select
                                                    multiple={false}
                                                    value={showLeadsStatus}
                                                    onChange={(e) => updateShowLeadsStatus(e.target.value)}
                                                >
                                                    <MenuItem value={'Unallocated'}>Unallocated</MenuItem>
                                                    <MenuItem value={'Allocated'}>Allocated</MenuItem>
                                                    <MenuItem value={'Rejected'}>Rejected</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <LeadsTable
                                    leads={showLeads}
                                    title={showLeadsStatus}
                                    refreshLeads={() => getData()}
                                    updateLead={(lead) => updateLead(lead)}
                                    showSuccessAlert={(text) => showSuccessAlert(text)}
                                    companies={companies}
                                />
                            </Grid>
                        </Grid>
                        <Grid container style={{marginTop: '2rem'}}>
                            <Grid item xs={12}>
                                
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default NationalPensionHelpline