import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import filterFactory from 'react-bootstrap-table2-filter'
import cellEditFactory from 'react-bootstrap-table2-editor'
import AddDeliveryModal from './AddDeliveryModal'
import DeliveryModal from './DeliveryModal'
import EnergyAPI from 'config/api/EnergyAPI'
import { NotificationManager } from 'react-notifications'
import { onError } from 'config/lib/errorLib'

import componentStyles from 'assets/theme/views/admin/react-bs-table.js'

const useStyles = makeStyles(componentStyles)

function DeliveriesTable(props) {
    const classes = { ...useStyles() }
    const api = new EnergyAPI()

    const [deliveries, setDeliveries] = useState(props.deliveries)
    const [delivery, setDelivery] = useState(null)
    const [showDeliveryModal, setShowDeliveryModal] = useState(false)
    const [showAddDeliveryModal, setShowAddDeliveryModal] = useState(false)
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [loading, setLoading] = useState(false)

    // Update the deliveries state when props.deliveries changes
    useEffect(() => {
        setDeliveries(props.deliveries);
    }, [props.deliveries]); // This effect runs whenever props.deliveries changes

    const columns = [
        {
            dataField: 'company',
            text: 'Company',
            editable: false,
            //sort: true,
            //filter: textFilter(),
            formatter: (cell, row) => {
                return (<Box className={row.active === false ? 'inactive-delivery hover-text primary-text' : 'active-delivery hover-text primary-text'} onClick={() => openDeliveryModal(row)}>{row.companyName} ({row.companyId.toUpperCase()})</Box>)
            },
            footer: 'Total'
        },
        {
            dataField: 'leadPrice',
            text: 'Lead Price',
            sort: true,
            editable: false,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>€{row.leadPrice.toFixed(2)}</Box>
            },
            footer: ''
        },
        {
            dataField: 'revenue',
            text: 'Revenue',
            sort: true,
            editable: false,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                if (props.screen === 'deliveries') {
                    return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>€{row.leadPrice && row.weeklyCap ? (parseFloat(row.leadPrice) * parseFloat(row.weeklyCap).toFixed(2)) : '0.00'}</Box>
                }
                else {
                    return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>€{row.revenue ? row.revenue.toFixed(2) : '0.00'}</Box>
                }
            },
            footer: '€' + (props.screen === 'deliveries' ? deliveries.reduce((total, delivery) => total + ((parseFloat(delivery.leadPrice) * parseFloat(delivery.weeklyCap)) || 0), 0).toFixed(2) : deliveries.reduce((total, delivery) => total + (parseFloat(delivery.revenue) || 0), 0).toFixed(2))
        }
    ]

    if (props.screen === 'deliveries') {
        columns.splice(2, 0, {
            dataField: 'weeklyCap',
            text: 'Weekly Cap',
            sort: true,
            editable: false,
            formatter: (cell, row) => {
                return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>{row.weeklyCap || '-'}</Box>
            },
            footer: deliveries.reduce((total, delivery) => total + (delivery.weeklyCap || 0), 0).toString()
        })
    }
    else {
        columns.splice(1, 0, {
            dataField: 'weight',
            text: 'Weight',
            sort: true,
            editable: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>{row.weight.toFixed(3)}</Box>
            },
            footer: ''
        })

        columns.splice(3, 0, {
            dataField: 'leadCount',
            text: 'Leads',
            sort: true,
            editable: false,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>{row.leadCount || 0}</Box>
            },
            footer: deliveries.reduce((total, delivery) => total + (parseInt(delivery.leadCount) || 0), 0).toString()
        })

        if (props.site === 'ee') {
            columns.splice(1, 0, {
                dataField: 'product',
                text: 'Product',
                sort: true,
                editable: false,
                //filter: numberFilter(),
                formatter: (cell, row) => {
                    return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>{row.product}</Box>
                },
                footer: ''
            })
        }

        if (props.site === 'sf') {
            columns.splice(3, 0, {
                dataField: 'weeklyCap',
                text: 'Weekly Cap',
                sort: true,
                editable: false,
                formatter: (cell, row) => {
                    return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>{row.weeklyCap || '-'}</Box>
                },
                footer: deliveries.reduce((total, delivery) => total + (parseInt(delivery.weeklyCap) || 0), 0).toString()
            })
            columns.splice(5, 0, {
                dataField: 'eeLeadCount',
                text: 'EE Leads',
                sort: true,
                editable: false,
                //filter: numberFilter(),
                formatter: (cell, row) => {
                    return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>{row.eeLeadCount || 0}</Box>
                },
                footer: deliveries.reduce((total, delivery) => total + (parseInt(delivery.eeLeadCount) || 0), 0).toString()
            })
        }
    }

    function openDeliveryModal(delivery) {
        setDelivery(delivery)
        setShowDeliveryModal(true)
    }

    function closeDeliveryModal() {
        setShowDeliveryModal(false)
        setDelivery(null)
    }

    const handleTableChange = (type, { data, cellEdit: { rowId, dataField, newValue } }) => {
        console.log(rowId)
        console.log(dataField)
        console.log(newValue)

        // update state to new field
        const result = data.map((row) => {
            console.log(row)
            if (row.companyId === rowId) {
                const newRow = { ...row }
                if (newRow[dataField] !== parseFloat(newValue)) {
                    setUnsavedChanges(true)
                }
                newRow[dataField] = parseFloat(newValue)
                return newRow
            }
            return row
        })
        console.log('table change result: ', result)
        //setBrokers(result.sort((a, b) => b.weeklyAllocation - a.weeklyAllocation))
        setDeliveries(result)
        //checkAllocations(result)
    }

    function checkWeights() {
        let productWeights = {}

        for (let item of deliveries) {
            console.log(parseFloat(item.weight.toFixed(3)) * 100)
            if (productWeights.hasOwnProperty(item.product)) {
                productWeights[item.product] += parseFloat(item.weight.toFixed(3)) * 100
            }
            else {
                productWeights[item.product] = parseFloat(item.weight.toFixed(3)) * 100
            }
        }
        console.log('productWeights: ', productWeights)

        let valid = true
        //let problem = ''

        for (let product of Object.keys(productWeights)) {
            if (productWeights[product] !== 100) {
                valid = false
            } 
        }

        return valid
    }

    async function saveWeights() {
        console.log('updated deliveries: ', deliveries)
        setLoading(true)

        try {
            let result = props.site === 'ee' ? await api.updateEeDeliveries(deliveries) : await api.updateSfDeliveries(deliveries)
            console.log('updated deliveries: ', result)

            NotificationManager.success('Deliveries updated')
            setUnsavedChanges(false)
            setLoading(false)
            props.refreshDeliveries()
        } catch(e) {
            onError(e)
        }
    }

    return (
        <Card>
            {!loading ? (
                <>
                    <CardHeader
                        subheader={
                            <>
                                <Box display='flex' style={{justifyContent: 'space-between'}}>
                                    <Box>
                                        <Box
                                            component={Typography}
                                            variant='h2'
                                            marginBottom='0!important'
                                        >
                                            <Box component='span'>{props.title}</Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Button
                                            component={Box}
                                            onClick={() => setShowAddDeliveryModal(true)}
                                            color='primary'
                                            variant='contained'
                                            size='small'
                                        >
                                            Add Delivery
                                        </Button>
                                    </Box>
                                </Box>
                            </>
                        }
                        classes={{ root: classes.cardHeaderRoot }}
                    ></CardHeader>
                    
                    <div className='table-container'>
                        <BootstrapTable 
                            keyField='companyId' 
                            data={ deliveries } 
                            columns={ columns } 
                            filter={ filterFactory() } 
                            noDataIndication={<Box>No deliveries</Box>}
                            cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
                            onTableChange={ handleTableChange }
                            remote={{
                                filter: false,
                                pagination: false,
                                sort: false,
                                cellEdit: true
                            }}
                        />
                    </div>

                    {/*!checkWeights() &&
                        <Grid container style={{margin: '1rem'}}>
                            <Grid item xs={12} style={{textAlign: 'center'}}>
                                <Box class='warning-text'>Invalid weights. Values must add up to 1 for each product. Please correct this to avoid allocation errors.</Box>
                            </Grid>
                        </Grid>
                    */}

                    {unsavedChanges &&
                        <Grid container direction='column' justifyContent='center' alignItems='center' spacing={1} style={{margin: '1rem'}}>
                            {!checkWeights() ? (
                                <Grid item xs={12} style={{textAlign: 'center'}}>
                                    <Box class='warning-text'>Invalid weights. Values must add up to 1 for each product. Please correct this to avoid allocation errors.</Box>
                                </Grid>
                            ) : (
                                <>
                                <Grid item xs={12} style={{textAlign: 'center'}}>
                                    <Box class='warning-text'>You have made unsaved changes</Box>
                                </Grid>
                                <Grid item>
                                    <Button
                                        component={Box}
                                        color='primary'
                                        variant='contained'
                                        size='medium'
                                        disabled={loading}
                                        onClick={() => saveWeights()}
                                    >
                                        Save Changes
                                    </Button>
                                </Grid>
                                </>
                            )}
                        </Grid>
                    }

                    {showAddDeliveryModal &&
                        <AddDeliveryModal 
                            isOpen={showAddDeliveryModal}
                            close={() => setShowAddDeliveryModal(false)}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            refreshDeliveries={() => props.refreshDeliveries()}
                            companies={props.companies}
                            deliveries={deliveries}
                            site={props.site}
                        />
                    }

                    {showDeliveryModal &&
                        <DeliveryModal 
                            isOpen={showDeliveryModal}
                            close={() => closeDeliveryModal()}
                            delivery={delivery}
                            deliveries={deliveries}
                            updateDelivery={(delivery) => props.updateDelivery(delivery)}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            companies={props.companies}
                            site={props.site}
                        />
                    }
                </>
            ) : (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                            <Loader />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Card>
    );
}

export default DeliveriesTable