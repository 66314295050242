import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import EnergyAPI from 'config/api/EnergyAPI'
import { onError } from 'config/lib/errorLib'
import TotalStats from 'components/admin/TotalStats'
import LeadStatsBar from 'components/admin/LeadStatsBar'
import CompanyIncomePie from 'components/admin/CompanyIncomePie'
import ProductIncomePie from 'components/admin/ProductIncomePie'
import ProfitabilityTable from 'components/admin/ProfitabilityTable'
import RevenueGroupedBar from 'components/admin/RevenueGroupedBar'
import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'
import moment from 'moment-timezone'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

let periods = []
let currentMonth = moment().month()
for (let i = 5; i > 0; i--) {
    let previousMonth = moment().subtract(i, 'months')
    //console.log(previousMonth.month());
    periods.push({value: moment().set('month', months[previousMonth.month()]).format('MM'), label: months[previousMonth.month()]})
}
periods.push({value: moment().set('month', months[currentMonth]).format('MM'), label: months[currentMonth]})

function Profitability() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new AdminAPI()
    const eApi = new EnergyAPI()

    const [product, setProduct] = useState('Financial')
    const [totals, setTotals] = useState(null)
    const [allPayments, setAllPayments] = useState([])
    const [payments, setPayments] = useState([])
    const [allWeeks, setAllWeeks] = useState([])
    const [profitabilityWeeks, setProfitabilityWeeks] = useState([])
    const [period, setPeriod] = useState(moment().format('MM'))
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getData(product)
    }, [])

    async function getData(product) {
        try {
            const payments = product === 'Financial' ? await api.getPayments() : await eApi.getPayments()
            console.log('payments: ', payments)

            const weeks = product === 'Financial' ? await api.getProfitabilityWeeks() : await eApi.getProfitabilityWeeks()
            console.log('weeks: ', weeks)
            weeks.sort((a, b) => {
                return moment(b.date).valueOf() - moment(a.date).valueOf()
            })

            let month = moment().format('YYYY-MM')
            console.log('month: ', month)

            let monthPayments = []
            let monthWeeks = []
            let totals = {
                income: 0,
                costs: 0
            }
            for (var payment of payments) {
                // exclude balance topup payments and Q
                if (payment.billingMethod !== 'TOPUP' && payment.companyId !== 'qfa') {
                    if (payment.date.startsWith(month)) {
                        monthPayments.push(payment)
                        //totals.income += payment.subtotal
                    }
                }
            }
            for (var week of weeks) {
                if (week.date.startsWith(month)) {
                    monthWeeks.push(week)
                    totals.income += week.revenue
                    totals.costs += week.cost
                }
            }
            console.log('month payments: ', monthPayments)
            console.log('month weeks: ', monthWeeks)

            setAllPayments(payments)
            setPayments(monthPayments)
            setAllWeeks(weeks)
            setProfitabilityWeeks(monthWeeks)
            setTotals(totals)
            setPeriod(moment().format('MM'))
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updateWeek(week) {
        const index = profitabilityWeeks.findIndex(oldWeek => (oldWeek.country === week.country && oldWeek.date === week.date))
        console.log('week index: ', index)

        let newWeeks = [...profitabilityWeeks]
        newWeeks[index] = week
        setProfitabilityWeeks(newWeeks)
    }

    function updatePeriod(period) {
        let month = moment().format('YYYY-') + period
        console.log('month: ', month)

        let monthPayments = []
        let monthWeeks = []
        let totals = {
            income: 0,
            costs: 0
        }
        for (var payment of allPayments) {
            // exclude balance topup payments and Q
            if (payment.billingMethod !== 'TOPUP' && payment.companyId !== 'qfa') {
                if (payment.date.startsWith(month)) {
                    monthPayments.push(payment)
                    //totals.income += payment.subtotal
                }
            }
        }
        for (var week of allWeeks) {
            if (week.date.startsWith(month)) {
                monthWeeks.push(week)
                totals.income += week.revenue
                totals.costs += week.cost
            }
        }
        
        setPayments(monthPayments)
        setProfitabilityWeeks(monthWeeks)
        setPeriod(period)
        setTotals(totals)
        setPayments(monthPayments)
    }

    async function updateProduct(product) {
        setProduct(product)
        setLoading(true)
        await getData(product)
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    component={Box}
                                    alignItems='center'
                                    justifyContent='flex-start'
                                    style={{marginBottom: '2rem'}}
                                    direction='row'
                                >
                                    <Grid item xs={12} md={2}>
                                        <FormGroup style={{marginBottom: '0'}}>
                                            <FormControl variant='outlined' fullWidth>
                                                <Select
                                                    multiple={false}
                                                    //IconComponent={KeyboardArrowDown}
                                                    value={product}
                                                    onChange={(e) => updateProduct(e.target.value)}
                                                >
                                                    <MenuItem value={'Financial'}>Financial</MenuItem>
                                                    <MenuItem value={'Solar'}>Solar</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <FormGroup style={{marginBottom: '0'}}>
                                            <FormControl variant='outlined'>
                                                <Select
                                                    multiple={false}
                                                    defaultValue={null}
                                                    IconComponent={KeyboardArrowDown}
                                                    value={period}
                                                    onChange={(e) => updatePeriod(e.target.value)}
                                                >
                                                    {periods.map((month) => {
                                                        return (
                                                            <MenuItem value={month.value}>{month.label}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TotalStats 
                                    totals={totals}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ProfitabilityTable
                                    weeks={profitabilityWeeks}
                                    refreshWeeks={() => getData(product)}
                                    updateWeek={(week) => updateWeek(week)}
                                    showSuccessAlert={(text) => showSuccessAlert(text)}    
                                    product={product}          
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CompanyIncomePie
                                    payments={payments} 
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ProductIncomePie
                                    payments={payments} 
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LeadStatsBar
                                    payments={allPayments}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RevenueGroupedBar
                                    weeks={allWeeks}
                                />
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default Profitability