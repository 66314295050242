import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Loader from 'components/general/Loader'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import LeadStats from 'components/broker/LeadStats'
import ProductsTable from 'components/broker/ProductsTable'
import { getProductString } from 'config/helpers/Helpers'
import { defaultProducts } from 'config/constants/Constants'
import { getMoments } from 'config/helpers/Helpers'
import DetailsContext from 'config/context/DetailsContext'
import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'
import moment from 'moment-timezone'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

const productAttributes = {
    volume: 0,
    meetings: 0,
    won: 0,
    premiums: 0
}

function CibDashboard() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new BrokerAPI()
    const theme = useTheme()
    const companyDetails = useContext(DetailsContext)
	const companyId = companyDetails.companyId
    const productsAvailable = companyDetails.productsAvailable || defaultProducts
    console.log('products available: ', productsAvailable)

    const [details, setDetails] = useState(null)
    const [brokers, setBrokers] = useState([])
    const [leads, setLeads] = useState([])
    const [periodLeads, setPeriodLeads] = useState([])
    const [totals, setTotals] = useState(null)
    const [products, setProducts] = useState([])
    const [period, setPeriod] = useState('thisWeek')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getDashboardStats()
    }, [])

    async function getDashboardStats() {
        try {
            const details = await api.getDetails(companyId)
            console.log('details: ', details)

            const brokers = await api.getBrokers(companyId)
            console.log('brokers: ', brokers)

            const brokerId = details.permissions === 'admin' ? null : details.brokerId
            const leads = await api.getAllLeads(companyId, brokerId)
            console.log('all leads: ', leads)
            leads.items.sort((a, b) => {
                return b.createdAt - a.createdAt
            })

            let initialProducts = {}
            if (details.products && details.products.length > 0 && details.products[0] !== 'All') {
                for (let product of details.products) {
                    initialProducts[product] = {...productAttributes}
                }
            }
            else {
                for (let product of Object.keys(productsAvailable)) {
                    if (productsAvailable[product]) {
                        initialProducts[product] = {...productAttributes}
                    }
                }
            }
            console.log('initialProducts: ', initialProducts)

            let dates = getMoments('thisWeek')

            let weekLeads = []
            let totals = {
                leads: 0,
                meetings: 0,
                won: 0,
                premiums: 0
            }
            for (let lead of leads.items) {
                let created = moment(lead.createdAt);
                if (created.isBetween(dates.fromDate, dates.toDate)) {
                    // only count leads for products in list above
                    if (initialProducts.hasOwnProperty(lead.product)) {
                        weekLeads.push(lead)
                        totals.leads += 1
                        initialProducts[lead.product]['volume'] += 1
                        if (lead.stage === 'meeting') {
                            totals.meetings += 1
                            initialProducts[lead.product]['meetings'] += 1
                        }
                        if (lead.stage === 'won') {
                            console.log('WON: ', lead)
                            totals.won += 1
                            initialProducts[lead.product]['won'] += 1
                            if (lead.product === 'pensions') {
                                totals.premiums += parseFloat(lead.quote.currentValue)
                                initialProducts[lead.product]['premiums'] += parseFloat(lead.quote.currentValue)
                            }
                            else {
                                totals.premiums += parseFloat(lead.quote.premium) * 12 
                                initialProducts[lead.product]['premiums'] += parseFloat(lead.quote.premium) * 12 
                            }
                        }
                    }
                }
            }
            console.log('week leads: ', weekLeads)
            console.log('totals: ', totals)

            let productArray = []
            for(let product of Object.keys(initialProducts)) {
                productArray.push({
                    product: product,
                    ...initialProducts[product]
                })
            }
            console.log('product array: ', productArray)
            productArray.sort((a, b) => {
                return b.volume - a.volume
            })

            setDetails(details)
            setBrokers(brokers)
            setLeads(leads.items)
            setPeriodLeads(weekLeads)
            setTotals(totals)
            setProducts(productArray)
            setFromDate(dates.fromDate.format('MMMM Do'))
            setToDate(dates.toDate.format('MMMM Do'))
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updatePeriod(period) {
        setPeriod(period)
        let dates = getMoments(period)

        let initialProducts = {}
        if (details.products && details.products.length > 0 && details.products[0] !== 'All') {
            for (let product of details.products) {
                initialProducts[product] = {...productAttributes}
            }
        }
        else {
            // initialProducts = {
            //     'home-insurance': {...productAttributes},
            //     'business-insurance': {...productAttributes},
            //     'health-insurance': {...productAttributes},
            //     'life-insurance': {...productAttributes},
            //     'income-protection': {...productAttributes},
            //     'mortgage-protection': {...productAttributes}
            // }
            for (let product of Object.keys(productsAvailable)) {
                if (productsAvailable[product]) {
                    initialProducts[product] = {...productAttributes}
                }
            }
        }
        console.log('initialProducts: ', initialProducts)
        
        let newLeads = []
        let totals = {
            leads: 0,
            meetings: 0,
            won: 0,
            premiums: 0
        }
        for (let lead of leads) {
            let created = moment(lead.createdAt);
            if (created.isBetween(dates.fromDate, dates.toDate)) {
                // only count leads for products in list above
                if (initialProducts.hasOwnProperty(lead.product)) {
                    newLeads.push(lead)
                    totals.leads += 1
                    initialProducts[lead.product]['volume'] += 1
                    if (lead.stage === 'meeting') {
                        totals.meetings += 1
                        initialProducts[lead.product]['meetings'] += 1
                    }
                    if (lead.stage === 'won') {
                        console.log('WON: ', lead)
                        totals.won += 1
                        initialProducts[lead.product]['won'] += 1
                        if (lead.product === 'pensions') {
                            totals.premiums += parseFloat(lead.quote.currentValue)
                            initialProducts[lead.product]['premiums'] += parseFloat(lead.quote.currentValue)
                        }
                        else {
                            totals.premiums += parseFloat(lead.quote.premium) * 12 
                            initialProducts[lead.product]['premiums'] += parseFloat(lead.quote.premium) * 12 
                        }
                    }
                }
            }
        }
        console.log('period leads: ', newLeads)
        console.log('totals: ', totals)

        let productArray = []
        for(let product of Object.keys(initialProducts)) {
            productArray.push({
                product: product,
                ...initialProducts[product]
            })
        }
        console.log('product array: ', productArray)
        productArray.sort((a, b) => {
            return b.volume - a.volume
        })

        setPeriodLeads(newLeads)
        setTotals(totals)
        setProducts(productArray)
        setFromDate(dates.fromDate.format('MMMM Do'))
        setToDate(dates.toDate.format('MMMM Do'))
    }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    component={Box}
                                    alignItems='center'
                                    justifyContent='flex-start'
                                    style={{marginBottom: '2rem'}}
                                    direction='row'
                                >
                                    <Grid item xs={12} md={2}>
                                        <FormGroup style={{marginBottom: '0'}}>
                                            <FormControl variant='outlined'>
                                                <Select
                                                    multiple={false}
                                                    defaultValue={null}
                                                    IconComponent={KeyboardArrowDown}
                                                    value={period}
                                                    onChange={(e) => updatePeriod(e.target.value)}
                                                >
                                                    <MenuItem value={'thisWeek'}>This Week</MenuItem>
                                                    <MenuItem value={'lastWeek'}>Last Week</MenuItem>
                                                    <MenuItem value={'thisMonth'}>This Month</MenuItem>
                                                    <MenuItem value={'lastMonth'}>Last Month</MenuItem>
                                                    <MenuItem value={'yearToDate'}>Year to Date</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                        <Box
                                            fontSize='12px'
                                            color={theme.palette.gray[600]}
                                            //marginBottom='0.2rem'
                                            //marginLeft='1rem'
                                            marginTop='0.5rem'
                                            textAlign='left'
                                            //className='online-discount'
                                        >
                                            <Box component='small' fontSize='100%'>
                                                Showing from {fromDate} - {toDate}
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <LeadStats 
                                    totals={totals}
                                    product='all'
                                    direction='row'
                                    companyId='cib'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ProductsTable 
                                    products={products}
                                    companyId='cib'
                                />
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default CibDashboard