import React, { useState, useEffect } from 'react'
import { useLocation, Switch, Route, Redirect } from 'react-router-dom'
import AdminNavbarAlternative from 'components/argon/Navbars/AdminNavbarAlternative.js'
import Sidebar from 'components/argon/Sidebar/Sidebar.js'
import Box from '@material-ui/core/Box'
import DashboardIcon from '@material-ui/icons/Dashboard'
//import PeopleIcon from '@material-ui/icons/People'
import HouseIcon from '@material-ui/icons/House'
import HomeIcon from '@material-ui/icons/Home'
import HealthIcon from '@material-ui/icons/LocalHospital'
import BusinessIcon from '@material-ui/icons/Business'
import PeopleIcon from '@material-ui/icons/People'
import WorkIcon from '@material-ui/icons/Work'
import HeartIcon from '@material-ui/icons/Favorite'
import GraphIcon from '@material-ui/icons/ShowChart'
import GadgetIcon from '@material-ui/icons/PhoneAndroid'
import PetIcon from '@material-ui/icons/Pets'
import TravelIcon from '@material-ui/icons/Flight'
//import FolderIcon from '@material-ui/icons/FolderShared'
import SettingsIcon from '@material-ui/icons/Settings'
import TasksIcon from '@material-ui/icons/CalendarToday'
//import AllocationsIcon from '@material-ui/icons/Subscriptions'
import HelpIcon from '@material-ui/icons/Help'
import BillingIcon from '@material-ui/icons/CreditCard'
import { defaultProducts } from 'config/constants/Constants'

import logo from 'resources/images/logo-blue-500.png'

import { makeStyles } from '@material-ui/core/styles'
import componentStyles from 'assets/theme/layouts/admin.js'

import Dashboard from 'scenes/broker/Dashboard'
import CibDashboard from 'scenes/broker/CibDashboard'
import Tasks from 'scenes/broker/Tasks'
import Leads from 'scenes/broker/Leads'
import Allocations from 'scenes/broker/Allocations'
import Help from 'scenes/broker/Help'
import Billing from 'scenes/broker/Billing'
import Topup from 'scenes/guest/Topup'
import { getProductString } from 'config/helpers/Helpers'

const useStyles = makeStyles(componentStyles)

const productIcons = {
    'income-protection': WorkIcon,
    'mortgage-protection': HouseIcon,
    'life-insurance': HeartIcon,
    'pensions': GraphIcon,
    'health-insurance': HealthIcon,
    'home-insurance': HomeIcon,
    'business-insurance': BusinessIcon,
    'gadget-insurance': GadgetIcon,
    'pet-insurance': PetIcon,
    'travel-insurance': TravelIcon,
    'general': PeopleIcon
}

const Main = (props) => {
    const classes = useStyles()
    const location = useLocation()
    const [sidebarOpenResponsive, setSidebarOpenResponsive] = useState(false)

    const details = props.details
    console.log('details: ', details)
    const productsAvailable = getProductsAvailable()
    const companyId = props.companyDetails.companyId

    function getProductsAvailable() {
        if (details.products && details.products.length > 0 && details.products[0] !== 'All') {
            let available = {}
            for (let product of details.products) {
                available[product] = true
            }
            return available
        }
        else {
            return props.companyDetails.productsAvailable || defaultProducts
        }
    }

    useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
        // mainContent.current.scrollTop = 0
    }, [location])

    var routes = [
        {
            path: '/dashboard',
            name: 'Dashboard',
            icon: DashboardIcon,
            iconColor: 'Info',
            component: (companyId === 'cib' || companyId === 'ci') ? CibDashboard : Dashboard
        },
        {
            path: '/tasks',
            name: 'Tasks',
            icon: TasksIcon,
            iconColor: 'Info',
            component: Tasks,
        },
        {
            path: '/help',
            name: 'Help',
            icon: HelpIcon,
            iconColor: 'Info',
            component: Help,
        },
    ]

    Object.keys(productsAvailable).map((product, i) => {
        if (productsAvailable[product]) {
            routes.splice(1, 0, {
                path: '/' + product,
                name: getProductString(product),
                icon: productIcons[product],
                iconColor: 'Info',
                component: Leads,
            })
        }
    })

    if (details.permissions === 'admin') {
        routes.push({
            path: '/allocations',
            name: 'Allocations',
            icon: SettingsIcon,
            iconColor: 'Info',
            component: Allocations,
        })

        routes.push({
            path: '/billing',
            name: 'Billing',
            icon: BillingIcon,
            iconColor: 'Info',
            component: Billing,
        })
    }

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views)
            } 
            else {
                return (
                    <Route
                        path={prop.path}
                        component={prop.component}
                        key={key}
                    />
                )
            }
        });
    };

    return (
        <>
            <Box display='flex'>
                {details &&
                    <Sidebar
                        routes={routes}
                        openResponsive={sidebarOpenResponsive}
                        closeSidebarResponsive={() => setSidebarOpenResponsive(false)}
                        accountType='broker'
                        permissions={details.permissions}
                        paymentMethod={props.paymentMethod}
                        email={details.email}
                        logo={{
                            innerLink: '/',
                            imgSrc: logo,
                            imgAlt: '...',
                            className: 'sidebar-logo',
                        }}
                        billingAccount={props.companyDetails.billingAccount}
                    />
                }
                <Box position='relative' flex='1' className={classes.mainContent}>
                    <AdminNavbarAlternative
                        openSidebarResponsive={() => setSidebarOpenResponsive(true)}
                        accountType='broker'
                        permissions={details.permissions}
                        name={details.firstName + ' ' + details.lastName}
                        email={details.email}
                    />
                    
                    <Switch>
                        {getRoutes(routes)}
                        <Route exact path='/topup' component={Topup} />
                        <Redirect from='*' to='/dashboard' />
                    </Switch>
                </Box>
            </Box>
        </>
    )
}

export default Main