import React from 'react'
import Chart from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { chartOptions, parseOptions, chartExample7 } from 'config/constants/ChartVariables'
import componentStyles from 'assets/theme/components/cards/charts/card-product-comparison.js'
import moment from 'moment-timezone'

const useStyles = makeStyles(componentStyles)

const themeColors = require('assets/theme/colors.js').default

var colors = {
    gray: themeColors.gray,
    theme: {
        default: themeColors.dark.main,
        primary: themeColors.primary.main,
        secondary: themeColors.secondary.main,
        info: themeColors.info.main,
        infoLight: themeColors.info.light,
        success: themeColors.success.main,
        danger: themeColors.error.main,
        warning: themeColors.warning.main,
    },
    black: themeColors.black.light,
    white: themeColors.white.main,
    transparent: themeColors.transparent.main,
}

const options = {
    tooltips: {
        mode: 'index',
        intersect: false,
    },
    responsive: true,
    scales: {
        xAxes: [
            {
                stacked: false,
            },
        ],
        yAxes: [
            {
                stacked: false,
            },
        ],
    }
}

function CashflowSummary(props) {
    const classes = useStyles()
    const theme = useTheme()
    if (window.Chart) {
        parseOptions(Chart, chartOptions())
    }

    // aggregate weeks and payments 
    let weeks = {}
    let bqPayments = {}
    let ssPayments = {}
    for (let week of props.profitabilityWeeks) {
        if (weeks[week.date]) {
            weeks[week.date].cost += parseFloat(week.cost)
        }
        else {
            weeks[week.date] = {
                date: week.date,
                cost: parseFloat(week.cost)
            }
        }
        // add remaining weeks in month
        // let thisFriday = moment().day(5)
        // if (thisFriday.month() === props.fromDate.month()) {
        //     weeks[thisFriday.format('YYYY-MM-DD')] = {
        //         date: thisFriday.format('YYYY-MM-DD'),
        //         cost: 0
        //     }
        // }
        // while (thisFriday.add(1, 'week').month() === props.fromDate.month()) {
        //     weeks[thisFriday.format('YYYY-MM-DD')] = {
        //         date: thisFriday.format('YYYY-MM-DD'),
        //         cost: 0
        //     }
        // }
    }
    console.log('aggregated weeks: ', weeks)
    const weeksArray = Object.keys(weeks).map((date) => {
        return {
            date: date,
            cost: weeks[date].cost.toFixed(2)
        }
    })
    weeksArray.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
    console.log('weeks array: ', weeksArray)

    for (let payment of props.payments) {
        let paymentDate = moment(payment.date)

        if (!weeks[paymentDate.format('YYYY-MM-DD')]) {
            console.log('payment does not fall on standard friday: ', paymentDate.format('YYYY-MM-DD'))
            // update payment date to friday of that week
            paymentDate.day(5)
            console.log('updated date: ', paymentDate.format('YYYY-MM-DD'))
            payment.date = paymentDate.format('YYYY-MM-DD')
        }


        if (payment.billingAccount === 'BQ') {
            if (bqPayments[payment.date]) {
                bqPayments[payment.date].total += parseFloat(payment.total)
            }
            else {
                bqPayments[payment.date] = {
                    date: payment.date,
                    total: parseFloat(payment.total),
                    //billingAccount: payment.billingAccount
                }
            }
        }
        if (payment.billingAccount === 'SS') {
            if (ssPayments[payment.date]) {
                ssPayments[payment.date].total += parseFloat(payment.total)
            }
            else {
                ssPayments[payment.date] = {
                    date: payment.date,
                    total: parseFloat(payment.total),
                    //billingAccount: payment.billingAccount
                }
            }
        }
    }
    const bqPaymentsArray = Object.keys(bqPayments).map((date) => {
        return {
            date: date,
            total: bqPayments[date].total.toFixed(2),
            //billingAccount: bqPayments[date].billingAccount
        }
    })
    console.log('bq payments array: ', bqPaymentsArray)
    const ssPaymentsArray = Object.keys(ssPayments).map((date) => {
        return {
            date: date,
            total: ssPayments[date].total.toFixed(2),
            //billingAccount: bqPayments[date].billingAccount
        }
    })
    console.log('ss payments array: ', ssPaymentsArray)


    const labels = weeksArray.map((week) => 'Week ending ' + moment(week.date).format('DD/MM'))
    console.log('labels: ', labels)

    const bqOut = weeksArray.map((week) => week.cost)
    console.log('bq out: ', bqOut)

    const bqIn = bqPaymentsArray.map((payment) => payment.total || 0)
    console.log('bq in: ', bqIn)

    const ssIn = ssPaymentsArray.map((payment) => payment.total || 0)
    console.log('ss in: ', ssIn)

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'BQ In',
                data: bqIn,
                backgroundColor: colors.theme['success'],
                maxBarThickness: 30,
                stack: 'Stack 0'
            },
            {
                label: 'BQ Out',
                data: bqOut,
                backgroundColor: colors.theme['danger'],
                maxBarThickness: 30,
                stack: 'Stack 1'
            },
            {
                label: 'SS In',
                data: ssIn,
                backgroundColor: colors.theme['info'],
                maxBarThickness: 30,
                stack: 'Stack 2'
            }
        ]
    }
  
    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    title={
                        <Box component='span' color={theme.palette.gray[600]}>
                            
                        </Box>
                    }
                    subheader='Cashflow Summary'
                    classes={{ root: classes.cardHeaderRoot }}
                    titleTypographyProps={{
                        component: Box,
                        variant: 'h6',
                        letterSpacing: '2px',
                        marginBottom: '0!important',
                        classes: {
                            root: classes.textUppercase,
                        },
                    }}
                    subheaderTypographyProps={{
                        component: Box,
                        variant: 'h3',
                        marginBottom: '0!important',
                        color: 'initial',
                    }}
                ></CardHeader>
                
                <CardContent>
                    <Box position='relative' height='350px'>
                        <Bar
                            data={data}
                            options={options}
                            getDatasetAtEvent={(e) => console.log(e)}
                        />
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}

export default CashflowSummary