import { Component } from 'react'
import { API, Auth } from 'aws-amplify'
//import { v4 as uuidv4 } from 'uuid'
import config from './Config'

const apiPath = config.apiGatewayBroker.PATH

class BrokerClient extends Component {
	addDetails(companyId, firstName, lastName, email, phone, permissions, counties, products) {
		const data = {
			companyId,
			firstName,
			lastName,
			email,
			phone,
			permissions,
			counties,
			products
		}
		console.log(data)

		return API.post(apiPath, 'brokers/add', {
			body: data
		})
	}

	updateBroker(companyId, brokerId, counties, permissions, notificationEmails, reminderEmails, hideInAnalytics, products) {
		const data = {
			companyId,
			brokerId,
			counties,
			permissions,
			notificationEmails,
			reminderEmails,
			hideInAnalytics,
			products
		}
		console.log(data)

		return API.post(apiPath, 'brokers/update', {
			body: data
		})
	}

	getDetails(companyId) {
		let data = {
			companyId
		}
		console.log(data)

		return API.post(apiPath, 'brokers/get', {
			body: data
		})
	}

	getCompanyDetails(companyId) {
		const data = {
			companyId
		}
		console.log(data)

		return API.post(apiPath, 'brokers/get-company', {
			body: data
		})
	}

	getBrokers(companyId) {
		let data = {
			companyId
		}
		return API.post(apiPath, 'brokers/list', {
			body: data
		})
	}

	getInvites(companyId) {
		let data = {
			companyId
		}
		console.log(data)
		return API.post(apiPath, 'brokers/invites', {
			body: data
		})
	}

	getInvite(email) {
		let data = {
			email
		}
		console.log(data)
		return API.post(apiPath, 'brokers/get-invite', {
			body: data
		})
	}

	cancelInvite(email) {
		let data = {
			email
		}
		return API.post(apiPath, 'brokers/delete-invite', {
			body: data
		})
	}

	getLeads(product, companyId, brokerId) {
		const data = {
			product,
			companyId,
			brokerId
		}
		console.log(data)
		
		return API.post(apiPath, 'leads/list', {
			body: data
		})
	}

    getLeadsLimit(product, companyId, brokerId, loadMore) {
		const data = {
			product,
			companyId,
			brokerId,
			loadMore
		}
		console.log(data)
		
		return API.post(apiPath, 'leads/list-limit', {
			body: data
		})
	}

	getLeadsPeriod(product, companyId, fromDate, toDate) {
		const data = {
			product,
			companyId,
			fromDate,
			toDate
		}
		console.log(data)
		
		return API.post(apiPath, 'leads/list-period', {
			body: data
		})
	}

	getAllLeads(companyId, brokerId) {
		const data = {
			companyId,
			brokerId
		}
		console.log(data)
		
		return API.post(apiPath, 'leads/list-all', {
			body: data
		})
	}

	addLead(companyId, product, firstName, lastName, email, phone, brokerId, brokerName) {
		const data = {
			companyId,
			product,
			firstName,
			lastName,
			email,
			phone,
			brokerId,
			brokerName
		}
		console.log(data)

		return API.post(apiPath, 'leads/add', {
			body: data
		})
	}

	updateLeadPremium(companyId, product, phone, premium) {
		const data = {
			companyId,
			product,
			phone,
			premium
		}
		console.log(data)

		return API.post(apiPath, 'leads/update-premium', {
			body: data
		})
	}

	updateGeneralPremium(companyId, date, createdAt, premium) {
		const data = {
			companyId,
			date,
			createdAt,
			premium
		}
		console.log(data)

		return API.post(apiPath, 'general/update-premium', {
			body: data
		})
	}

	updatePensionValue(companyId, product, phone, currentValue) {
		const data = {
			companyId,
			product,
			phone,
			currentValue
		}
		console.log(data)

		return API.post(apiPath, 'leads/update-pension-value', {
			body: data
		})
	}

	updateLeadLostReason(companyId, product, phone, lostReason) {
		const data = {
			companyId,
			product,
			phone,
			lostReason
		}
		console.log(data)

		return API.post(apiPath, 'leads/update-lost-reason', {
			body: data
		})
	}

	updateGeneralLostReason(companyId, date, createdAt, lostReason) {
		const data = {
			companyId,
			date,
			createdAt,
			lostReason
		}
		console.log(data)

		return API.post(apiPath, 'general/update-lost-reason', {
			body: data
		})
	}

	updateLeadStage(companyId, product, phone, stage) {
		const data = {
			companyId,
			product,
			phone,
			stage
		}
		console.log(data)

		return API.post(apiPath, 'leads/update-stage', {
			body: data
		})
	}

	updateGeneralStage(companyId, date, createdAt, stage) {
		const data = {
			companyId,
			date,
			createdAt,
			stage
		}
		console.log(data)

		return API.post(apiPath, 'general/update-stage', {
			body: data
		})
	}

	updateLeadStageMultiple(companyId, product, leads, stage) {
		const data = {
			companyId,
			product,
			leads,
			stage
		}
		console.log(data)

		return API.post(apiPath, 'leads/update-stage-multiple', {
			body: data
		})
	}

	updateGeneralStageMultiple(companyId, leads, stage) {
		const data = {
			companyId,
			leads,
			stage
		}
		console.log(data)

		return API.post(apiPath, 'general/update-stage-multiple', {
			body: data
		})
	}

	updateLeadBroker(companyId, product, phone, brokerId, brokerName) {
		const data = {
			companyId,
			product,
			phone,
			brokerId,
			brokerName
		}
		console.log(data)

		return API.post(apiPath, 'leads/update-broker', {
			body: data
		})
	}

	updateGeneralBroker(companyId, date, createdAt, brokerId, brokerName) {
		const data = {
			companyId,
			date,
			createdAt,
			brokerId,
			brokerName
		}
		console.log(data)

		return API.post(apiPath, 'general/update-broker', {
			body: data
		})
	}

	updateLeadBrokerMultiple(companyId, product, leads, brokerId, brokerName) {
		const data = {
			companyId,
			product,
			leads,
			brokerId,
			brokerName
		}
		console.log(data)

		return API.post(apiPath, 'leads/update-broker-multiple', {
			body: data
		})
	}

	updateLeadQuote(companyId, product, phone, quote) {
		const data = {
			companyId,
			product,
			phone,
			quote
		}
		console.log(data)

		return API.post(apiPath, 'leads/update-quote', {
			body: data
		})
	}

	updateLeadDetails(companyId, product, phone, details) {
		const data = {
			companyId,
			product,
			phone,
			details
		}
		console.log(data)

		return API.post(apiPath, 'leads/update-details', {
			body: data
		})
	}

	updateGeneralDetails(companyId, date, createdAt, details) {
		const data = {
			companyId,
			date,
			createdAt,
			details
		}
		console.log(data)

		return API.post(apiPath, 'general/update-details', {
			body: data
		})
	}

	updateGeneralProduct(companyId, date, createdAt, product) {
		const data = {
			companyId,
			date,
			createdAt,
			product
		}
		console.log(data)

		return API.post(apiPath, 'general/update-product', {
			body: data
		})
	}

	addLeadNote(companyId, product, phone, note) {
		const data = {
			companyId,
			product,
			phone,
			note
		}
		console.log(data)

		return API.post(apiPath, 'leads/add-note', {
			body: data
		})
	}

	addGeneralNote(companyId, date, createdAt, note) {
		const data = {
			companyId,
			date,
			createdAt,
			note
		}
		console.log(data)

		return API.post(apiPath, 'general/add-note', {
			body: data
		})
	}

	updateLeadNote(companyId, product, phone, updatedNote, index) {
		const data = {
			companyId,
			product,
			phone,
			updatedNote,
			index
		}
		console.log(data)

		return API.post(apiPath, 'leads/update-note', {
			body: data
		})
	}

	updateGeneralNote(companyId, date, createdAt, updatedNote, index) {
		const data = {
			companyId,
			date,
			createdAt,
			updatedNote,
			index
		}
		console.log(data)

		return API.post(apiPath, 'general/update-note', {
			body: data
		})
	}

	addLeadCall(companyId, product, phone, date, outcome, note) {
		const data = {
			companyId,
			product,
			phone,
			date,
			outcome,
			note
		}
		console.log(data)

		return API.post(apiPath, 'leads/add-call', {
			body: data
		})
	}

	addGeneralCall(companyId, date, createdAt, callDate, outcome, note) {
		const data = {
			companyId,
			date,
			createdAt,
			callDate,
			outcome,
			note
		}
		console.log(data)

		return API.post(apiPath, 'general/add-call', {
			body: data
		})
	}

	addLeadTask(companyId, product, brokerId, phone, name, title, date, duration, reminder, note) {
		const data = {
			companyId,
			product,
			brokerId,
			phone,
			name,
			title,
			date,
			duration,
			reminder,
			note
		}
		console.log(data)

		return API.post(apiPath, 'tasks/add-lead', {
			body: data
		})
	}

	addGeneralTask(companyId, genDate, genCreatedAt, product, brokerId, phone, name, title, date, duration, reminder, note) {
		const data = {
			companyId,
			genDate,
			genCreatedAt,
			product,
			brokerId,
			phone,
			name,
			title,
			date,
			duration,
			reminder,
			note
		}
		console.log(data)

		return API.post(apiPath, 'tasks/add-general', {
			body: data
		})
	}

	getTasks(companyId, taskIds, brokerId = null) {
		const data = {
			companyId,
			taskIds,
			brokerId
		}
		console.log(data)

		return API.post(apiPath, 'tasks/get-by-id', {
			body: data
		})
	}

	getBrokerTasks(companyId, brokerId) {
		const data = {
			companyId,
			brokerId
		}
		console.log(data)

		return API.post(apiPath, 'tasks/get-broker', {
			body: data
		})
	}

	updateTask(companyId, brokerId, taskId, title, date, duration, note, reminder, completed) {
		const data = {
			companyId,
			brokerId,
			taskId,
			title,
			date,
			duration,
			note,
			reminder,
			completed
		}
		console.log(data)

		return API.post(apiPath, 'tasks/update', {
			body: data
		})
	}

	deleteTask(companyId, brokerId, taskId) {
		const data = {
			companyId,
			brokerId,
			taskId
		}
		console.log(data)

		return API.post(apiPath, 'tasks/delete', {
			body: data
		})
	}

	async addBroker(companyId, firstName, lastName, email, phone, counties, permissions, products) {
		// add to invite table
		const password = this.generatePassword()
		try {
			let data = {
				companyId,
				email,
				counties,
				password,
				products
			}
			await API.post(apiPath, 'brokers/add-invite', {
				body: data
			})

			// Create cognito user
			let cognitoData = {
				'username': email, 
				'password': password,
				'attributes': {
					'name': firstName + ' ' + lastName,
					'email': email,
					'phone_number': phone,
					'custom:account_type': 'broker',
					'custom:company_id': companyId,
					//'custom:county': county,
					'custom:permissions': permissions
				}
			}
			console.log(cognitoData)

			return Auth.signUp(cognitoData)
		} catch(e) {
			console.log(e)
			return e
		}
	}

	generatePassword() {
		const alpha = 'abcdefghijklmnopqrstuvwxyz'
		const calpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
		const num = '1234567890'
		const specials = '$!@'
		const options = [alpha, alpha, alpha, calpha, calpha, num, num, specials]
		let opt, choose
		let pass = ''
		
		for ( let i = 0; i < 8; i++ ) {
			opt = Math.floor(Math.random() * options.length)
			choose = Math.floor(Math.random() * (options[opt].length))
			pass = pass + options[opt][choose]
			options.splice(opt, 1)
		}

		return pass
	}

	getConfig(companyId) {
		let data = {
			companyId
		}
		console.log(data)
		
		return API.post(apiPath, 'config/get', {
			body: data
		})
	}

	getAllocatedLeads(companyId, product) {
		let data = {
			companyId,
			product
		}
		console.log(data)
		
		return API.post(apiPath, 'config/get-allocated', {
			body: data
		})
	}

	updateAllocations(companyId, product, allocations) {
		let data = {
			companyId,
			product,
			allocations
		}
		console.log(data)
		
		return API.post(apiPath, 'config/update-allocations', {
			body: data
		})
	}

	updateDailyCaps(companyId, product, dailyCaps) {
		let data = {
			companyId,
			product,
			dailyCaps
		}
		console.log(data)
		
		return API.post(apiPath, 'config/update-daily-caps', {
			body: data
		})
	}

	getDashboardStats(companyId, product, fromDate, toDate) {
		let data = {
			companyId,
			product,
			fromDate,
			toDate
		}
		console.log(data)

		return API.post(apiPath, 'analytics/dashboard', {
			body: data
		})
	}

	submitEnquiry(companyId, enquiry) {
		let data = {
			companyId,
			enquiry
		}
		console.log(data)

		return API.post(apiPath, 'brokers/submit-enquiry', {
			body: data
		})
	}

	submitLeadReturn(companyId, companyName, leadReturn) {
		let data = {
			companyId,
			companyName,
			leadReturn
		}
		console.log(data)

		return API.post(apiPath, 'leads/request-return', {
			body: data
		})
	}

	getDelivery(companyId, product) {
		let data = {
			companyId,
			product
		}
		return API.post(apiPath, 'deliveries/get', {
			body: data
		})
	}

	getGeneral(companyId, brokerId) {
		const data = {
			companyId,
			brokerId
		}
		console.log(data)
		
		return API.post(apiPath, 'general/list', {
			body: data
		})
	}

	getSetupIntent(companyId, billingEmail) {
		const data = {
			companyId,
			billingEmail
		}
		console.log(data)

		return API.post(apiPath, 'stripe/create-setup-intent', {
			body: data
		})
	}

	getPaymentMethods(stripeCustomerId) {
		const data = {
			stripeCustomerId
		}
		console.log(data)

		return API.post(apiPath, 'stripe/get-payment-methods', {
			body: data
		})
	}

	getPayments(companyId) {
		const data = {
			companyId
		}
		console.log(data)

		return API.post(apiPath, 'payments/list', {
			body: data
		})
	}
	
	makePaymentExisting(payment) {
		const data = {
			payment
		}
		console.log(data)

        return API.post(apiPath, 'stripe/create-payment-intent-off', {
            body: data
        })
	}

	makePaymentNew(payment) {
		const data = {
			payment
		}
		console.log(data)

        return API.post(apiPath, 'stripe/create-payment-intent-on', {
            body: data
        })
	}

	getPaymentIntentStatus(paymentIntentId) {
		const data = {
			paymentIntentId
		}
		console.log(data)

        return API.post(apiPath, 'stripe/get-payment-intent-status', {
            body: data
        })
	}

	markPaymentPaid(companyId, paymentDate) {
		const data = {
			companyId,
			paymentDate
		}
		console.log(data)

		return API.post(apiPath, 'payments/mark-paid', {
			body: data
		})
	}

	getBalanceDelivery(companyId, product) {
		const data = {
			companyId,
			product
		}
		console.log(data)

		return API.post(apiPath, 'deliveries/get-balance', {
			body: data
		})
	}
} 

export default BrokerClient