import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import filterFactory from 'react-bootstrap-table2-filter'
import { getProductString } from 'config/helpers/Helpers'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'

import componentStyles from 'assets/theme/views/admin/react-bs-table.js'

const useStyles = makeStyles(componentStyles)

function ProductsTable(props) {
    const classes = { ...useStyles() }
    const api = new AdminAPI()

    const products = props.products.sort((a, b) => b.volume - a.volume)
    console.log('products: ', products)
    
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            dataField: 'product',
            text: 'Product',
            formatter: (cell, row) => {
                return (<Box className='hover-text primary-text'>{getProductString(row.product)}</Box>)
            },
            footer: ''
        },
        {
            dataField: 'sold',
            text: 'Sold',
            //sort: true,
            formatter: (cell, row) => {
                return <>{row.volume}</>
            },
            footer: products.reduce((total, product) => total + (product.volume || 0), 0).toString()
        },
        {
            dataField: 'rejected',
            text: 'Rejected',
            //sort: true,
            formatter: (cell, row) => {
                return <>{row.rejected}</>
            },
            footer: products.reduce((total, product) => total + (product.rejected || 0), 0).toString()
        },
        {
            dataField: 'acceptedReturns',
            text: 'Returns',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.acceptedReturns} from {row.requestedReturns}</>
            },
            footer: products.reduce((total, product) => total + (product.acceptedReturns || 0), 0).toString() + ' from ' + products.reduce((total, product) => total + (product.requestedReturns || 0), 0).toString()
        },
        {
            dataField: 'revenue',
            text: 'Revenue',
            //sort: true,
            formatter: (cell, row) => {
                return <>{'€' + row.revenue.toFixed(2)}</>
            },
            footer: '€' + products.reduce((total, product) => total + (product.revenue || 0), 0).toFixed(2)
        },
        {
            dataField: 'adSpend',
            text: 'Ad Spend',
            sort: true,
            formatter: (cell, row) => {
                return <>{'€' + row.adSpend.toFixed(2)}</>
            },
            footer: '€' + products.reduce((total, product) => total + (product.adSpend || 0), 0).toFixed(2)
        },
        {
            dataField: 'otherCosts',
            text: 'Other Costs',
            sort: true,
            formatter: (cell, row) => {
                return <>{'€' + row.otherCosts.toFixed(2)}</>
            },
            footer: '€' + products.reduce((total, product) => total + (product.otherCosts || 0), 0).toFixed(2)
        },
        {
            dataField: 'profit',
            text: 'Profit',
            sort: true,
            formatter: (cell, row) => {
                return <>{'€' + (row.revenue - (row.adSpend + row.otherCosts)).toFixed(2)}</>
            },
            footer: '€' + (products.reduce((total, product) => total + (product.revenue || 0), 0) - (products.reduce((total, product) => total + (product.adSpend || 0), 0) + products.reduce((total, product) => total + (product.otherCosts || 0), 0))).toFixed(2)
        }
    ]

    return (
        <Card>
            <>
                <CardHeader
                    subheader={
                        <>
                            <Box display='flex' style={{justifyContent: 'space-between'}}>
                                <Box>
                                    <Box
                                        component={Typography}
                                        variant='h2'
                                        marginBottom='0!important'
                                    >
                                        <Box component='span'>Product Overview</Box>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    }
                    classes={{ root: classes.cardHeaderRoot }}
                ></CardHeader>

                {!loading ? (
                    <div className='table-container'>
                        <BootstrapTable 
                            keyField='product' 
                            data={ products } 
                            columns={ columns } 
                            filter={ filterFactory() } 
                            noDataIndication={<Box>No data for products</Box>}
                        />
                    </div>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '10vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </>
        </Card>
    );
}

export default ProductsTable