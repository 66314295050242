import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import EnergyAPI from 'config/api/EnergyAPI'
import { onError } from 'config/lib/errorLib'
import PaymentsTable from 'components/admin/PaymentsTable'
import OverduePaymentsTable from 'components/admin/OverduePaymentsTable'
import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'
import moment from 'moment-timezone'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Payments() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new AdminAPI()
    const eApi = new EnergyAPI()

    const [product, setProduct] = useState('Financial')
    const [showStatus, setShowStatus] = useState(false)
    const [payments, setPayments] = useState([])
    const [showPayments, setShowPayments] = useState([])
    const [companies, setCompanies] = useState([])
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getPayments(product)
    }, [])

    async function getPayments(product) {
        try {
            const payments = product === 'Financial' ? await api.getPayments() : await eApi.getPayments()
            console.log('payments: ', payments)
            payments.sort((a, b) => {
                return moment(b.date).valueOf() - moment(a.date).valueOf()
            })

            const companies = product === 'Financial' ? await api.getCompanies() : await eApi.getCompanies()
            const activeCompanies = companies.filter((company) => (company.active))
            activeCompanies.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
            console.log('activeCompanies: ', activeCompanies)

            let showPayments = payments.filter((payment) => (!payment.paid))

            setPayments(payments)
            setShowPayments(showPayments)
            setCompanies(activeCompanies)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updatePayment(payment) {
        const index = payments.findIndex(oldPayment => (oldPayment.companyId === payment.companyId && oldPayment.date === payment.date))
        console.log('payment index: ', index)

        let newPayments = [...payments]
        newPayments[index] = payment
        let checkStatus = showStatus === 'paid' ? true : false
        let showPayments = newPayments.filter((payment) => (payment.paid === checkStatus))
        console.log('check status: ', checkStatus)
        console.log(showPayments)
        setPayments(newPayments)
        setShowPayments(showPayments)
    }

    function updateShowStatus(showStatus) {
        //let checkStatus = showStatus === 'paid' ? true : false
        let showPayments = payments.filter((payment) => (payment.paid === showStatus))
        setShowPayments(showPayments)
        setShowStatus(showStatus)
    }

    async function updateProduct(product) {
        setProduct(product)
        setLoading(true)
        await getPayments(product)
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid
                                container
                                component={Box}
                                alignItems='center'
                                justifyContent='flex-start'
                                style={{marginBottom: '2rem'}}
                                direction='row'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '0'}}>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                //IconComponent={KeyboardArrowDown}
                                                value={product}
                                                onChange={(e) => updateProduct(e.target.value)}
                                            >
                                                <MenuItem value={'Financial'}>Financial</MenuItem>
                                                <MenuItem value={'Solar'}>Solar</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '0'}}>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                value={showStatus}
                                                onChange={(e) => updateShowStatus(e.target.value)}
                                            >
                                                <MenuItem value={false}>Unpaid</MenuItem>
                                                <MenuItem value={true}>Paid</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        {showStatus === false &&
                            <Grid item xs={12}>
                                <OverduePaymentsTable 
                                    payments={showPayments}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <PaymentsTable 
                                payments={showPayments}
                                companies={companies}
                                refreshPayments={() => getPayments(product)}
                                updatePayment={(payment) => updatePayment(payment)}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                                product={product}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default Payments