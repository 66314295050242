import React, { useState, useEffect, useContext } from 'react'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import AdminAPI from 'config/api/AdminAPI'
import EnergyAPI from 'config/api/EnergyAPI'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { onError } from 'config/lib/errorLib'
import Loader from 'components/general/Loader'
import LeadsRemainingTable from 'components/admin/LeadsRemainingTable'
import ThisWeekTable from 'components/admin/ThisWeekTable'
import AllocationStats from 'components/broker/AllocationStats'
import { productOptions } from 'config/constants/Constants'
import { getMoments } from 'config/helpers/Helpers'
import moment from 'moment-timezone'

function ThisWeek() {
    const api = new AdminAPI()
    const eApi = new EnergyAPI()

    const [product, setProduct] = useState('Financial')
    //const [activeCompanies, setActiveCompanies] = useState([])
    const [activeDeliveries, setActiveDeliveries] = useState([])
    //const [solarDeliveries, setSolarDeliveries] = useState([])
    //const [financialLeads, setFinancialLeads] = useState([])
    //const [solarLeads, setSolarLeads] = useState([])
    //const [totals, setTotals] = useState(null)
    //const [companies, setCompanies] = useState([])
    //const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    async function getData() {
        try {
            const deliveries = await api.getDeliveries()
            let activeDeliveries = deliveries.filter((delivery) => (delivery.active)).map(delivery => ({...delivery, delivered: 0}))
            activeDeliveries = activeDeliveries.filter((delivery) => {
                if (delivery.companyId === 'al' && delivery.product === 'pensions') {
                    console.log('al pensions')
                }
                else {
                    return delivery
                }
            })

            let dates = getMoments('thisWeek')
            console.log('dates: ', dates)
            console.log('from: ', dates.fromDate.format('DD/MM HH:mm'))
            console.log('to: ', dates.toDate.format('DD/MM HH:mm'))

            let leads = []
            for (let product of productOptions) {
                let productLeads = await api.getAllLeadsPeriod(product.value, dates.fromDate.valueOf(), dates.toDate.valueOf())
                console.log(product.value + ': ', productLeads.length)
                leads = leads.concat(productLeads)
            }
            console.log('leads: ', leads.length)

            for (let lead of leads) {
                let index = activeDeliveries.findIndex((delivery) => (delivery.companyId === lead.companyId && delivery.product === lead.product))
                //console.log('index: ', index)
                if (index > -1) {
                    activeDeliveries[index]['delivered'] += 1
                }
            }
            activeDeliveries = activeDeliveries.map(delivery => ({...delivery, remaining: (parseInt(delivery.allocation) + parseInt(delivery.extraAllocation)) - parseInt(delivery.delivered)}))
            activeDeliveries.sort((a, b) => {
                return b.remaining - a.remaining
            })
            console.log('active deliveries updated: ', activeDeliveries)

            setActiveDeliveries(activeDeliveries)
            setToDate(dates.toDate)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function updateProduct(product) {
        setProduct(product)
        setLoading(true)
        try {
            const deliveries = product === 'Financial' ? await api.getDeliveries() : await eApi.getSfDeliveries()
            let activeDeliveries = product === 'Financial' ? 
                deliveries.filter((delivery) => (delivery.active)).map(delivery => ({...delivery, delivered: 0}))
            : 
                deliveries.filter((delivery) => (delivery.active && delivery.companyId !== 'pvg')).map(delivery => ({...delivery, delivered: 0, deliveredSf: 0, deliveredEe: 0}))
            if (product === 'Financial') {
                activeDeliveries = activeDeliveries.filter((delivery) => {
                    if (delivery.companyId === 'al' && delivery.product === 'pensions') {
                        console.log('al pensions')
                    }
                    else {
                        return delivery
                    }
                })
            }

            let dates = getMoments('thisWeek')
            console.log('dates: ', dates)

            let leads = []

            if (product === 'Financial') {
                for (let product of productOptions) {
                    let productLeads = await api.getAllLeadsPeriod(product.value, dates.fromDate.valueOf(), dates.toDate.valueOf())
                    console.log(product.value + ': ', productLeads.length)
                    leads = leads.concat(productLeads)
                }
            }
            else {
                leads = await eApi.getSfLeadsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
                let eeLeads = await eApi.getEeLeadsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
                leads = leads.concat(eeLeads)
            }
            console.log('leads: ', leads.length)

            for (let lead of leads) {
                let index = activeDeliveries.findIndex((delivery) => (delivery.companyId === lead.companyId && delivery.product === lead.product))
                console.log('index: ', index)
                if (index > -1) {
                    activeDeliveries[index]['delivered'] += 1
                    if (product === 'Solar') {
                        if (lead.site === 'sf') {
                            activeDeliveries[index]['deliveredSf'] += 1
                        }
                        if (lead.site === 'ee') {
                            activeDeliveries[index]['deliveredEe'] += 1
                        }
                    }
                }
            }
            activeDeliveries = activeDeliveries.map(delivery => ({...delivery, remaining: parseInt(delivery.weeklyCap) - parseInt(delivery.delivered)}))
            activeDeliveries.sort((a, b) => {
                return b.remaining - a.remaining
            })
            console.log('active deliveries updated: ', activeDeliveries)

            setActiveDeliveries(activeDeliveries)
            setToDate(dates.toDate)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                //classes={{ root: classes.containerRoot }}
            >
                {alert}
                {!loading ? (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Grid
                                container
                                component={Box}
                                alignItems='center'
                                justifyContent='flex-start'
                                style={{marginBottom: '2rem'}}
                                direction='row'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '0'}}>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                //IconComponent={KeyboardArrowDown}
                                                value={product}
                                                onChange={(e) => updateProduct(e.target.value)}
                                            >
                                                <MenuItem value={'Financial'}>Financial</MenuItem>
                                                <MenuItem value={'Solar'}>Solar</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction='row' spacing={1}>
                                <Grid item xs={12}>
                                    <AllocationStats 
                                        allocation={activeDeliveries.reduce((sum, obj) => sum + (product === 'Financial' ? obj.allocation : obj.weeklyCap), 0)}
                                        extraAllocation={product === 'Financial' ? activeDeliveries.reduce((sum, obj) => sum + obj.extraAllocation, 0) : 0}
                                        delivered={activeDeliveries.reduce((sum, obj) => sum + obj.delivered, 0)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LeadsRemainingTable
                                        deliveries={activeDeliveries}
                                        toDate={toDate}
                                        product={product}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    )
}

export default ThisWeek