import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
//import QuotesModal from 'components/broker/QuotesModal'
import Button from '@material-ui/core/Button'
import EnergyAPI from 'config/api/EnergyAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import { validEmail } from 'config/constants/Constants'

import componentStyles from 'assets/theme/views/admin/validation.js'
import componentStylesForms from 'assets/theme/components/forms.js'

const useStyles = makeStyles(componentStyles)
const useStylesForms = makeStyles(componentStylesForms)

function PersonalDetails(props) {
    const classes = { ...useStyles(), ...useStylesForms() }
    const api = new EnergyAPI()

    const details = props.lead

    const [phone, setPhone] = useState(details.phone || '')
    const [email, setEmail] = useState(details.email || '')
    const [emailValidation, setEmailValidation] = useState(null)
    const [firstName, setFirstName] = useState(details.firstName || '')
    const [firstNameValidation, setFirstNameValidation] = useState(null)
    const [lastName, setLastName] = useState(details.lastName || '')
    const [lastNameValidation, setLastNameValidation] = useState(null)
    const [eircode, setEircode] = useState(details.eircode || '')
    const [eircodeValidation, setEircodeValidation] = useState(null)
    const [county, setCounty] = useState(details.county || '')
    const [showContent, setShowContent] = useState(false)
    
    async function updateDetails() {
        try {
            let details = {
                phone,
                email,
                firstName,
                lastName,
                eircode,
                county
            }
            let result = await api.updateLeadDetails(props.lead.date, props.lead.leadId, details, props.site)

            props.updateLead(result)
            NotificationManager.success('Details updated')
            setShowContent(false)
        } catch(e) {
            onError(e)
        }
    }

    function checkRequiredFields() {
        if (firstName.length > 0) {
            return false
        }
        else {
            return true
        }
    }
    
    return (
        <Card>
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Grid item xs='auto'>
                            <Box
                                component={Typography}
                                variant='h3'
                                marginBottom='0!important'
                            >
                                Personal Details
                            </Box>
                        </Grid>
                        <Grid item xs='auto'>
                            <Box>
                                {showContent ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                }
                onClick={() => setShowContent(!showContent)} 
                style={{cursor: 'pointer'}}
            >
            </CardHeader>
            
            {showContent &&
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>First Name *</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={firstName}
                                        classes={{
                                            notchedOutline: clsx({
                                                [classes.borderWarning]:
                                                    firstNameValidation === 'invalid',
                                                [classes.borderSuccess]:
                                                    firstNameValidation === 'valid',
                                            }),
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value === '') {
                                                setFirstNameValidation('invalid')
                                            } else {
                                                setFirstNameValidation('valid')
                                            }
                                            setFirstName(e.target.value)
                                        }}
                                    />
                                </FormControl>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Phone {details.product !== 'general' ? '*' : ''}</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={phone}
                                        //disabled={details.product !== 'general'}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Eircode</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={eircode}
                                        classes={{
                                            notchedOutline: clsx({
                                                [classes.borderWarning]:
                                                    eircodeValidation === 'invalid',
                                                [classes.borderSuccess]:
                                                    eircodeValidation === 'valid',
                                            }),
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value === '') {
                                                setEircodeValidation('invalid')
                                            } else {
                                                setEircodeValidation('valid')
                                            }
                                            setEircode(e.target.value)
                                        }}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormLabel>Last Name</FormLabel>
                                    <FormControl
                                        variant='filled'
                                        component={Box}
                                        width='100%'
                                        //marginBottom='1rem!important'
                                    >
                                        <OutlinedInput
                                            fullWidth
                                            type='text'
                                            value={lastName}
                                            classes={{
                                                notchedOutline: clsx({
                                                    [classes.borderWarning]:
                                                        lastNameValidation === 'invalid',
                                                    [classes.borderSuccess]:
                                                        lastNameValidation === 'valid',
                                                }),
                                            }}
                                            onChange={(e) => {
                                                if (e.target.value === '') {
                                                    setLastNameValidation('invalid')
                                                } else {
                                                    setLastNameValidation('valid')
                                                }
                                                setLastName(e.target.value)
                                            }}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl
                                        variant='filled'
                                        component={Box}
                                        width='100%'
                                        //marginBottom='1rem!important'
                                    >
                                        <OutlinedInput
                                            fullWidth
                                            type='email'
                                            value={email}
                                            classes={{
                                                notchedOutline: clsx({
                                                    [classes.borderWarning]:
                                                        emailValidation === 'invalid',
                                                    [classes.borderSuccess]:
                                                        emailValidation === 'valid',
                                                }),
                                            }}
                                            onChange={(e) => {
                                                if (!validEmail.test(e.target.value)) {
                                                    setEmailValidation('invalid')
                                                } else {
                                                    setEmailValidation('valid')
                                                }
                                                setEmail(e.target.value)
                                            }}
                                        />
                                    </FormControl>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel>County</FormLabel>
                                    <FormControl
                                        variant='filled'
                                        component={Box}
                                        width='100%'
                                        //marginBottom='1rem!important'
                                    >
                                        <OutlinedInput
                                            fullWidth
                                            type='text'
                                            value={county}
                                            onChange={(e) => setEircode(e.target.value)}
                                        />
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} lg={12}>        
                            <Grid container justifyContent='space-between'>
                                <Grid item>
                                    {/*
                                    <Button
                                        onClick={() => setShowQuotesModal(true)}
                                        color='primary'
                                        disabled
                                    >
                                        Generate Quotes
                                    </Button>
                                    */}
                                </Grid>
                                <Grid item>
                                    <Button
                                        component={Box}
                                        color='primary'
                                        variant='contained'
                                        size='medium'
                                        disabled={checkRequiredFields()}
                                        onClick={() => updateDetails()}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            }
        </Card>
    )
}

export default PersonalDetails