import React, { Component } from 'react'
import GuestRouter from 'config/routing/GuestRouter'
import ChangePwRouter from 'config/routing/ChangePwRouter'
import BrokerRouter from 'config/routing/BrokerRouter'
import AdminRouter from 'config/routing/AdminRouter'
import { NotificationContainer } from 'react-notifications'
import { withRouter } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import Loader from 'components/general/Loader'
import brokerApi from 'config/api/BrokerAPI'
//import clientApi from 'config/api/ClientAPI'
import { DetailsProvider } from 'config/context/DetailsContext'
import { onError } from 'config/lib/errorLib'
import ErrorBoundary from 'components/general/ErrorBoundary'
import moment from 'moment-timezone'

// set default timezone for entire app
moment.tz.setDefault('Europe/Dublin')

const isLocal = process.env.NODE_ENV === 'development'

// Turn off logs in production
if (!isLocal) {
    console.log = function() {}
}

class App extends Component {
    constructor(props) {
        super(props)
        console.log(this.props)
        this.state = {
            loading: true,
            accountType: null,
            details: null,
            changePw: false,
            companyDetails: null,
            name: null
        }
        this.api = new brokerApi()
    }

    async componentDidMount() {
        await this.checkCurrentUser()
    }

    async checkCurrentUser() {
        try {
            const info = await Auth.currentUserInfo()
            console.log('info: ', info)
            console.log(this.props.authState)

            if (this.props.authState === 'signedIn' && Object.keys(info).length === 0) {
                this.logout()
            }

            let accountType = info ? info.attributes['custom:account_type'] : null
            let details = false
            let companyId = info ? info.attributes['custom:company_id'] : null
            let companyDetails = false
            let changePw = false
            let paymentMethod = false

            if (accountType === 'broker') {
                details = await this.api.getDetails(companyId)
                console.log('details: ', details)
                companyDetails = await this.api.getCompanyDetails(companyId)
                console.log('company details: ', companyDetails)

                if (!details) {
                    console.log('no details, first login, force pw change')
                    let firstName = info.attributes['name'].split(' ')[0]
                    let lastName = info.attributes['name'].split(' ')[1]
                    let permissions = info.attributes['custom:permissions']
                    changePw = true
                    details = {
                        firstName,
                        lastName,
                        permissions
                    }
                    // let email = info.attributes['email']
                    // let phone = info.attributes['phone_number']
                    // details = await this.api.addDetails(companyId, firstName, lastName, email, phone, permissions)
                    // console.log('broker details: ', details)
                }

                if (companyDetails.stripeCustomerId) {
                    let paymentMethods = await this.api.getPaymentMethods(companyDetails.stripeCustomerId)
                    console.log('payment methods: ', paymentMethods)
                    paymentMethod = paymentMethods.paymentMethods && paymentMethods.paymentMethods.data.length > 0 ? true : false
                }
            }

            this.setState({
                accountType: accountType,
                details: details,
                changePw: changePw,
                companyDetails: companyDetails,
                paymentMethod: paymentMethod,
                loading: false
            })
        } catch(e) {
            onError(e)
        }
    }

    logout() {
        Auth.signOut().then((user) => {
            //window.location.replace('/')
            //this.props.history.push('/')
            console.log(user)
        }).catch(err => 
            onError(err)
        )
    }

    render() {
        return (
            <ErrorBoundary>
                <>
                    <NotificationContainer />
                    {!this.state.loading ? (
                        <>
                            {this.props.authState === 'signedIn' ? (
                                <>
                                    {this.state.accountType === 'broker' &&
                                        <DetailsProvider value={this.state.companyDetails}>
                                            {this.state.changePw ? (
                                                <ChangePwRouter details={this.state.details} />
                                            ) : (
                                                <BrokerRouter companyDetails={this.state.companyDetails} details={this.state.details} paymentMethod={this.state.paymentMethod} />
                                            )}
                                        </DetailsProvider>
                                    }
                                    {this.state.accountType === 'admin' &&
                                        <AdminRouter />
                                    }
                                </>
                            ) : (
                                <GuestRouter />
                            )}
                        </>
                    ) : (
						<Loader 
							className='full-page-loader'
						/>
                    )}
                </>
            </ErrorBoundary>
        )
    }
}

export default withRouter(App)