import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { NotificationManager } from 'react-notifications'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import { callOutcomes } from 'config/constants/Constants'
import DetailsContext from 'config/context/DetailsContext'
import moment from 'moment-timezone'

function AddCall(props) {
    const api = new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
	const companyId = companyDetails.companyId

    const lead = props.lead

    const [callDate, setCallDate] = useState(moment().format('YYYY-MM-DD'))
	const [callTime, setCallTime] = useState(moment().format('HH:mm'))
	const [callOutcome, setCallOutcome] = useState('answer')
	const [callNote, setCallNote] = useState('')

    console.log(callDate)

    async function addCall() {
		console.log('add call...')

		let date = new Date(callDate + ' ' + callTime)
		
		try {
            //let result = lead.product === 'general' ? await api.addGeneralCall(companyId, lead.date, lead.createdAt, date, callOutcome, callNote) : await api.addLeadCall(companyId, lead.product, lead.phone, date, callOutcome, callNote)
            let result = await api.addLeadCall(companyId, lead.product, lead.phone, date, callOutcome, callNote)
            console.log(result)

			setCallDate(Date.now())
			setCallOutcome('answer')
			setCallNote('')
			props.updateLead(result)
            props.openActivity()
            props.hide()
			NotificationManager.success('Call added')
		} catch(e) {
			onError(e)
		}
	}

	function checkCallFields() {
		if (callDate.length > 0 && callTime.length > 0 && callOutcome.length > 0) {
			return false
		}
		else {
			return true
		}
	}
    
    return (
        <Grid container>
            <Grid item xs={12} lg={4}>
                <FormGroup>
                    <FormLabel>Date</FormLabel>
                    <FormControl
                        variant='filled'
                        component={Box}
                        width='100%'
                    >
                        <OutlinedInput
                            fullWidth
                            type='date'
                            value={callDate}
                            onChange={(e) => setCallDate(e.target.value)}
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Time</FormLabel>
                    <FormControl
                        variant='filled'
                        component={Box}
                        width='100%'
                    >
                        <OutlinedInput
                            fullWidth
                            type='time'
                            value={callTime}
                            onChange={(e) => setCallTime(e.target.value)}
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Outcome</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            IconComponent={KeyboardArrowDown}
                            value={callOutcome}
                            onChange={(e) => setCallOutcome(e.target.value)}
                        >
                            {Object.keys(callOutcomes).map((key) => {
                                return (<MenuItem value={key}>{callOutcomes[key]}</MenuItem>)

                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
            </Grid>
            <Grid item xs={12} lg={8}>
                <FormGroup>
                    <FormLabel>Note</FormLabel>
                    <FormControl
                        variant='filled'
                        component={Box}
                        width='100%'
                        //marginBottom='1rem!important'
                    >
                        <OutlinedInput
                            fullWidth
                            type='text'
                            multiline
                            minRows={5}
                            value={callNote}
                            onChange={(e) => setCallNote(e.target.value)}
                        />
                    </FormControl>
                </FormGroup>
            </Grid>
            <Grid item xs={12}>
                <Box display='flex' justifyContent='flex-end'>
                    <Button
                        onClick={() => props.hide()}
                        color='primary'
                    >
                        Cancel
                    </Button>
                    <Button
                        component={Box}
                        onClick={() => addCall()}
                        color='primary'
                        style={{marginLeft: '1rem'}}
                        variant='contained'
                        disabled={checkCallFields()}
                    >
                        Save
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}

export default AddCall