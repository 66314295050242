import React, { useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import moment from 'moment-timezone'

function PeriodSelect(props) {
    const theme = useTheme()
    const [fromDate, setFromDate] = useState(props.fromDate)
    const [toDate, setToDate] = useState(props.toDate)

    function updateFromDate(date) {
        if (moment(date).day() === 6) {
            setFromDate(date)
        }
        else {
            alert('From Date must be a Saturday')
        }
    }

    function updateToDate(date) {
        if (moment(date).day() === 5) {
            setToDate(date)
        }
        else {
            alert('To Date must be a Saturday')
        }
    }

    function updateDates() {
        props.updatePeriod(fromDate, toDate)
    }

    return (
        <>
            <FormGroup style={{marginBottom: '0'}}>
                <Grid container direction='row'>
                
                <Grid item>
                    <FormLabel>From Date</FormLabel>
                    <OutlinedInput
                        fullWidth
                        type='date'
                        value={moment(fromDate).format('YYYY-MM-DD')}
                        defaultValue={moment(fromDate).format('YYYY-MM-DD')}
                        onChange={(e) => updateFromDate(e.target.value)}
                        style={{marginBottom: '0.5rem'}}
                    />
                    <Box
                        fontSize='12px'
                        color={theme.palette.gray[600]}
                        //marginBottom='0.2rem'
                        //marginLeft='1rem'
                        marginTop='0.5rem'
                        textAlign='left'
                        //className='online-discount'
                    >
                        <Box component='small' fontSize='100%'>
                            Showing from {moment(props.fromDate).format('DD/MM')} - {moment(props.toDate).format('DD/MM')}
                        </Box>
                    </Box>
                </Grid>
                <Grid item>
                    <FormLabel>To Date</FormLabel>
                    <OutlinedInput
                        fullWidth
                        type='date'
                        value={moment(toDate).format('YYYY-MM-DD')}
                        defaultValue={moment(toDate).format('YYYY-MM-DD')}
                        onChange={(e) => updateToDate(e.target.value)}
                        style={{marginBottom: '0.5rem'}}
                    />
                </Grid>
                <Grid item>
                    <FormLabel style={{color: '#fff'}}>Hello</FormLabel>
                    <Button
                        component={Box}
                        onClick={() => updateDates()}
                        color='primary'
                        variant='contained'
                        size='medium'
                        disabled={!(fromDate && toDate)}
                        style={{width: '100%'}}
                    >
                        {(fromDate && toDate) ? (
                            <>Load {moment(fromDate).format('DD/MM')} - {moment(toDate).format('DD/MM')}</>
                        ) : (
                            <>Select dates</>
                        )}
                    </Button>
                </Grid>

                </Grid>
            </FormGroup>
        </>
    )
}

export default PeriodSelect