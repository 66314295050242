import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter'
import cellEditFactory from 'react-bootstrap-table2-editor'

function AllocationTable(props) {
    let arr = props.product === 'Financial' ? [
        {product: 'life-insurance', allocation: 0, leadPrice: 0},
        {product: 'income-protection', allocation: 0, leadPrice: 0},
        {product: 'pensions', allocation: 0, leadPrice: 0},
        {product: 'mortgage-protection', allocation: 0, leadPrice: 0},
        {product: 'health-insurance', allocation: 0, leadPrice: 0}
    ] : [
        {product: 'Solar', allocation: 0, leadPrice: 0}
    ]

    if (props.allocations) {
        for (const [product, values] of Object.entries(props.allocations)) {
            console.log(product)
            console.log(values)
            for (let item of arr) {
                if (item.product === product) {
                    if (values.hasOwnProperty('allocation')) {
                        item.allocation = values.allocation
                        item.leadPrice = values.leadPrice
                    }
                    else {
                        item.allocation = values
                        item.leadPrice = 0
                    }
                }
            }
        }
    }

    const [allocations, setAllocations] = useState(arr)

    let columns = [
        {
            dataField: 'product',
            text: 'Product',
            sort: true,
            editable: false,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.product}</>
            }
        },
        {
            dataField: 'allocation',
            text: 'Allocation',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (
                    <>{row.allocation}</>
                )
            }
        },
        {
            dataField: 'leadPrice',
            text: 'Lead Price (€)',
            sort: true,
            editable: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (
                    <>{row.leadPrice}</>
                )
            }
        }
    ]

    const handleTableChange = (type, { data, cellEdit: { rowId, dataField, newValue } }) => {
        console.log(rowId)
        console.log(dataField)
        console.log(newValue)

        // update state to new field
        const result = data.map((row) => {
            console.log(row)
            if (row.product === rowId) {
                const newRow = { ...row }
                // if (newRow[dataField] !== newValue) {
                //     setUnsavedChanges(true)
                // }
                newRow[dataField] = newValue
                return newRow
            }
            return row
        })
        console.log('table change result: ', result)
        updateAllocations(result)
    }

    function updateAllocations(allocations) {
        setAllocations(allocations)
        // convert to key value object and update in parent
        let obj = {}
        for (let alloc of allocations) {
            obj[alloc.product] = {
                allocation: alloc.allocation,
                leadPrice: alloc.leadPrice
            }
        }
        props.updateAllocations(obj)
    }

    return (
        <div className='table-container'>
            <BootstrapTable 
                keyField='product' 
                data={ allocations } 
                columns={ columns } 
                filter={ filterFactory() } 
                noDataIndication={<Box>No brokers</Box>}
                cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
                onTableChange={ handleTableChange }
                remote={ {
                    filter: false,
                    pagination: false,
                    sort: false,
                    cellEdit: true
                } }
            />
        </div>
    );
}

export default AllocationTable