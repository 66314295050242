import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import componentStyles from 'assets/theme/views/admin/react-bs-table.js'
import AdminAPI from 'config/api/AdminAPI'
import Loader from 'components/general/Loader'
import { onError } from 'config/lib/errorLib'

const useStyles = makeStyles(componentStyles)

function OverduePaymentsTable(props, ref) {
    const classes = { ...useStyles() }
    const payments = props.payments

    let companyCounts = {}

    for (let payment of payments) {
        console.log(companyCounts[payment.companyName])
        if (companyCounts[payment.companyName]) {
            companyCounts[payment.companyName]['invoices']++
            companyCounts[payment.companyName]['total'] += parseFloat(payment.total)
        }
        else {
            companyCounts[payment.companyName] = {
                companyId: payment.companyId,
                invoices: 1,
                total: parseFloat(payment.total) 
            }
        }
    }
    console.log(companyCounts)

    let companies = []
    let total = 0

    for (let company of Object.keys(companyCounts)) {
        total += parseFloat(companyCounts[company]['total'])
        companies.push({
            companyName: company,
            invoices: companyCounts[company]['invoices'],
            total: companyCounts[company]['total']
        })
    }
    companies.sort((a, b) => {
        return b.total - a.total
    })

    console.log('companies: ', companies)
    console.log('total: ', total)

    return (
        <Card>
            <CardHeader
                subheader={
                    <>
                        <Box display='flex' style={{justifyContent: 'space-between'}}>
                            <Box>
                                <Box
                                    component={Typography}
                                    variant='h2'
                                    marginBottom='0!important'
                                >
                                    <Box component='span'>Totals Oustanding</Box>
                                </Box>
                            </Box>
                        </Box>
                    </>
                }
                classes={{ root: classes.cardHeaderRoot }}
            ></CardHeader>

            <div className='table-container'>
                <BootstrapTable 
                    keyField='companyId' 
                    data={ companies } 
                    columns={ 
                        [
                            {
                                dataField: 'companyName',
                                text: 'Name',
                                //sort: true,
                                //filter: textFilter(),
                                formatter: (cell, row) => {
                                    return (<Box>{row.companyName}</Box>)
                                },
                                footer: 'Total'
                            },
                            {
                                dataField: 'invoices',
                                text: 'Invoices Due',
                                //sort: true,
                                //filter: textFilter(),
                                formatter: (cell, row) => {
                                    return (<Box>{row.invoices}</Box>)
                                },
                                footer: ''
                            },
                            {
                                dataField: 'total',
                                text: 'Total Due (€)',
                                //sort: true,
                                //filter: textFilter(),
                                formatter: (cell, row) => {
                                    return (<Box>€{row.total.toFixed(2)}</Box>)
                                },
                                footer: '€' + total.toFixed(2)
                            },
                        ]
                    } 
                    filter={ filterFactory() } 
                    noDataIndication={<Box>No outstanding invoices</Box>}
                />
            </div>
        </Card>
    );
}

export default OverduePaymentsTable