import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import EnergyAPI from 'config/api/EnergyAPI'
import { onError } from 'config/lib/errorLib'
import LeadsTable from 'components/admin/LeadsTable'
import EnergyLeadsTable from 'components/admin/Energy/LeadsTable'
import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'
import { getMoments, getProductString } from 'config/helpers/Helpers'
import Settings from '@material-ui/icons/Settings'
import moment from 'moment-timezone'
import PeriodSelect from 'components/general/PeriodSelect'
import ActiveProductsModal from 'components/admin/ActiveProductsModal'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Leads() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const theme = useTheme()
    const api = new AdminAPI()
    const energyApi = new EnergyAPI()

    const [leads, setLeads] = useState([])
    const [showLeads, setShowLeads] = useState([])
    const [showStatus, setShowStatus] = useState('Unallocated')
    const [period, setPeriod] = useState('thisWeek')
    const [sourceOptions, setSourceOptions] = useState([])
    const [source, setSource] = useState('all')
    const [product, setProduct] = useState('pensions')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [company, setCompany] = useState('all')
    const [companies, setCompanies] = useState([])
    const [solarCompanies, setSolarCompanies] = useState([])
    // const [loadMore, setLoadMore] = useState(false)
    // const [moreLeadsLoading, setMoreLeadsLoading] = useState(false)
    const [createdOnly, setCreatedOnly] = useState(false)
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)
    const [productsAvailable, setProductsAvailable] = useState([])
    const [showActiveProducts, setShowActiveProducts] = useState(false)

    useEffect(() => {
        getLeads()
    }, [])

    async function getLeads() {
        try {
            let dates = getMoments(period)

            let productsAvailable = await api.getProductsAvailable()
            console.log('products available: ', productsAvailable.productsAvailable)

            let leads = await api.getAllLeadsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
            let sourceOptions = new Set()
            leads.sort((a, b) => {
                sourceOptions.add(a.source)
                return b.createdAt - a.createdAt
            })
            console.log('leads size: ', leads.length)
            console.log('source options: ', sourceOptions)
            
            let companies = await api.getCompanies()
            console.log('companies: ', companies)
            const activeCompanies = companies.filter((company) => (company.active))
            activeCompanies.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })

            let solarCompanies = await energyApi.getCompanies()
            console.log('solarCompanies: ', solarCompanies)
            const activeSolarCompanies = solarCompanies.filter((company) => (company.active))
            activeSolarCompanies.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })

            setCompanies(activeCompanies)
            setSolarCompanies(activeSolarCompanies)
            setLeads(leads)
            setShowLeads(leads.filter((lead) => (lead.companyId === null && lead.isValid)))
            setFromDate(dates.fromDate)
            setToDate(dates.toDate)
            setSourceOptions(sourceOptions)
            //setLoadMore(response.loadMore)
            setProductsAvailable(productsAvailable.productsAvailable)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function updateProduct(product) {
        setLoading(true) 
        let dates = getMoments(period)
        try {
            let leads = []
            if (product === 'all') {
                for (let product of Object.keys(productsAvailable)) {
                    if (productsAvailable[product]) {
                        let productLeads = await api.getAllLeadsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
                        console.log(product + ': ', productLeads.length)
                        leads = leads.concat(productLeads)
                    }
                }
            }
            else if (product === 'Solar') {
                let eeLeads = await energyApi.getEeLeadsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
                let sfLeads = await energyApi.getSfLeadsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
                leads = eeLeads.concat(sfLeads)
            }
            else {
                leads = await api.getAllLeadsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
            }
            let sourceOptions = new Set()
            leads.sort((a, b) => {
                sourceOptions.add(a.source)
                return b.createdAt - a.createdAt
            })
            console.log('leads size: ', leads.length)
            let showLeads = []
            if (showStatus === 'Unallocated') {
                showLeads = leads.filter((lead) => lead.companyId === null && lead.isValid)
            }
            else if (showStatus === 'Allocated') {
                showLeads = leads.filter((lead) => lead.companyId !== null)
            }
            else if (showStatus === 'Rejected') {
                showLeads = leads.filter((lead) => lead.companyId === null && !lead.isValid)
            }
            console.log('show leads: ', showLeads)
            console.log('product: ', product)
            setLeads(leads)
            setSourceOptions(sourceOptions)
            setShowLeads(showLeads)
            setProduct(product)
            setSource('all')
            setCompany('all')
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function updatePeriod(period, fromDate=null, toDate=null) {
        setPeriod(period)
        setLoading(true) 
        let dates = period === 'custom' ? {
            fromDate: moment(fromDate).startOf('day'),
            toDate: moment(toDate).endOf('day')
        } : getMoments(period)
        
        try {
            let leads = []
            if (product === 'all') {
                for (let product of Object.keys(productsAvailable)) {
                    if (productsAvailable[product]) {
                        let productLeads = await api.getAllLeadsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
                        console.log(product + ': ', productLeads.length)
                        leads = leads.concat(productLeads)
                    }
                }
            }
            else if (product === 'Solar') {
                let eeLeads = await energyApi.getEeLeadsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
                let sfLeads = await energyApi.getSfLeadsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
                leads = eeLeads.concat(sfLeads)
            }
            else {
                leads = await api.getAllLeadsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
            }
            let sourceOptions = new Set()
            leads.sort((a, b) => {
                sourceOptions.add(a.source)
                return b.createdAt - a.createdAt
            })
            console.log('leads size: ', leads.length)
            let showLeads = []
            if (showStatus === 'Unallocated') {
                showLeads = leads.filter((lead) => lead.companyId === null && lead.isValid)
            }
            else if (showStatus === 'Allocated') {
                showLeads = leads.filter((lead) => lead.companyId !== null)
            }
            else if (showStatus === 'Rejected') {
                showLeads = leads.filter((lead) => lead.companyId === null && !lead.isValid)
            }
            console.log('show leads: ', showLeads)
            setLeads(leads)
            setShowLeads(showLeads)
            setFromDate(dates.fromDate)
            setSourceOptions(sourceOptions)
            setToDate(dates.toDate)
            setSource('all')
            setCompany('all')
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updateSource(source) {
        //let checkStatus = showStatus === 'paid' ? true : false
        //setLeadsLoading(true)
        let showLeads = []
        if (showStatus === 'Unallocated') {
            showLeads = leads.filter((lead) => lead.companyId === null && lead.isValid)
        }
        else if (showStatus === 'Allocated') {
            showLeads = leads.filter((lead) => lead.companyId !== null)
        }
        else if (showStatus === 'Rejected') {
            showLeads = leads.filter((lead) => lead.companyId === null && !lead.isValid)
        }
        if (source !== 'all') {
            showLeads = showLeads.filter((lead) => lead.source === source)
        }
        if (company !== 'all') {
            showLeads = showLeads.filter((lead) => lead.companyId === company)
        }
        if (createdOnly) {
            showLeads = showLeads.filter((lead) => moment(lead.createdAtOriginal).isBetween(moment(fromDate), moment(toDate)))
        }
        console.log('show leads: ', showLeads)
        setShowLeads(showLeads)
        setSource(source)
        //setLoading(false) 
    }

    function updateShowStatus(showStatus) {
        //let checkStatus = showStatus === 'paid' ? true : false
        //setLeadsLoading(true)
        let showLeads = []
        if (showStatus === 'Unallocated') {
            showLeads = leads.filter((lead) => lead.companyId === null && lead.isValid)
        }
        else if (showStatus === 'Allocated') {
            showLeads = leads.filter((lead) => lead.companyId !== null)
        }
        else if (showStatus === 'Rejected') {
            showLeads = leads.filter((lead) => lead.companyId === null && !lead.isValid)
        }
        if (source !== 'all') {
            showLeads = showLeads.filter((lead) => lead.source === source)
        }
        if (createdOnly) {
            showLeads = showLeads.filter((lead) => moment(lead.createdAtOriginal).isBetween(moment(fromDate), moment(toDate)))
        }
        console.log('show leads: ', showLeads)
        setShowLeads(showLeads)
        setShowStatus(showStatus)
        setCreatedOnly(false)
        //setLeadsLoading(false)
        //setLoading(false) 
    }

    function updateCompany(companyId) {
        console.log('update company: ', company)
        let showLeads = []
        if (showStatus === 'Unallocated') {
            showLeads = leads.filter((lead) => lead.companyId === null && lead.isValid)
        }
        else if (showStatus === 'Allocated') {
            showLeads = leads.filter((lead) => lead.companyId !== null)
        }
        else if (showStatus === 'Rejected') {
            showLeads = leads.filter((lead) => lead.companyId === null && !lead.isValid)
        }
        if (companyId !== 'all') {
            showLeads = showLeads.filter((lead) => lead.companyId === companyId)
        }
        if (source !== 'all') {
            showLeads = showLeads.filter((lead) => lead.source === source)
        }
        if (createdOnly) {
            showLeads = showLeads.filter((lead) => moment(lead.createdAtOriginal).isBetween(moment(fromDate), moment(toDate)))
        }
        console.log('show leads: ', showLeads)
        setShowLeads(showLeads)
        setCompany(companyId)
        //setLoading(false) 
    }

    function updateCreatedOnly(createdOnly) {
        console.log('created only: ', createdOnly)
        let showLeads = []
        if (showStatus === 'Unallocated') {
            showLeads = leads.filter((lead) => lead.companyId === null && lead.isValid)
        }
        else if (showStatus === 'Allocated') {
            showLeads = leads.filter((lead) => lead.companyId !== null)
        }
        else if (showStatus === 'Rejected') {
            showLeads = leads.filter((lead) => lead.companyId === null && !lead.isValid)
        }
        if (company !== 'all') {
            showLeads = showLeads.filter((lead) => lead.companyId === company)
        }
        if (source !== 'all') {
            showLeads = showLeads.filter((lead) => lead.source === source)
        }
        if (createdOnly) {
            showLeads = showLeads.filter((lead) => moment(lead.createdAtOriginal).isBetween(moment(fromDate), moment(toDate)))
        }
        console.log('show leads: ', showLeads)
        setShowLeads(showLeads)
        setCreatedOnly(createdOnly)
    }

    function updateLead(lead) {
        const index = leads.findIndex(oldLead => (oldLead.leadId === lead.leadId))
        console.log('lead index: ', index)

        let newLeads = [...leads]
        newLeads[index] = lead
        console.log(newLeads)
        //setLeads(newLeads.filter((lead) => lead.companyId === null))
        let showLeads = []
        if (showStatus === 'Unallocated') {
            showLeads = newLeads.filter((lead) => lead.companyId === null && lead.isValid)
        }
        else if (showStatus === 'Allocated') {
            showLeads = newLeads.filter((lead) => lead.companyId !== null)
        }
        else if (showStatus === 'Rejected') {
            showLeads = newLeads.filter((lead) => lead.companyId === null && !lead.isValid)
        }
        console.log('show leads: ', showLeads)
        setShowLeads(showLeads)
        setLeads(newLeads)
    }

    // function updateLeads(leads) {
    //     let newLeads = [...leads]
    //     for (let lead of leads) {
    //         const index = leads.findIndex(oldLead => (oldLead.leadId === lead.leadId))
    //         console.log('lead index: ', index)

    //         newLeads[index] = lead
    //     }
    //     //setLeads(newLeads.filter((lead) => lead.companyId === null))
    //     let showLeads = []
    //     if (showStatus === 'Unallocated') {
    //         showLeads = newLeads.filter((lead) => lead.companyId === null && lead.isValid)
    //     }
    //     else if (showStatus === 'Allocated') {
    //         showLeads = newLeads.filter((lead) => lead.companyId !== null)
    //     }
    //     else if (showStatus === 'Rejected') {
    //         showLeads = newLeads.filter((lead) => lead.companyId === null && !lead.isValid)
    //     }
    //     console.log('show leads: ', showLeads)
    //     setShowLeads(showLeads)
    //     setLeads(newLeads)
    // }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                <Grid container>
                    <Grid item xs={12}>
                        <Box display='flex' direction='row' justifyContent='flex-end'>
                            <Settings onClick={() => setShowActiveProducts(true)} style={{color: '#525f7f', width: '20px', height: '20px', cursor: 'pointer', marginBottom: '5px'}} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            container
                            component={Box}
                            alignItems='center'
                            justifyContent='flex-start'
                            style={{marginBottom: '2rem'}}
                            direction='row'
                        >
                            <Grid item xs={12} md={2}>
                                <PeriodSelect
                                    period={period}
                                    updatePeriod={(period, fromDate, toDate) => updatePeriod(period, fromDate, toDate)}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormGroup style={{marginBottom: '0'}}>
                                    <FormControl variant='outlined'>
                                        <Select
                                            multiple={false}
                                            defaultValue={null}
                                            //IconComponent={KeyboardArrowDown}
                                            value={product}
                                            onChange={(e) => updateProduct(e.target.value)}
                                        >
                                            <MenuItem value={'all'}>Financial (All)</MenuItem>
                                            <MenuItem value={'Solar'}>Solar (All)</MenuItem>
                                            {Object.keys(productsAvailable).map((key) => {
                                                if (productsAvailable[key]) {
                                                    return (<MenuItem value={key}>{getProductString(key)}</MenuItem>)
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                                <Box
                                    fontSize='12px'
                                    color={theme.palette.gray[600]}
                                    //marginBottom='0.2rem'
                                    //marginLeft='1rem'
                                    marginTop='0.5rem'
                                    textAlign='left'
                                    //className='online-discount'
                                >
                                    <Box component='small' fontSize='100%'>
                                        Product
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormGroup style={{marginBottom: '0'}}>
                                    <FormControl variant='outlined'>
                                        <Select
                                            multiple={false}
                                            defaultValue={null}
                                            //IconComponent={KeyboardArrowDown}
                                            value={source}
                                            onChange={(e) => updateSource(e.target.value)}
                                        >
                                            <MenuItem value={'all'}>All</MenuItem>
                                            {[...sourceOptions].map((option) => {
                                                return (<MenuItem value={option}>{option}</MenuItem>)
                                            })}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                                <Box
                                    fontSize='12px'
                                    color={theme.palette.gray[600]}
                                    //marginBottom='0.2rem'
                                    //marginLeft='1rem'
                                    marginTop='0.5rem'
                                    textAlign='left'
                                    //className='online-discount'
                                >
                                    <Box component='small' fontSize='100%'>
                                        Source
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormGroup style={{marginBottom: '0'}}>
                                    <FormControl variant='outlined' fullWidth>
                                        <Select
                                            multiple={false}
                                            value={showStatus}
                                            onChange={(e) => updateShowStatus(e.target.value)}
                                        >
                                            <MenuItem value={'Unallocated'}>Unallocated</MenuItem>
                                            <MenuItem value={'Allocated'}>Allocated</MenuItem>
                                            <MenuItem value={'Rejected'}>Rejected</MenuItem>
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                                <Box
                                    fontSize='12px'
                                    color={theme.palette.gray[600]}
                                    //marginBottom='0.2rem'
                                    //marginLeft='1rem'
                                    marginTop='0.5rem'
                                    textAlign='left'
                                    //className='online-discount'
                                >
                                    <Box component='small' fontSize='100%'>
                                        Status
                                    </Box>
                                </Box>
                            </Grid>
                            {showStatus === 'Allocated' &&
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '0'}}>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                value={company}
                                                onChange={(e) => updateCompany(e.target.value)}
                                            >
                                                <MenuItem value={'all'}>All</MenuItem>
                                                {(product === 'Solar' ? solarCompanies : companies).map((company) => {
                                                    return (
                                                        <MenuItem value={company.companyId}>{company.name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                    <Box
                                        fontSize='12px'
                                        color={theme.palette.gray[600]}
                                        //marginBottom='0.2rem'
                                        //marginLeft='1rem'
                                        marginTop='0.5rem'
                                        textAlign='left'
                                        //className='online-discount'
                                    >
                                        <Box component='small' fontSize='100%'>
                                            Company
                                        </Box>
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {showStatus === 'Allocated' &&
                        <Grid item xs={12}>
                            <Grid container direction='row' alignItems='center' justifyContent='flex-start'>
                                <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                                    <Box>
                                        <Checkbox
                                            color='primary' 
                                            checked={createdOnly} 
                                            onChange={(e) => updateCreatedOnly(e.target.checked)}
                                            //style={{paddingLeft: '0'}}
                                        />
                                    </Box>
                                    <Box>
                                        <FormLabel style={{margin: '0', padding: '0'}}>Only show created for period</FormLabel>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {product === 'Solar' ? (
                            <EnergyLeadsTable
                                leads={showLeads}
                                title={showStatus}
                                refreshLeads={() => getLeads()}
                                updateLead={(lead) => updateLead(lead)}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                                companies={solarCompanies}
                                //site='sf'
                            />
                        ) : (
                            <LeadsTable
                                leads={showLeads}
                                title={showStatus}
                                refreshLeads={() => getLeads()}
                                updateLeads={(leads) => updateLead(leads)}
                                updateLead={(lead) => updateLead(lead)}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                                companies={companies}
                                product={product}
                                showStatus={showStatus}
                            />
                        )}
                    </Grid>

                    {showActiveProducts && 
                        <ActiveProductsModal
                            isOpen={showActiveProducts}
                            close={() => setShowActiveProducts(false)}
                            products={productsAvailable}
                            updateProducts={(products) => setProductsAvailable(products)}
                            //showSuccessAlert={(text) => props.showSuccessAlert(text)}
                        />
                    }
                </Grid>
                ) : (
                    <Grid container>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default Leads