import React from 'react'
import Grid from '@material-ui/core/Grid'
import OrdersIcon from '@material-ui/icons/ListAlt'
import SalesIcon from '@material-ui/icons/EuroSymbol'
import TeamIcon from '@material-ui/icons/Group'
import Check from '@material-ui/icons/Check'
import ThumbsUpDown from '@material-ui/icons/ThumbsUpDown'
// core components
import CardStats from 'components/argon/Cards/Dashboard/CardStats.js'

const ReturnStats = ( props ) => {

    const requests = props.requests
    const direction = 'column'
    
    return (
        <Grid container direction={direction} spacing={1}>
            <Grid item xs={12} md={direction === 'row' ? 4 : 12}>
                <CardStats
                    subtitle='Requests'
                    title={requests.length}
                    icon={TeamIcon}
                    color='bgWarning'
                />
            </Grid>
            
            <Grid item xs={12} md={direction === 'row' ? 4 : 12}>
                <CardStats
                    subtitle='Accepted'
                    title={requests.filter((request) => request.status === 'accepted').length}
                    icon={Check}
                    color='bgSuccess'
                />
            </Grid>
            <Grid item xs={12} md={direction === 'row' ? 4 : 12}>
                <CardStats
                    subtitle='Accept Rate'
                    title={((requests.filter((request) => request.status === 'accepted').length / requests.length) * 100 || 0).toFixed(2) + '%'}
                    icon={ThumbsUpDown}
                    color='bgPrimary'
                />
            </Grid>
        </Grid>
    )
}

export default ReturnStats
