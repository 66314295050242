import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter'
import AddWeekModal from 'components/admin/AddWeekModal'
import WeekModal from 'components/admin/WeekModal'
import componentStyles from 'assets/theme/views/admin/react-bs-table.js'
import moment from 'moment-timezone'

const useStyles = makeStyles(componentStyles)

function ProfitabilityTable(props) {
    const classes = { ...useStyles() }

    //const payments = props.payments
    console.log('unsorted: ', props.weeks)

    let weeks = props.weeks

    const [week, setWeek] = useState(null)
    const [showWeekModal, setShowWeekModal] = useState(false)
    const [showAddWeekModal, setShowAddWeekModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            dataField: 'date',
            text: 'Date',
            //sort: true,
            //filter: textFilter(),
            formatter: (cell, row) => {
                return <>{moment(row.date).format('DD/MM/YY')}</>
            },
            footer: ''
        },
        // income protection
        {
            dataField: 'country',
            text: 'Country',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (<Box className='hover-text primary-text' onClick={() => openWeekModal(row)}>{row.hasOwnProperty('country') ? row.country.toUpperCase() : ''}</Box>)
            },
            footer: ''
        },
        {
            dataField: 'leads',
            text: '#Leads',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.hasOwnProperty('leads') ? row.leads : ''}</>
            },
            footer: ''
        },
        {
            dataField: 'cost',
            text: 'Total Cost',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.hasOwnProperty('cost') ? '€' + row.cost.toFixed(2) : ''}</>
            },
            footer: ''
        },
        {
            dataField: 'revenue',
            text: 'Net Revenue',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.hasOwnProperty('revenue') ? '€' + row.revenue.toFixed(2) : ''}</>
            },
            footer: ''
        },
        {
            dataField: 'profit',
            text: 'Profit',
            //sort: true,
            //filter: textFilter(),
            formatter: (cell, row) => {
                return <>{'€' + (row.revenue - row.cost).toFixed(2)}</>
            },
            footer: ''
        },
        {
            dataField: 'margin',
            text: 'Margin',
            //sort: true,
            //filter: textFilter(),
            formatter: (cell, row) => {
                return <>{row.revenue > 0 ? (((row.revenue - row.cost)/row.revenue) * 100).toFixed(2) + '%' : '0%'}</>
            },
            footer: ''
        }
    ]

    function openWeekModal(week) {
        setWeek(week)
        setShowWeekModal(true)
    }

    function closeWeekModal() {
        setShowWeekModal(false)
        setWeek(null)
    }

    return (
        <Card>
            {!loading ? (
                <>
                    <CardHeader
                        subheader={
                            <>
                                <Box display='flex' style={{justifyContent: 'space-between'}}>
                                    <Box>
                                        <Box
                                            component={Typography}
                                            variant='h2'
                                            marginBottom='0!important'
                                        >
                                            <Box component='span'>Profitability</Box>
                                        </Box>
                                        <Box
                                            component='p'
                                            fontSize='.875rem'
                                            marginBottom='0'
                                            marginTop='0'
                                            lineHeight='1.7'
                                            fontWeight='300'
                                        >
                                            Entries are created automatically on Saturday at 00:00 for the previous week
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Button
                                            component={Box}
                                            onClick={() => setShowAddWeekModal(true)}
                                            color='primary'
                                            variant='contained'
                                            size='small'
                                        >
                                            Add Week
                                        </Button>
                                    </Box>
                                </Box>
                            </>
                        }
                        classes={{ root: classes.cardHeaderRoot }}
                    ></CardHeader>

                    <div className='table-container'>
                        <BootstrapTable 
                            keyField='date' 
                            data={ weeks } 
                            columns={ columns } 
                            filter={ filterFactory() } 
                            noDataIndication={<Box>No weeks added</Box>}
                        />
                    </div>

                    {showAddWeekModal &&
                        <AddWeekModal 
                            isOpen={showAddWeekModal}
                            close={() => setShowAddWeekModal(false)}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            refreshWeeks={() => props.refreshWeeks()}
                            //companies={props.companies}
                            product={props.product}
                        />
                    }

                    {showWeekModal &&
                        <WeekModal 
                            isOpen={showWeekModal}
                            close={() => closeWeekModal()}
                            week={week}
                            updateWeek={(week) => props.updateWeek(week)}
                            refreshWeeks={() => props.refreshWeeks()}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            product={props.product}
                        />
                    }
                </>
            ) : (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                            <Loader />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Card>
    );
}

export default ProfitabilityTable