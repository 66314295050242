import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import CompareInsuraceTotals from 'components/admin/CI/CompareInsuranceTotals'
import ProductsTable from 'components/admin/CI/ProductsTable'
import LeadsTable from 'components/admin/CI/LeadsTable'
import ProductPie from 'components/admin/CI/ProductPie'
import ProductBar from 'components/admin/CI/ProductBar'
//import CompareInsuranceLine from 'components/admin/CompareInsuranceLine'
import { getProductString, getMoments } from 'config/helpers/Helpers' 
import { ciPrices } from 'config/constants/Constants'
import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'
import moment from 'moment-timezone'
import PeriodSelect from 'components/general/PeriodSelect'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function CompareInsurance() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const theme = useTheme()
    const api = new AdminAPI()

    const [totals, setTotals] = useState(null)
    const [leads, setLeads] = useState([])
    const [showLeads, setShowLeads] = useState([])
    const [periodLeads, setPeriodLeads] = useState([])
    const [showLeadsStatus, setShowLeadsStatus] = useState('Unallocated')
    const [products, setProducts] = useState([])
    const [companies, setCompanies] = useState([])
    const [stats, setStats] = useState(null)
    const [period, setPeriod] = useState('thisWeek')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    async function getData() {
        await getData()
        //setLoading(false)
    }

    async function getData() {
        setLoading(true)
        try {
            let companies = await api.getCompanies()
            const activeCompanies = companies.filter((company) => (company.active))
            activeCompanies.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
            console.log('companies: ', activeCompanies)

            const leads = await api.getCompareInsuranceLeads()
            console.log('all leads: ', leads)
            leads.sort((a, b) => {
                return b.createdAt - a.createdAt
            })

            let dates = getMoments('thisWeek')
            // let dates = {
            //     fromDate: moment('2024-05-01').set('hour', 0).set('minute', 0).set('second', 0),
            //     toDate: moment('2024-05-31').set('hour', 23).set('minute', 59).set('second', 59)
            // }

            let weekLeads = []
            let totals = {
                all: 0,
                allocated: 0,
                rejected: 0,
            }
            for (let product of Object.keys(ciPrices)) {
                totals[product] = 0
            }
            for (var lead of leads) {
                let created = moment(lead.createdAt);
                if (created.isBetween(dates.fromDate, dates.toDate)) {
                    weekLeads.push(lead)
                    totals.all += 1
                    totals[lead.product] += 1
                    if (lead.companyId === null && !lead.isValid) {
                        totals.rejected += 1
                    }
                    if (lead.companyId) {
                        totals.allocated += 1
                    }
                }
            }
            console.log('week leads: ', weekLeads)
            console.log('totals: ', totals)

            let productArray = []
            for(let product of Object.keys(ciPrices)) {
                productArray.push({
                    product: getProductString(product),
                    volume: totals[product],
                    price: ciPrices[product],
                    returned: 0
                })
            }
            console.log('product array: ', productArray)
            productArray.sort((a, b) => {
                return b.volume - a.volume
            })

            setLeads(leads)
            setShowLeads(leads.filter((lead) => lead.companyId === null && lead.isValid))
            setPeriodLeads(weekLeads)
            setTotals(totals)
            setProducts(productArray)
            setCompanies(activeCompanies)
            setFromDate(dates.fromDate)
            setToDate(dates.toDate)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updatePeriod(period, fromDate=null, toDate=null) {
        setPeriod(period)
        setLoading(true)
        // if custom period and dates supplied, use them, otherwise get based on period
        let dates = period === 'custom' ? {
            fromDate: moment(fromDate).startOf('day'),
            toDate: moment(toDate).endOf('day')
        } : getMoments(period)
        
        let newLeads = []
        let totals = {
            all: 0,
            allocated: 0,
            rejected: 0,
        }
        for (let product of Object.keys(ciPrices)) {
            totals[product] = 0
        }
        for (var lead of leads) {
            let created = moment(lead.createdAt);
            if (created.isBetween(dates.fromDate, dates.toDate)) {
                newLeads.push(lead)
                totals.all += 1
                totals[lead.product] += 1
                if (lead.companyId === null && !lead.isValid) {
                    totals.rejected += 1
                }
                if (lead.companyId) {
                    totals.allocated += 1
                }
            }
        }
        console.log('newLeads: ', newLeads)
        console.log('totals: ', totals)

        let productArray = []
        for(let product of Object.keys(ciPrices)) {
            productArray.push({
                product: getProductString(product),
                volume: totals[product],
                price: ciPrices[product],
                returned: 0
            })
        }
        console.log('product array: ', productArray)
        productArray.sort((a, b) => {
            return b.volume - a.volume
        })

        setPeriodLeads(newLeads)
        setTotals(totals)
        setProducts(productArray)
        setFromDate(dates.fromDate)
        setToDate(dates.toDate)
        setLoading(false)
    }

    function updateShowLeadsStatus(showLeadsStatus) {
        // if companyId === null lead is unallocated, if companyId does not exist it is from Start Pension
        let showLeads = []
        if (showLeadsStatus === 'Unallocated') {
            showLeads = leads.filter((lead) => lead.companyId === null && lead.isValid)
        }
        else if (showLeadsStatus === 'Allocated') {
            showLeads = leads.filter((lead) => lead.companyId !== null)
        }
        else if (showLeadsStatus === 'Rejected') {
            //showLeads = leads.filter((lead) => lead.companyId === null && !lead.isValid)
            showLeads = leads.filter((lead) => !lead.companyId && !lead.isValid)
        }
        setShowLeads(showLeads)
        setShowLeadsStatus(showLeadsStatus)
    }

    function updateLead(lead) {
        const index = leads.findIndex(oldLead => (oldLead.leadId === lead.leadId))
        console.log('lead index: ', index)

        let newLeads = [...leads]
        newLeads[index] = lead
        console.log(newLeads)
        //setLeads(newLeads.filter((lead) => lead.companyId === null))
        let showLeads = []
        if (showLeadsStatus === 'Unallocated') {
            showLeads = newLeads.filter((lead) => lead.companyId === null && lead.isValid)
        }
        else if (showLeadsStatus === 'Allocated') {
            showLeads = newLeads.filter((lead) => lead.companyId !== null)
        }
        else if (showLeadsStatus === 'Rejected') {
            showLeads = newLeads.filter((lead) => lead.companyId === null && !lead.isValid)
        }
        console.log('show leads: ', showLeads)
        setShowLeads(showLeads)
        setLeads(newLeads)
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    component={Box}
                                    alignItems='center'
                                    justifyContent='flex-start'
                                    style={{marginBottom: '2rem'}}
                                    direction='row'
                                >
                                    <Grid item xs={12} md={2}>
                                        <PeriodSelect
                                            period={period}
                                            updatePeriod={(period, fromDate, toDate) => updatePeriod(period, fromDate, toDate)}
                                            fromDate={fromDate}
                                            toDate={toDate}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <CompareInsuraceTotals
                                    totals={totals}
                                    leads={periodLeads.filter((lead) => lead.companyId || lead.isValid)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ProductsTable 
                                    products={products}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ProductPie
                                    // all leads
                                    leads={periodLeads}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ProductBar
                                    // all non rejected leads
                                    leads={periodLeads.filter((lead) => lead.companyId || lead.isValid)} 
                                    returns={[]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    component={Box}
                                    alignItems='center'
                                    justifyContent='flex-start'
                                    style={{marginBottom: '2rem'}}
                                    direction='row'
                                >
                                    <Grid item xs={12} md={2}>
                                        <FormGroup style={{marginBottom: '0'}}>
                                            <FormControl variant='outlined' fullWidth>
                                                <Select
                                                    multiple={false}
                                                    value={showLeadsStatus}
                                                    onChange={(e) => updateShowLeadsStatus(e.target.value)}
                                                >
                                                    <MenuItem value={'Unallocated'}>Unallocated</MenuItem>
                                                    <MenuItem value={'Allocated'}>Allocated</MenuItem>
                                                    <MenuItem value={'Rejected'}>Rejected</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <LeadsTable
                                    leads={showLeads}
                                    title={showLeadsStatus}
                                    refreshLeads={() => getData()}
                                    updateLead={(lead) => updateLead(lead)}
                                    showSuccessAlert={(text) => showSuccessAlert(text)}
                                    companies={companies}
                                />
                            </Grid>
                        </Grid>
                        {/*
                        <Grid container style={{marginTop: '2rem'}}>
                            <Grid item xs={12}>
                                {Object.keys(stats).map((product) => {
                                    return (<p>{getProductString(product)}: {stats[product]} (€{(stats[product] * prices[product]).toFixed(2)})</p>)
                                })}
                            </Grid>
                            <Grid item xs={12}>
                                <CompareInsuranceLine 
                                    leads={leads}
                                    period={period}
                                />
                            </Grid>
                        </Grid>
                        */}
                    </>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default CompareInsurance