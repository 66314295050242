import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import { getMoments, getProductString } from 'config/helpers/Helpers'
import ReturnRequestsTable from 'components/admin/ReturnRequestsTable'
import ReturnStats from 'components/admin/ReturnStats'
import ReturnStatsTable from 'components/admin/ReturnStatsTable'
import PeriodSelect from 'components/general/PeriodSelect'
import moment from 'moment-timezone'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Replacements() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const theme = useTheme()
    const api = new AdminAPI()

    const [requests, setRequests] = useState([])
    const [showRequests, setShowRequests] = useState([])
    const [companyOptions, setCompanyOptions] = useState({})
    const [company, setCompany] = useState('all')
    const [requestStatus, setRequestStatus] = useState('pending')
    const [period, setPeriod] = useState('thisWeek')
    const [product, setProduct] = useState('all')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)
    const [productsAvailable, setProductsAvailable] = useState([])

    useEffect(() => {
        getReturnRequests()
    }, [])

    async function getReturnRequests() {
        setLoading(true)
        try {
            let dates = getMoments(period)

            let response = await api.getProductsAvailable()
            console.log('products available: ', response.productsAvailable)

            let productsAvailable = response.productsAvailable

            let requests = []
            if (product === 'all') {
                for (let product of Object.keys(productsAvailable)) {
                    if (productsAvailable[product]) {
                        let productRequests = await api.getReturnRequestsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
                        console.log(product + ': ', productRequests.length)
                        requests = requests.concat(productRequests)
                    }
                }
            }
            else {
                requests = await api.getReturnRequestsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
            }
            console.log('period: ', period)
            console.log('product: ', product)
            console.log('requests: ', requests.length)

            let companyOptions = {}

            requests.sort((a, b) => {
                if (Object.keys(companyOptions).indexOf(a.companyId) < 0) {
                    companyOptions[a.companyId] = a.companyName
                }
                return b.createdAt - a.createdAt
            })
            console.log('company options: ', companyOptions)

            setRequests(requests)
            setShowRequests(requests.filter((request) => request.status === requestStatus))
            setCompanyOptions(companyOptions)
            setFromDate(dates.fromDate)
            setToDate(dates.toDate)
            setProductsAvailable(productsAvailable)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function updateProduct(product) {
        setLoading(true) 
        let dates = getMoments(period)
        try {
            let requests = []
            if (product === 'all') {
                for (let product of Object.keys(productsAvailable)) {
                    if (productsAvailable[product]) {
                        let productRequests = await api.getReturnRequestsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
                        console.log(product + ': ', productRequests.length)
                        requests = requests.concat(productRequests)
                    }
                }
            }
            else {
                requests = await api.getReturnRequestsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
            }
            requests.sort((a, b) => {
                return b.createdAt - a.createdAt
            })
            console.log('requests size: ', requests.length)
            let showRequests = []
            if (company !== 'all') {
                showRequests = requests.filter((request) => request.companyId === company && request.status === requestStatus)
            }
            else {
                showRequests = requests.filter((request) => request.status === requestStatus)
            }
            console.log('show requests: ', showRequests)
            console.log('product: ', product)
            
            setRequests(requests)
            setShowRequests(showRequests)
            setProduct(product)
            setCompany('all')
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function updatePeriod(period, fromDate=null, toDate=null) {
        setPeriod(period)
        setLoading(true) 
        let dates = period === 'custom' ? {
            fromDate: moment(fromDate).startOf('day'),
            toDate: moment(toDate).endOf('day')
        } : getMoments(period)
        
        try {
            let requests = []
            if (product === 'all') {
                for (let product of Object.keys(productsAvailable)) {
                    if (productsAvailable[product]) {
                        let productRequests = await api.getReturnRequestsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
                        console.log(product + ': ', productRequests.length)
                        requests = requests.concat(productRequests)
                    }
                }
            }
            else {
                requests = await api.getReturnRequestsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
            }
            requests.sort((a, b) => {
                return b.createdAt - a.createdAt
            })
            console.log('requests size: ', requests.length)
            let showRequests = []
            if (company !== 'all') {
                showRequests = requests.filter((request) => request.companyId === company && request.status === requestStatus)
            }
            else {
                showRequests = requests.filter((request) => request.status === requestStatus)
            }
            console.log('show requests: ', showRequests)

            setRequests(requests)
            setShowRequests(showRequests)
            setFromDate(dates.fromDate)
            setToDate(dates.toDate)
            setCompany('all')
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updateCompany(company) {
        console.log(company)
        setCompany(company)
        let newRequests = []
        if (company !== 'all') {
            newRequests = requests.filter((request) => request.companyId === company && request.status === requestStatus)
        }
        else {
            newRequests = requests.filter((request) => request.status === requestStatus)
        }
        setShowRequests(newRequests)
    }

    function updateStatus(requestStatus) {
        console.log(requestStatus)
        setRequestStatus(requestStatus)
        let newRequests = []
        if (company !== 'all') {
            newRequests = requests.filter((request) => request.status === requestStatus && request.companyId === company)
        }
        else {
            newRequests = requests.filter((request) => request.status === requestStatus)
        }
        setShowRequests(newRequests)
    }

    async function acceptRequestMultiple(requests) {
        console.log('accept requests multiple')
        console.log(requests)
        try {
            let updated = await api.acceptReturnRequestMultiple(requests)
            console.log('requests: ', updated)

            NotificationManager.success('Requests accepted')
            await getReturnRequests()
        } catch(e) {
            onError(e)
        }
    }

    async function rejectRequestMultiple(requests) {
        console.log('reject requests multiple')
        console.log(requests)
        try {
            let updated = await api.rejectReturnRequestMultiple(requests)
            console.log('requests: ', updated)

            NotificationManager.success('Requests rejected')
            await getReturnRequests()
        } catch(e) {
            onError(e)
        }
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid
                                container
                                component={Box}
                                alignItems='center'
                                justifyContent='flex-start'
                                style={{marginBottom: '2rem'}}
                                direction='row'
                            >
                                <Grid item xs={12} md={2}>
                                    <PeriodSelect
                                        period={period}
                                        updatePeriod={(period, fromDate, toDate) => updatePeriod(period, fromDate, toDate)}
                                        fromDate={fromDate}
                                        toDate={toDate}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '0'}}>
                                        <FormControl variant='outlined'>
                                            <Select
                                                multiple={false}
                                                defaultValue={null}
                                                //IconComponent={KeyboardArrowDown}
                                                value={product}
                                                onChange={(e) => updateProduct(e.target.value)}
                                            >
                                                <MenuItem value={'all'}>Financial (All)</MenuItem>
                                                {Object.keys(productsAvailable).map((key) => {
                                                    if (productsAvailable[key]) {
                                                        return (<MenuItem value={key}>{getProductString(key)}</MenuItem>)
                                                    }
                                                })}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                    <Box
                                        fontSize='12px'
                                        color={theme.palette.gray[600]}
                                        //marginBottom='0.2rem'
                                        //marginLeft='1rem'
                                        marginTop='0.5rem'
                                        textAlign='left'
                                        //className='online-discount'
                                    >
                                        <Box component='small' fontSize='100%'>
                                            Product
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '0'}}>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                //IconComponent={KeyboardArrowDown}
                                                value={company}
                                                onChange={(e) => updateCompany(e.target.value)}
                                            >
                                                <MenuItem value={'all'}>All</MenuItem>
                                                {Object.keys(companyOptions).map((option) => {
                                                    return (<MenuItem value={option}>{companyOptions[option]}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                    <Box
                                        fontSize='12px'
                                        color={theme.palette.gray[600]}
                                        //marginBottom='0.2rem'
                                        //marginLeft='1rem'
                                        marginTop='0.5rem'
                                        textAlign='left'
                                        //className='online-discount'
                                    >
                                        <Box component='small' fontSize='100%'>
                                            Company
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '0'}}>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                //IconComponent={KeyboardArrowDown}
                                                value={requestStatus}
                                                onChange={(e) => updateStatus(e.target.value)}
                                            >
                                                <MenuItem value={'pending'}>Pending</MenuItem>
                                                <MenuItem value={'accepted'}>Accepted</MenuItem>
                                                <MenuItem value={'rejected'}>Rejected</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                    <Box
                                        fontSize='12px'
                                        color={theme.palette.gray[600]}
                                        //marginBottom='0.2rem'
                                        //marginLeft='1rem'
                                        marginTop='0.5rem'
                                        textAlign='left'
                                        //className='online-discount'
                                    >
                                        <Box component='small' fontSize='100%'>
                                            Status
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} style={{marginBottom: '2rem'}}>
                            <ReturnStats 
                                requests={requests}
                            />
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <ReturnStatsTable
                                requests={requests}
                                fromDate={fromDate}
                                toDate={toDate}
                                productsAvailable={productsAvailable}
                                product={product}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ReturnRequestsTable
                                requests={showRequests}
                                updateRequest={() => getReturnRequests()}
                                deleteRequest={() => getReturnRequests()}
                                acceptRequestMultiple={(requests) => acceptRequestMultiple(requests)}
                                rejectRequestMultiple={(requests) => rejectRequestMultiple(requests)}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                                requestStatus={requestStatus}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default Replacements