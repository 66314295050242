import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Loader from 'components/general/Loader'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import LeadStats from 'components/broker/LeadStats'
import BrokerStatsTable from 'components/broker/BrokerStatsTable'
import PolicyStats from 'components/broker/PolicyStats'
import { defaultProducts } from 'config/constants/Constants'
import { getProductString, getMoments } from 'config/helpers/Helpers'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Dashboard() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
	const companyId = companyDetails.companyId
    const productsAvailable = companyDetails.productsAvailable || defaultProducts
    let initialProduct = 'income-protection'
    if (productsAvailable['income-protection'] === false) {
        for (let key of Object.keys(productsAvailable)) {
            if (productsAvailable[key] === true) {
                console.log(key)
                initialProduct = key
            }
        }
    }
    console.log('products available: ', productsAvailable)
    console.log('initial product: ', initialProduct)

    const [details, setDetails] = useState(null)
    const [brokers, setBrokers] = useState([])
    const [brokerStats, setBrokerStats] = useState(null)
    const [leadTotals, setLeadTotals] = useState(null)
    const [policyData, setPolicyData] = useState(null)
    const [unassigned, setUnassigned] = useState(null)
    const [period, setPeriod] = useState('thisMonth')
    const [product, setProduct] = useState(initialProduct)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getDashboardStats()
    }, [])

    async function getDashboardStats() {
        try {
            const details = await api.getDetails(companyId)
            console.log('details: ', details)

            let dates = getMoments(period)

            const data = await api.getDashboardStats(companyId, product, dates.fromDate.valueOf(), dates.toDate.valueOf())
            console.log('data: ', data)

            const brokers = data.brokers

            setPolicyData(data.policyData)
            setUnassigned(data.unassigned)
            setDetails(details)
            updateTotals('thisMonth', brokers, data.unassigned)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function updatePeriod(period) {
        setLoading(true)
        setPeriod(period)

        let dates = getMoments(period)

        try {
            const data = await api.getDashboardStats(companyId, product, dates.fromDate.valueOf(), dates.toDate.valueOf())
            console.log('data: ', data)

            setPolicyData(data.policyData)
            setUnassigned(data.unassigned)
            updateTotals(period, data.brokers, data.unassigned)
            setLoading(false)
        } catch(e) {
            console.log(e)
            onError(e)
        }
    }

    async function updateProduct(product) {
        setLoading(true)
        setProduct(product)

        let dates = getMoments(period)

        try {
            const data = await api.getDashboardStats(companyId, product, dates.fromDate.valueOf(), dates.toDate.valueOf())
            console.log('data: ', data)

            setPolicyData(data.policyData)
            setUnassigned(data.unassigned)
            updateTotals(period, data.brokers, data.unassigned)
            setLoading(false)
        } catch(e) {
            console.log(e)
            onError(e)
        }
    }

    function updateTotals(period, brokers, unassigned) {
        let time = getTime(period)

        let totals = {
            leads: 0,
            meetings: 0,
            pending: 0,
            won: 0,
            premiums: 0
        }

        let brokerStats = []
        for (let broker of brokers) {
            totals.leads += broker[time].leadsAssigned
            totals.meetings += broker[time].meetings
            totals.pending += broker[time].pending
            totals.won += broker[time].closedSales
            totals.premiums += broker[time].policiesSigned
            brokerStats.push({
                brokerId: broker.brokerId,
                firstName: broker.firstName,
                lastName: broker.lastName,
                leadsAssigned: broker[time].leadsAssigned,
                meetings: broker[time].meetings,
                pending: broker[time].pending,
                closedSales: broker[time].closedSales,
                premiums: broker[time].policiesSigned
            })
        }
        console.log('totals: ', totals)
        console.log('broker stats: ', brokerStats)
        
        totals.leads += unassigned[period].length

        brokerStats.sort((a, b) => {
            return b.premiums - a.premiums
        })
        
        setBrokerStats(brokerStats)
        setLeadTotals(totals)
        setBrokers(brokers)
    }

    function getTime(period) {
        if (period === 'thisWeek') {
            return 'weekStats'
        }
        if (period === 'lastWeek') {
            return 'lastWeekStats'
        }
        if (period === 'thisMonth') {
            return 'monthStats'
        }
        if (period === 'lastMonth') {
            return 'lastMonthStats'
        }
        if (period === 'lastSixMonths') {
            return 'lastSixMonthsStats'
        }
    }

    function getTitle(period) {
        if (period === 'thisWeek') {
            return 'This Week'
        }
        if (period === 'lastWeek') {
            return 'Last Week'
        }
        if (period === 'thisMonth') {
            return 'This Month'
        }
        if (period === 'lastMonth') {
            return 'Last Month'
        }
        if (period === 'lastSixMonths') {
            return 'Last 6 Months'
        }
    }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    component={Box}
                                    alignItems='center'
                                    justifyContent='flex-start'
                                    style={{marginBottom: '2rem'}}
                                    direction='row'
                                >
                                    <Grid item xs={12} md={2}>
                                        <FormGroup style={{marginBottom: '1rem'}}>
                                            <FormControl variant='outlined'>
                                                <Select
                                                    multiple={false}
                                                    defaultValue={null}
                                                    IconComponent={KeyboardArrowDown}
                                                    value={period}
                                                    onChange={(e) => updatePeriod(e.target.value)}
                                                >
                                                    <MenuItem value={'thisWeek'}>This Week</MenuItem>
                                                    <MenuItem value={'lastWeek'}>Last Week</MenuItem>
                                                    <MenuItem value={'thisMonth'}>This Month</MenuItem>
                                                    <MenuItem value={'lastMonth'}>Last Month</MenuItem>
                                                    <MenuItem value={'lastSixMonths'}>Last 6 Months</MenuItem>
                                                    <MenuItem value={'custom'} disabled>Custom Period</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <FormGroup style={{marginBottom: '1rem'}}>
                                            <FormControl variant='outlined'>
                                                <Select
                                                    multiple={false}
                                                    defaultValue={null}
                                                    IconComponent={KeyboardArrowDown}
                                                    value={product}
                                                    onChange={(e) => updateProduct(e.target.value)}
                                                >
                                                    {Object.keys(productsAvailable).map((product) => {
                                                        if (productsAvailable[product]) {
                                                            return (<MenuItem value={product}>{getProductString(product)}</MenuItem>)
                                                        }
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <LeadStats 
                                    totals={leadTotals}
                                    product={product}
                                />
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <BrokerStatsTable
                                    brokers={brokerStats}
                                    type={period}
                                    time={getTime(period)}
                                    title={getTitle(period)}
                                    details={details}
                                    product={product}
                                />
                            </Grid>
                        </Grid>
                        {period === 'lastSixMonths' &&
                            <Grid container style={{marginTop: '2rem'}}>
                                <Grid item xs={12}>
                                    <PolicyStats 
                                        data={policyData}
                                        product={product}
                                    />
                                </Grid>
                            </Grid>
                        }
                    </>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default Dashboard