import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter'
import LeadModal from 'components/admin/LeadModal'
import AllocateLeadModal from 'components/admin/AllocateLeadModal'
import { getProductString, getTooltipContent, getMoment } from 'config/helpers/Helpers'
import { NotificationManager } from 'react-notifications'
import componentStyles from 'assets/theme/views/admin/react-bs-table.js'
import Tooltip from '@mui/material/Tooltip'
import moment from 'moment-timezone'

const useStyles = makeStyles(componentStyles)

function LeadsTable(props) {
    const bstable: any = React.useRef()
    const classes = { ...useStyles() }
    const api = new AdminAPI()

    const [lead, setLead] = useState(null)
    const [showLeadModal, setShowLeadModal] = useState(false)
    const [showAllocateLeadModal, setShowAllocateLeadModal] = useState(false)
    const [selected, setSelected] = useState([])
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(false)

    function selectLead(lead) {
        let newSelected = [...selected]
        newSelected.push(lead)
        setSelected(newSelected)
    }

    function deselectLead(lead) {
        let newSelected = [...selected]
        let index = selected.findIndex(item => (item.phone === lead.phone && item.product === lead.product))
        newSelected.splice(index, 1)
        setSelected(newSelected)
    }

    function deselectAll() {
        bstable.current.selectionContext.selected = []
        //console.log(bstable)
        // bulk allocate lead - call api
        setSelected([])
        setAlert(null)
    }

    const selectRow = {
        mode: 'checkbox',
        //clickToSelect: true,
        //selected: [this.state.jobOrderValue],
        //clickToExpand: true,
        selectColumnStyle: { textAlign: 'center', margin: 'auto', width: '50px' },
        onSelect: (row, isSelect, selected, e) => {
            //this.handleOnSelect(row, isSelect, selected, e);
            // console.log('selected')
            // console.log(row)
            // console.log(isSelect)
            // console.log(selected)
            if (isSelect) {
                selectLead(row)
            }
            else {
                deselectLead(row)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect) {
                setSelected(rows)
            }
            else {
                setSelected([])
            }
        }
    }

    function getType(lead) {
        if (lead.product === 'pensions') {
            return lead.quote.type
        }
        else if (lead.product === 'income-protection') {
            return lead.quote.riskLevel
        }
        else {
            return '-'
        }
    }

    const columns = [
        {
            dataField: 'firstName',
            text: 'Name',
            //sort: true,
            filter: textFilter(),
            formatter: (cell, row) => {
                return (
                    <Tooltip title={getTooltipContent(row)} placement='right' arrow>
                        <Box className='hover-text primary-text' onClick={() => openLeadModal(row)}>
                            {row.firstName + ' ' + (row.lastName ? row.lastName : '')}
                        </Box>
                    </Tooltip>
                )
            },
            headerFormatter: (col, colIndex, components) => {
                return components.filterElement
            }
        },
        {
            dataField: 'phone',
            text: 'Phone',
            sort: true,
            formatter: (cell, row) => {
                return (<Box>{row.phone}</Box>)
            },
        },
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
            formatter: (cell, row) => {
                return (<Box>{row.email}</Box>)
            },
        },
        {
            dataField: 'isValid',
            text: 'Passed Validation',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.isValid ? 'Yes' : 'No (' + row.invalidReason + ')'}</>
            }
        },
        {
            dataField: 'source',
            text: 'Source',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.source}</>
            }
        },
        {
            dataField: 'createdAt',
            text: props.showStatus === 'Allocated' ? 'Allocated' : 'Created',
            sort: true,
            formatter: (cell, row) => {
                return <>{moment(row.createdAt).format('DD/MM HH:mm')}</>
                //return <>{moment().diff(row.createdAt, 'days')} days ago</>
                //return <>{moment(row.createdAt).fromNow()}</>
            }
        }
    ]

    if (props.showStatus === 'Allocated') {
        columns.splice(columns.length - 1, 0, {
            dataField: 'createdAtOriginal',
            text: 'Created',
            sort: true,
            formatter: (cell, row) => {
                return <>{moment(row.createdAtOriginal).format('DD/MM HH:mm')}</>
                //return <>{moment().diff(row.createdAt, 'days')} days ago</>
                //return <>{moment(row.createdAt).fromNow()}</>
            }
        })
        columns.splice(2, 0, {
            dataField: 'company',
            text: 'Company',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.companyId ? row.companyId.toUpperCase() : '-'}</>
            }
        })
    }

    if (props.product === 'all') {
        columns.splice(1, 0, {
            dataField: 'product',
            text: 'Product',
            sort: true,
            formatter: (cell, row) => {
                return (<Box>{getProductString(row.product)}</Box>)
            },
        })
    }

    if (props.product === 'pensions' || props.product === 'all') {
        columns.splice(2, 0, {
            dataField: 'type',
            text: 'Type',
            sort: true,
            formatter: (cell, row) => {
                return (<Box>{getType(row)}</Box>)
            }
        })
        columns.splice(3, 0, {
            dataField: 'currentValue',
            text: 'Pension Value',
            sort: true,
            formatter: (cell, row) => {
                return (<Box>{row.quote.currentValue || '-'}</Box>)
            }
        })
    } 

    if (props.product === 'income-protection') {
        columns.splice(2, 0, {
            dataField: 'risk',
            text: 'Risk',
            sort: true,
            formatter: (cell, row) => {
                return (<Box>{row.quote.riskLevel || '-'}</Box>)
            }
        })
        columns.splice(3, 0, {
            dataField: 'dob',
            text: 'DOB',
            sort: true,
            formatter: (cell, row) => {
                return (<Box>{row.dob || '-'}</Box>)
            }
        })
        columns.splice(4, 0, {
            dataField: 'salary',
            text: 'Salary',
            sort: true,
            formatter: (cell, row) => {
                return (<Box>{'€' + row.salary || '-'}</Box>)
            }
        })
        columns.splice(5, 0, {
            dataField: 'healthIssue',
            text: 'Health Issue',
            sort: true,
            formatter: (cell, row) => {
                return (<Box>{row.majorHealthIssue || '-'}</Box>)
            }
        })
    } 

    if (props.product === 'life-insurance') {
        columns.splice(2, 0, {
            dataField: 'dob',
            text: 'DOB',
            sort: true,
            formatter: (cell, row) => {
                return (<Box>{row.dob || '-'}</Box>)
            }
        })
        columns.splice(3, 0, {
            dataField: 'healthIssue',
            text: 'Health Issue',
            sort: true,
            formatter: (cell, row) => {
                return (<Box>{row.majorHealthIssue || '-'}</Box>)
            }
        })
    } 

    function openAllocateLeadModal() {
        let containsPension = false

        for (let lead of selected) {
            if (lead.product === 'pensions') {
                containsPension = true
            }
        }

        if (containsPension) {
            NotificationManager.error('Can not bulk allocate pension leads. Please review these individually.')
        }
        else {
            setShowAllocateLeadModal(true)
        }
    }

    function openLeadModal(lead) {
        setLead(lead)
        setShowLeadModal(true)
    }

    function closeLeadModal() {
        setShowLeadModal(false)
        setLead(null)
    }

    return (
        <Card>
            {alert}
            {!loading ? (
                <>
                    <CardHeader
                        subheader={
                            <>
                                <Box display='flex' style={{justifyContent: 'space-between'}}>
                                    <Box>
                                        <Box
                                            component={Typography}
                                            variant='h2'
                                            marginBottom='0!important'
                                        >
                                            <Box component='span'>{props.title} Leads ({props.leads.length})</Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                        }
                        classes={{ root: classes.cardHeaderRoot }}
                    ></CardHeader>

                    <div className='table-container'>
                        <BootstrapTable 
                            ref={bstable}
                            keyField='leadId' 
                            data={ props.leads }
                            columns={ columns } 
                            filter={ filterFactory() } 
                            selectRow={ selectRow }
                            noDataIndication={<Box>No pending leads</Box>}
                        />
                    </div>

                    <Grid container>
                        {selected.length > 0 &&
                            <>
                                <Grid item xs={3} style={{margin: '0.5rem'}}>
                                    <Box>
                                        <Button
                                            component={Box}
                                            onClick={() => openAllocateLeadModal()}
                                            color='primary'
                                            variant='contained'
                                            size='medium'
                                        >
                                            Allocate {selected.length} Leads
                                        </Button>
                                    </Box>
                                </Grid>
                            </>
                        }
                    </Grid>
                    
                    {showAllocateLeadModal &&
                        <AllocateLeadModal 
                            isOpen={showAllocateLeadModal}
                            close={() => setShowAllocateLeadModal(false)}
                            leads={selected}
                            //refreshLeads={() => props.refreshLeads()}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            companies={props.companies}
                            updateLead={(lead) => props.updateLead(lead)}
                            refreshLeads={() => props.refreshLeads()}
                            deselectAll={() => deselectAll()}
                        />
                    }
                    {showLeadModal &&
                        <LeadModal 
                            isOpen={showLeadModal}
                            close={() => closeLeadModal()}
                            lead={lead}
                            refreshLeads={() => props.refreshLeads()}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            companies={props.companies}
                            updateLead={(lead) => props.updateLead(lead)}
                            admin={props.admin}
                        />
                    }
                </>
            ) : (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                            <Loader />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Card>
    );
}

export default LeadsTable