import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Clear from '@material-ui/icons/Clear'
import Select from '@mui/material/Select'
import MenuItem from '@material-ui/core/MenuItem'
import AdminAPI from 'config/api/AdminAPI'
import Loader from 'components/general/Loader'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'

//import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import componentStylesForms from 'assets/theme/components/forms.js'
import componentStylesButtons from 'assets/theme/components/button.js'

//const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)
const useStylesForms = makeStyles(componentStylesForms)
const useStylesButtons = makeStyles(componentStylesButtons)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function AllocateLeadModal(props) {
	const classes = { ...useStylesDialog(), ...useStylesForms(), ...useStylesButtons() }
  	//const theme = useTheme()
  	const api = new AdminAPI()

    const [company, setCompany] = useState(null)
    const [brokers, setBrokers] = useState([])
    const [broker, setBroker] = useState('unassigned')
    const [brokersLoading, setBrokersLoading] = useState(false)
    const [allocating, setAllocating] = useState(false)
    const [progress, setProgress] = useState(0)
    const [errored, setErrored] = useState([])
    
    async function allocateLeads() {
        console.log('company: ', company)
        console.log('broker: ', broker)
        console.log('leads: ', props.leads)
        let selectedBroker = broker === 'unassigned' ? null : broker
        try {
            setAllocating(true)
            let updatedLeads = []
            let i = 1
            for (let lead of props.leads) {
                try {
                    let newLead = await api.allocateLead(company, selectedBroker, lead)
                    //let success = true
                    console.log('newLead: ', newLead)
                    updatedLeads.push(newLead)
                    setProgress(i)
                } catch(e) {
                    onError(e)
                    NotificationManager.error('Error allocating lead ' + lead.phone)
                    let phones = [...errored]
                    phones.push(lead.phone)
                    setErrored(phones)
                }
                i++
            }
            console.log('new leads: ', updatedLeads)
            props.showSuccessAlert('Leads allocated to ' + company.toUpperCase())
            props.deselectAll()
            props.refreshLeads()
            props.close()
        } catch(e) {
            onError(e)
            NotificationManager.error('Error allocating leads')
        }
    }

    async function updateCompany(companyId) {
        console.log('update company: ', companyId)
        setCompany(companyId)
        setBrokersLoading(true)
        try {
            let details = await api.getCompanyDetails(companyId)
            let brokers = details.brokerDiary ? await api.getCompanyBrokers(companyId) : []
            console.log('brokers: ', brokers)

            setBrokers(brokers)
            setBrokersLoading(false)
        } catch(e) {
            onError(e)
            NotificationManager.error('Error getting company brokers')
        }
	}

	return (
        <Dialog
            open={props.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => props.close()}
        >
            <div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Allocate {props.leads.length} {props.leads.length > 1 ? 'Leads' : 'Lead'}
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
            {!allocating ? (
            <>
            <DialogContent>
                <FormGroup>
                    <FormLabel>Assign to Company</FormLabel>
                    <FormControl>
                        <Select
                            multiple={false}
                            value={company}
                            onChange={(e) => updateCompany(e.target.value)}
                            input={<OutlinedInput />}
                        >
                            {props.companies.map((company) => {
                                return (
                                    <MenuItem value={company.companyId}>{company.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
                {!brokersLoading ? (
                    <FormGroup>
                        <FormLabel>Assign to Broker</FormLabel>
                        <FormControl variant='outlined' fullWidth>
                            <Select
                                multiple={false}
                                value={broker}
                                onChange={(e) => setBroker(e.target.value)}
                                input={<OutlinedInput />}
                            >
                                <MenuItem value={'unassigned'}>Auto Assign</MenuItem>
                                {brokers.map((broker) => {
                                    return (<MenuItem value={broker.brokerId}>{broker.firstName + ' ' + broker.lastName}</MenuItem>)
                                })}
                            </Select>
                        </FormControl>
                    </FormGroup>
                ) : (
                    <Loader height={12} />
                )}
            </DialogContent>
            <DialogActions>
                <Box width='100%' display='flex' justifyContent='space-between'>
                    <Button onClick={() => props.close()} color='primary'>
                        Close
                    </Button>
                    <Box>
                        <Box display='inline-block' marginRight='0.5rem'>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={() => allocateLeads()}
                                disabled={brokersLoading || !company}
                            >
                                Allocate
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </DialogActions>
            </>
            ) : (
                <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                    <Loader />
                </Box>
            )}
            {allocating &&
                <Box display='flex' justifyContent='center' alignItems='center'>
                    <p>Progress: {progress} / {props.leads.length}</p>
                </Box>
            }
            {errored.length > 0 &&
                <Box display='flex' justifyContent='center' alignItems='center'>
                    <p>Error allocating: </p>
                    <p>{errored.toString()}</p>
                </Box>
            }
        </Dialog>
	)
}