import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import filterFactory from 'react-bootstrap-table2-filter'
import { getProductString } from 'config/helpers/Helpers'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'

import componentStyles from 'assets/theme/views/admin/react-bs-table.js'

const useStyles = makeStyles(componentStyles)

function CompaniesTable(props) {
    const classes = { ...useStyles() }
    const theme = useTheme()
    const api = new AdminAPI()
    
    const leads = props.leads
    const fsCompanies = props.fsCompanies
    const solarCompanies = props.solarCompanies
    const fsDeliveries = props.fsDeliveries
    const balanceDeliveries = props.balanceDeliveries
    const sfDeliveries = props.sfDeliveries
    const eeDeliveries = props.eeDeliveries
    const returns = props.returns

    const [product, setProduct] = useState('Financial')
    const [company, setCompany] = useState(null)
    const [companyData, setCompanyData] = useState([])
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            dataField: 'company',
            text: 'Company',
            formatter: (cell, row) => {
                return (<Box className='hover-text primary-text'>{row.name} ({row.companyId.toUpperCase()})</Box>)
            },
            footer: ''
        },
        {
            dataField: 'product',
            text: 'Product',
            formatter: (cell, row) => {
                return <>{getProductString(row.product)}</>
            },
            footer: ''
        },
        {
            dataField: 'leadPrice',
            text: 'Lead Price (Avg)',
            //sort: true,
            formatter: (cell, row) => {
                return <>{'€' + row.leadPrice.toFixed(2)}</>
            },
            footer: '€' + (companyData.reduce((total, product) => total + (product.leadPrice || 0), 0) / companyData.length).toFixed(2)
        },
        {
            dataField: 'sold',
            text: 'Sold',
            //sort: true,
            formatter: (cell, row) => {
                return <>{row.volume}</>
            },
            footer: companyData.reduce((total, product) => total + (product.volume || 0), 0).toString()
        },
        {
            dataField: 'acceptedReturns',
            text: 'Returns',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.acceptedReturns} from {row.requestedReturns}</>
            },
            footer: companyData.reduce((total, product) => total + (product.acceptedReturns || 0), 0).toString() + ' from ' + companyData.reduce((total, product) => total + (product.requestedReturns || 0), 0).toString()
        },
        {
            dataField: 'revenue',
            text: 'Revenue',
            //sort: true,
            formatter: (cell, row) => {
                return <>{'€' + row.revenue.toFixed(2)}</>
            },
            footer: '€' + companyData.reduce((total, product) => total + (product.revenue || 0), 0).toFixed(2)
        },
        {
            dataField: 'leadPriceReturns',
            text: 'Lead Price (with Returns)',
            sort: true,
            formatter: (cell, row) => {
                return <>{'€' + (row.revenue / (row.volume + row.acceptedReturns)).toFixed(2)}</>
            },
            footer: '€' + (companyData.reduce((total, product) => total + (product.revenue / (product.volume + product.acceptedReturns) || 0), 0) / companyData.length).toFixed(2)
        }
    ]

    async function updateProduct(product) {
        setProduct(product)
    }

    async function updateCompany(companyId) {
        try {
            setCompany(companyId)
            setLoading(true)
    
            // Filter the leads by companyId
            const companyLeads = leads.filter(lead => lead.companyId === companyId)
            console.log('company leads: ', companyLeads.length)
    
            // Create a map to count the occurrences of each product
            const productCountMap = companyLeads.reduce((acc, lead) => {
                acc[lead.product] = (acc[lead.product] || 0) + 1
                return acc
            }, {})
            console.log('product count map: ', productCountMap)
    
            // Map over the products and fetch additional data (leadPrice, return requests)
            const companyData = await Promise.all(Object.keys(productCountMap).map(async (companyProduct) => {
                // Determine the type of company and product
                const companies = product === 'Financial' ? fsCompanies : solarCompanies
                const company = companies.find(company => company.companyId === companyId)
    
                // Select the correct deliveries array
                const deliveries = product === 'Financial' ? fsDeliveries : sfDeliveries
                let delivery = null
                if (companyProduct === 'spPensions') {
                    delivery = deliveries.find(delivery => delivery.companyId === companyId && delivery.product === 'pensions')
                }
                else {
                    delivery = deliveries.find(delivery => delivery.companyId === companyId && delivery.product === companyProduct)
                }
    
                // Fallback logic if no delivery is found
                if (!delivery && product === 'Financial') {
                    delivery = balanceDeliveries.find(delivery => delivery.companyId === companyId && delivery.product === companyProduct)
                }
                if (!delivery && product === 'Solar') {
                    delivery = eeDeliveries.find(delivery => delivery.companyId === companyId && delivery.product === companyProduct)
                }
    
                let leadPrice = 0
    
                // Handle custom pricing logic for pensions
                if (delivery && delivery.product === 'pensions' && delivery.pricing) {
                    const productLeads = companyLeads.filter(lead => lead.product === companyProduct)
                    let total = 0
                    for (let lead of productLeads) {
                        total += parseFloat(delivery.pricing[lead.quote.currentValue]) || 0
                    }
                    leadPrice = total / productLeads.length
                } else if (delivery) {
                    leadPrice = delivery.leadPrice || 0 // Default to 0 if no price available
                }
    
                // Initialize filtered return requests
                let filteredReturnRequests = []
                let acceptedReturnRequests = []
                let totalReturns = 0
    
                // Fetch and filter return requests if the product is 'Financial'
                if (product === 'Financial') {
                    const productRequests = returns.filter((request) => request.product === companyProduct)
                    //const productRequests = await api.getReturnRequestsPeriod(companyProduct, props.fromDate.valueOf(), props.toDate.valueOf())
                    console.log('product return requests: ', productRequests.length)
    
                    // Filter return requests by companyId
                    filteredReturnRequests = productRequests.filter(req => req.companyId === companyId)
                    console.log('company return requests: ', filteredReturnRequests.length)

                    // for each accepted return => find delivery, calculate price, reduce from revenue
                    acceptedReturnRequests = filteredReturnRequests.filter(req => req.status === 'accepted')
                    console.log('accepted return requests: ', acceptedReturnRequests.length)

                    for (let request of acceptedReturnRequests) {
                        let lead = companyLeads.find(lead => (lead.product === request.product && lead.phone === request.phone))
                        if (lead) {
                            if (delivery.product === 'pensions' && delivery.pricing) {
                                totalReturns += parseFloat(delivery.pricing[lead.quote.currentValue])
                            }
                            else {
                                totalReturns += leadPrice
                            }
                        }
                        else {
                            console.log('lead not found for request - may be in previous period')
                            console.log(request.product)
                            console.log(request.phone)
                        }
                    }
                    console.log('total returns: ', totalReturns)
                }
    
                // Return the final data for each product
                return {
                    id: company.name + companyProduct,
                    name: company.name,
                    companyId: companyId,
                    product: companyProduct,
                    volume: productCountMap[companyProduct],
                    leadPrice: leadPrice,
                    requestedReturns: filteredReturnRequests.length, 
                    acceptedReturns: filteredReturnRequests.filter(req => req.status === 'accepted').length,
                    revenue: (parseFloat(productCountMap[companyProduct]) * parseFloat(leadPrice)) - parseFloat(totalReturns)
                }
            }))
            console.log('company data: ', companyData)
    
            // Update state with the resulting company data
            setCompanyData(companyData)
        } catch (e) {
            onError(e);
        } finally {
            setLoading(false)
        }
    }

    return (
        <Card>
            <>
                <CardHeader
                    subheader={
                        <>
                            <Box display='flex' style={{justifyContent: 'space-between'}}>
                                <Box>
                                    <Box
                                        component={Typography}
                                        variant='h2'
                                        marginBottom='0!important'
                                    >
                                        <Box component='span'>Company Overview</Box>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    }
                    classes={{ root: classes.cardHeaderRoot }}
                ></CardHeader>


                <CardContent>
                    <Grid 
                        container
                        component={Box}
                        alignItems='center'
                        justifyContent='flex-start'
                        style={{marginBottom: '2rem'}}
                        direction='row'
                    >
                        <Grid item xs={12} md={6}>
                            <FormGroup style={{marginBottom: '0'}}>
                                <FormControl variant='outlined'>
                                    <Select
                                        multiple={false}
                                        defaultValue={null}
                                        //IconComponent={KeyboardArrowDown}
                                        value={product}
                                        onChange={(e) => updateProduct(e.target.value)}
                                    >
                                        <MenuItem value={'Financial'}>Financial (All)</MenuItem>
                                        <MenuItem value={'Solar'}>Solar (All)</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>
                            <Box
                                fontSize='12px'
                                color={theme.palette.gray[600]}
                                //marginBottom='0.2rem'
                                //marginLeft='1rem'
                                marginTop='0.5rem'
                                textAlign='left'
                                //className='online-discount'
                            >
                                <Box component='small' fontSize='100%'>
                                    Product
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormGroup style={{marginBottom: '0'}}>
                                <FormControl variant='outlined' fullWidth>
                                    <Select
                                        multiple={false}
                                        value={company}
                                        onChange={(e) => updateCompany(e.target.value)}
                                    >
                                        {(product === 'Solar' ? solarCompanies : fsCompanies).map((company) => {
                                            return (
                                                <MenuItem value={company.companyId}>{company.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </FormGroup>
                            <Box
                                fontSize='12px'
                                color={theme.palette.gray[600]}
                                //marginBottom='0.2rem'
                                //marginLeft='1rem'
                                marginTop='0.5rem'
                                textAlign='left'
                                //className='online-discount'
                            >
                                <Box component='small' fontSize='100%'>
                                    Company
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
                
                {!loading ? (
                    <div className='table-container'>
                        {companyData &&
                            <BootstrapTable 
                                keyField='id' 
                                data={ companyData } 
                                columns={ columns } 
                                filter={ filterFactory() } 
                                noDataIndication={<Box>Select a company to view data</Box>}
                            />
                        }
                    </div>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '10vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </>
        </Card>
    );
}

export default CompaniesTable