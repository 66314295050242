import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import InfoIcon from '@material-ui/icons/NotificationsActive'
import { reminderOptions } from 'config/constants/Constants'
import { getLeadActivityTimeline } from 'config/helpers/Helpers'
import { leadPipelineStages } from 'config/constants/Constants'
import { getProductString } from 'config/helpers/Helpers'
import Voicemail from 'components/broker/Voicemail'
import moment from 'moment-timezone'

function Activity(props) {

    const lead = props.lead
    const tasks = props.tasks
    //const tasks = props.tasks.sort((a, b) => {return Date.parse(a.date) - Date.parse(b.date)})
    const activity = getLeadActivityTimeline(lead, tasks)
    console.log('activity: ', activity)

    function getCategoryString(category) {
        let cat = 'Note'
        if (category === 'Callback') {
            cat = 'Callback Enquiry'
        }
        if (category === 'email') {
            cat = 'Email Enquiry'
        }
        if (category === 'Voicemail') {
            cat = 'Voicemail Enquiry'
        }
        return cat
    }
    
    return (
        <Grid container className='activity-container'>
            <Grid item xs={12} lg={12}>
                {activity.length > 0 ? (
                    <>
                        {activity.map((item, i) => {
                            return (
                                <Box style={{marginBottom: '1rem', borderBottom: '1px solid #eee'}} key={i}>
                                    {item.category === 'note' &&
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Grid container justifyContent='space-between'>
                                                    <Grid item>
                                                        <p style={{fontWeight: '700'}}>{getCategoryString(item.note.category)}</p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {item.note.category === 'Voicemail' ? (
                                                    <Voicemail 
                                                        vmKey={item.note.note}
                                                    />
                                                ) : (
                                                    <p>{item.note.note}</p>
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container justifyContent='space-between'>
                                                    <Grid item>
                                                    {(!item.note.system && !props.admin) &&
                                                        <p style={{textAlign: 'left', fontSize: '0.75rem'}} className='hover-text' onClick={() => props.editNote(item.note)}>Edit</p>
                                                    }
                                                    </Grid>
                                                    <Grid item>
                                                        <p style={{textAlign: 'right', fontSize: '0.75rem'}}>{moment(item.note.createdAt).format('Do MMMM YYYY HH:mm')}</p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    {item.category === 'call' &&
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p style={{fontWeight: '700'}}>Call {item.call.outcome === 'answer' ? 'Answered' : 'Not Answered'}</p>
                                            </Grid>
                                            {item.call.note && item.call.note.length > 0 &&
                                                <Grid item xs={12}>
                                                    <p>{item.call.note}</p>
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                <p style={{textAlign: 'right', fontSize: '0.75rem'}}>{moment(item.call.date).format('Do MMMM YYYY HH:mm')}</p>
                                            </Grid>
                                        </Grid>
                                    }
                                    {item.category === 'task' &&
                                        <>
                                            {moment(item.task.date).valueOf() > Date.now() ? (
                                                <Card>
                                                    <CardContent>
                                                        <Grid container className={moment(item.task.date).valueOf() > Date.now() ? 'task-container upcoming' : 'task-container past'}>
                                                            <Grid item xs={12}>
                                                                <p style={{fontWeight: '700', color: '#1a73e8'}}>{moment(item.task.date).valueOf() > Date.now() ? 'Upcoming Task' : 'Task'}</p>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <p style={{fontWeight: '700'}}>{item.task.title}</p>
                                                            </Grid>
                                                            {item.task.note && item.task.note.length > 0 &&
                                                                <Grid item xs={12}>
                                                                    <p>{item.task.note}</p>
                                                                </Grid>
                                                            }
                                                            <Grid item xs={12}>
                                                                {moment(item.task.date).valueOf() > Date.now() &&
                                                                    <Box style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                                                        <InfoIcon style={{marginBottom: '1rem', marginRight: '0.5rem'}} />
                                                                        <p style={{textAlign: 'right', fontSize: '0.75rem', marginRight: '0.2rem'}}>{reminderOptions[item.task.reminder]}</p>
                                                                        <p style={{textAlign: 'right', fontSize: '0.75rem'}}>{moment(item.task.date).format('Do MMMM YYYY HH:mm')}</p>
                                                                    </Box>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            ) : (
                                                <Grid container className={moment(item.task.date).valueOf() > Date.now() ? 'task-container upcoming' : 'task-container past'}>
                                                    <Grid item xs={12}>
                                                        <p style={{fontWeight: '700'}}>{moment(item.task.date).valueOf() > Date.now() ? 'Upcoming Task' : 'Task'}</p>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <p style={{fontWeight: '700'}}>{item.task.title}</p>
                                                    </Grid>
                                                    {item.task.note && item.task.note.length > 0 &&
                                                        <Grid item xs={12}>
                                                            <p>{item.task.note}</p>
                                                        </Grid>
                                                    }
                                                    <Grid item xs={12}>
                                                        <p style={{textAlign: 'right', fontSize: '0.75rem'}}>{moment(item.task.date).format('Do MMMM YYYY HH:mm')}</p>
                                                        {moment(item.task.date).valueOf() > Date.now() &&
                                                            <Box style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                                                <InfoIcon style={{marginBottom: '1rem', marginRight: '0.5rem'}} />
                                                                <p style={{textAlign: 'right', fontSize: '0.75rem'}}>{reminderOptions[item.task.reminder]}</p>
                                                            </Box>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </>
                                    }
                                    {item.category === 'stage-updated' &&
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p style={{fontWeight: '700'}}>Stage updated to {leadPipelineStages[item.label]}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p style={{textAlign: 'right', fontSize: '0.75rem'}}>{moment(item.timestamp).format('Do MMMM YYYY HH:mm')}</p>
                                            </Grid>
                                        </Grid>
                                    }
                                    {item.category === 'broker-updated' &&
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p style={{fontWeight: '700'}}>Broker updated to {item.label}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p style={{textAlign: 'right', fontSize: '0.75rem'}}>{moment(item.timestamp).format('Do MMMM YYYY HH:mm')}</p>
                                            </Grid>
                                        </Grid>
                                    }
                                    {item.category === 'product-updated' &&
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p style={{fontWeight: '700'}}>Product updated to {getProductString(item.label)}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p style={{textAlign: 'right', fontSize: '0.75rem'}}>{moment(item.timestamp).format('Do MMMM YYYY HH:mm')}</p>
                                            </Grid>
                                        </Grid>
                                    }
                                    {item.category === 'document' &&
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p style={{fontWeight: '700'}}>Document</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p>{item.label}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p style={{textAlign: 'right', fontSize: '0.75rem'}}>{moment(item.timestamp).format('Do MMMM YYYY HH:mm')}</p>
                                            </Grid>
                                        </Grid>
                                    }
                                    {item.category === 'core' &&
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p style={{fontWeight: '700'}}>{item.label}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p style={{textAlign: 'right', fontSize: '0.75rem'}}>{moment(item.timestamp).format('Do MMMM YYYY HH:mm')}</p>
                                            </Grid>
                                        </Grid>
                                    }
                                </Box>
                            )
                        })}
                    </>
                ) : (
                    <Box style={{marginBottom: '1rem'}}>
                        <Grid container>
                            <Grid item xs={12}>
                                <p>No activity</p>
                            </Grid>
                        </Grid>
                    </Box>	
                )}
            </Grid>
        </Grid>
    )
}

export default Activity