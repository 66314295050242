import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter'
import AddCompanyModal from './AddCompanyModal'
import CompanyModal from './CompanyModal'

import componentStyles from 'assets/theme/views/admin/react-bs-table.js'

const useStyles = makeStyles(componentStyles)

function CompaniesTable(props) {
    const classes = { ...useStyles() }

    const companies = props.companies
    const [company, setCompany] = useState(null)
    const [showCompanyModal, setShowCompanyModal] = useState(false)
    const [showAddCompanyModal, setShowAddCompanyModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            dataField: 'companyName',
            text: 'Name',
            //sort: true,
            //filter: textFilter(),
            formatter: (cell, row) => {
                return (<Box className='hover-text primary-text' onClick={() => openCompanyModal(row)}>{row.name} ({row.companyId.toUpperCase()})</Box>)
                
            }
        },
        {
            dataField: 'billingAccount',
            text: 'Billing Account',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.billingAccount ? row.billingAccount : '-'}</>
            }
        },
        // {
        //     dataField: 'billingPeriod',
        //     text: 'Billing Period',
        //     sort: true,
        //     //filter: numberFilter(),
        //     formatter: (cell, row) => {
        //         return <>{row.billingPeriod ? row.billingPeriod : '-'}</>
        //     }
        // },
        // {
        //     dataField: 'billingMethod',
        //     text: 'Billing Method',
        //     sort: true,
        //     //filter: numberFilter(),
        //     formatter: (cell, row) => {
        //         return <>{row.billingMethod ? row.billingMethod : '-'}</>
        //     }
        // },
    ]

    function openCompanyModal(company) {
        setCompany(company)
        setShowCompanyModal(true)
    }

    function closeCompanyModal() {
        setShowCompanyModal(false)
        setCompany(null)
    }

    return (
        <Card>
            {!loading ? (
                <>
                    <CardHeader
                        subheader={
                            <>
                                <Box display='flex' style={{justifyContent: 'space-between'}}>
                                    <Box>
                                        <Box
                                            component={Typography}
                                            variant='h2'
                                            marginBottom='0!important'
                                        >
                                            <Box component='span'>Solar Companies</Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Button
                                            component={Box}
                                            onClick={() => setShowAddCompanyModal(true)}
                                            color='primary'
                                            variant='contained'
                                            size='small'
                                        >
                                            Add Company
                                        </Button>
                                    </Box>
                                </Box>
                            </>
                        }
                        classes={{ root: classes.cardHeaderRoot }}
                    ></CardHeader>
                    
                    <div className='table-container'>
                        <BootstrapTable 
                            keyField='leadName' 
                            data={ companies } 
                            columns={ columns } 
                            filter={ filterFactory() } 
                            noDataIndication={<Box>No companies</Box>}
                        />
                    </div>

                    {showAddCompanyModal &&
                        <AddCompanyModal 
                            isOpen={showAddCompanyModal}
                            close={() => setShowAddCompanyModal(false)}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            refreshCompanies={() => props.refreshCompanies()}
                        />
                    }

                    {showCompanyModal &&
                        <CompanyModal 
                            isOpen={showCompanyModal}
                            close={() => closeCompanyModal()}
                            company={company}
                            updateCompany={(company) => props.updateCompany(company)}
                            //showSuccessAlert={(text) => props.showSuccessAlert(text)}
                        />
                    }
                </>
            ) : (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                            <Loader />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Card>
    );
}

export default CompaniesTable