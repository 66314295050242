import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import componentStyles from 'assets/theme/views/admin/react-bs-table.js'
import moment from 'moment-timezone'

const useStyles = makeStyles(componentStyles)

function ThisWeekTable(props) {
    const classes = { ...useStyles() }

    const columns = [
        {
            dataField: 'company',
            text: 'Company',
            formatter: (cell, row) => {
                return (<Box className='hover-text primary-text'>{row.name} ({row.companyId.toUpperCase()})</Box>)
                
            }
        },
        {
            dataField: 'allocation',
            text: 'Set Allocation',
            //sort: true,
            formatter: (cell, row) => {
                return <>{row.allocation}</>
            }
        },
        {
            dataField: 'extraAllocation',
            text: 'Extra Allocation',
            //sort: true,
            formatter: (cell, row) => {
                return <>{row.extraAllocation}</>
            }
        },
        {
            dataField: 'delivered',
            text: 'Delivered',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.delivered || 0}</>
            }
        },
        {
            dataField: 'remaining',
            text: 'Remaining',
            sort: true,
            formatter: (cell, row) => {
                return <>{(parseInt(row.allocation) + parseInt(row.extraAllocation)) - parseInt(row.delivered)}</>
            }
        }
    ]

    return (
        <Card>
            <>
                <CardHeader
                    subheader={
                        <>
                            <Box display='flex' style={{justifyContent: 'space-between'}}>
                                <Box>
                                    <Box
                                        component={Typography}
                                        variant='h2'
                                        marginBottom='0!important'
                                    >
                                        <Box component='span'>Allocation Summary (Week Ending {moment(props.toDate).format('MMMM Do')})</Box>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    }
                    classes={{ root: classes.cardHeaderRoot }}
                ></CardHeader>

                <div className='table-container'>
                    <BootstrapTable 
                        keyField='name' 
                        data={ props.companies } 
                        columns={ columns } 
                        filter={ filterFactory() } 
                        noDataIndication={<Box>No companies</Box>}
                    />
                </div>
            </>
        </Card>
    );
}

export default ThisWeekTable