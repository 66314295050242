import React, { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import moment from 'moment-timezone'
// import { Dayjs } from 'dayjs'
// import { LocalizationProvider } from '@mui/x-date-pickers-pro'
// import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
// import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker'

function PeriodSelect(props) {
    const theme = useTheme()
    const [showCustom, setShowCustom] = useState(props.period === 'custom' ? true : false)
    const [fromDate, setFromDate] = useState(props.fromDate)
    const [toDate, setToDate] = useState(props.toDate)

    // useEffect(() => {
    //     updatePhone(phone)
    // }, [])

    function updatePeriod(period) {
        if (period === 'custom') {
            setShowCustom(true)
        }
        else {
            props.updatePeriod(period)
            setShowCustom(false)
        }
    }

    function updateDates() {
        props.updatePeriod('custom', fromDate, toDate)
    }

    return (
        <>
            <FormGroup style={{marginBottom: '0.5rem'}}>
                <FormControl variant='outlined'>
                    <Select
                        multiple={false}
                        defaultValue={null}
                        //IconComponent={KeyboardArrowDown}
                        value={props.period}
                        onChange={(e) => updatePeriod(e.target.value)}
                        disabled={props.disabled}
                    >
                        <MenuItem value={'yesterday'}>Yesterday</MenuItem>
                        <MenuItem value={'today'}>Today</MenuItem>
                        <MenuItem value={'thisWeek'}>This Week</MenuItem>
                        <MenuItem value={'lastWeek'}>Last Week</MenuItem>
                        <MenuItem value={'thisMonth'}>This Month</MenuItem>
                        <MenuItem value={'lastMonth'}>Last Month</MenuItem>
                        <MenuItem value={'yearToDate'}>Year to Date</MenuItem>
                        <MenuItem value={'custom'}>Custom Period</MenuItem>
                    </Select>
                </FormControl>
            </FormGroup>
            {showCustom && 
                <FormGroup style={{marginBottom: '0'}}>
                    <FormLabel>From Date</FormLabel>
                    <OutlinedInput
                        fullWidth
                        type='date'
                        value={moment(fromDate).format('YYYY-MM-DD')}
                        defaultValue={moment(fromDate).format('YYYY-MM-DD')}
                        onChange={(e) => setFromDate(e.target.value)}
                        style={{marginBottom: '0.5rem'}}
                    />
                    <FormLabel>To Date</FormLabel>
                    <OutlinedInput
                        fullWidth
                        type='date'
                        value={moment(toDate).format('YYYY-MM-DD')}
                        defaultValue={moment(toDate).format('YYYY-MM-DD')}
                        onChange={(e) => setToDate(e.target.value)}
                        style={{marginBottom: '0.5rem'}}
                    />
                    <Button
                        component={Box}
                        onClick={() => updateDates()}
                        color='primary'
                        variant='contained'
                        size='small'
                        disabled={!(fromDate && toDate)}
                    >
                        {(fromDate && toDate) ? (
                            <>Load {moment(fromDate).format('DD/MM')} - {moment(toDate).format('DD/MM')}</>
                        ) : (
                            <>Select dates</>
                        )}
                    </Button>
                </FormGroup>
            }
            <Box
                fontSize='12px'
                color={theme.palette.gray[600]}
                //marginBottom='0.2rem'
                //marginLeft='1rem'
                marginTop='0.5rem'
                textAlign='left'
                //className='online-discount'
            >
                <Box component='small' fontSize='100%'>
                    Showing from {moment(props.fromDate).format('DD/MM')} - {moment(props.toDate).format('DD/MM')}
                </Box>
            </Box>
        </>
    )
}

export default PeriodSelect