import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import EnergyAPI from 'config/api/EnergyAPI'
import { onError } from 'config/lib/errorLib'
import { getMoments, getProductString } from 'config/helpers/Helpers'
import DashboardPeriodSelect from 'components/admin/Dashboard/DashboardPeriodSelect'
import BusinessTotals from 'components/admin/BusinessTotals'
import ProductsTable from 'components/admin/ProductsTable'
import CompaniesTable from 'components/admin/Dashboard/CompaniesTable'
import CashflowSummary from 'components/admin/Dashboard/CashflowSummary'
import moment from 'moment-timezone'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

const fsProducts = ['pensions', 'income-protection', 'life-insurance', 'health-insurance']
const solarProducts = ['Solar', 'EV Charger', 'Insulation']
const startPensions = ['al']

function Dashboard() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new AdminAPI()
    const eApi = new EnergyAPI()

    //const [period, setPeriod] = useState('lastWeek')
    const [totals, setTotals] = useState(null)
    const [products, setProducts] = useState([])
    const [fsCompanies, setFsCompanies] = useState([])
    const [solarCompanies, setSolarCompanies] = useState([])
    const [soldLeads, setSoldLeads] = useState([])
    const [returns, setReturns] = useState([])
    const [fsDeliveries, setFsDeliveries] = useState([])
    const [sfDeliveries, setSfDeliveries] = useState([])
    const [balanceDeliveries, setBalanceDeliveries] = useState([])
    const [eeDeliveries, setEeDeliveries] = useState([])
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [profitabilityWeeks, setProfitabilityWeeks] = useState([])
    const [payments, setPayments] = useState([])
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        let dates = getMoments('lastWeek')
        getData(dates.fromDate, dates.toDate)
    }, [])

    async function getData(fromDate, toDate) {
        fromDate = moment(fromDate)
        toDate = moment(toDate)
        setLoading(true)
        let totals = {
            revenue: 0,
            adSpend: 0,
            otherCosts: 0
        }
        try {
            // fetch in parallel rather than one at a time
            const fsLeads = await Promise.all(fsProducts.map(product => api.getAllLeadsPeriod(product, fromDate.valueOf(), toDate.valueOf())))
            const spLeads = await api.getVerifiedSpApplicationsPeriod('al', fromDate.valueOf(), toDate.valueOf())
            console.log('sp verified: ', spLeads.length)
            const eeLeads = await Promise.all(solarProducts.map(product => eApi.getEeLeadsPeriod(product, fromDate.valueOf(), toDate.valueOf())))
            const sfLeads = await eApi.getSfLeadsPeriod('Solar', fromDate.valueOf(), toDate.valueOf())
            const leads = [...fsLeads.flat(), ...eeLeads.flat(), ...sfLeads.flat(), ...spLeads.flat()]
            console.log('leads size: ', leads.length)

            // anything that is not sold or rejected is unallocated
            const soldLeads = leads.filter(lead => lead.companyId)
            console.log('sold leads size: ', soldLeads.length)
            const rejectedLeads = leads.filter(lead => !lead.companyId && !lead.isValid)
            console.log('rejected leads size: ', rejectedLeads.length)

            const returnKeys = soldLeads.map(lead => {
                return {
                    "product": lead.product,
                    "phone": lead.phone
                }
            })
            console.log('keys: ', returnKeys)

            // get returns for all FS products
            const allReturns = await api.getReturnRequestsBatch(returnKeys)
            //const allReturns = [...returns.flat()]   
            console.log('returns size: ', allReturns.length)

            // get all deliveries - in order to get prices to get revenue
            const fsDeliveries = await api.getDeliveries()
            const balanceDeliveries = await api.getBalanceDeliveries()
            const sfDeliveries = await eApi.getSfDeliveries()
            const eeDeliveries = await eApi.getDeliveries()

            const fsCompanies = await api.getCompanies()
            const solarCompanies = await eApi.getCompanies()

            // create array of product objects for products table
            let products = []

            for (let lead of soldLeads) {
                let delivery = null
                if (lead.product === 'pensions' && startPensions.indexOf(lead.companyId) > -1) {
                    lead.product = 'spPensions'
                }
                let productIndex = products.findIndex((item) => item.product === lead.product)
                if (productIndex > -1) {
                    products[productIndex].volume += 1
                }
                else {
                    products.push({
                        product: lead.product,
                        volume: 1,
                        revenue: 0,
                        adSpend: 0,
                        otherCosts: 0,
                        rejected: 0,
                        requestedReturns: 0,
                        acceptedReturns: 0
                    })
                }

                if (solarProducts.indexOf(lead.product) > -1) {
                    if (lead.source === 'energyefficiency.ie') {
                        let index = eeDeliveries.findIndex((delivery) => (delivery.companyId === lead.companyId && delivery.product === lead.product))
                        if (index > -1) {
                            delivery = eeDeliveries[index]
                        }
                    }
                    else if (lead.source === 'solarfinder.ie' || lead.source === 'quotes.solarfinder.ie') {
                        let index = sfDeliveries.findIndex((delivery) => (delivery.companyId === lead.companyId && delivery.product === lead.product))
                        if (index > -1) {
                            delivery = sfDeliveries[index]
                        }
                    }
                }
                else if (fsProducts.indexOf(lead.product) > -1) {
                    let index = fsDeliveries.findIndex((delivery) => (delivery.companyId === lead.companyId && delivery.product === lead.product))
                    if (index > -1) {
                        delivery = fsDeliveries[index]
                    }
                    else { // check balance deliveries
                        index = balanceDeliveries.findIndex((delivery) => (delivery.companyId === lead.companyId && delivery.product === lead.product))
                        if (index > -1) {
                            delivery = balanceDeliveries[index]
                        }
                    }
                }
                else if (lead.product === 'spPensions') {
                    let index = fsDeliveries.findIndex((delivery) => (delivery.companyId === lead.companyId && delivery.product === 'pensions'))
                    if (index > -1) {
                        delivery = fsDeliveries[index]
                    }
                }
                //console.log('delivery: ', delivery)
                if (delivery) {
                    // do not count towards revenue if accepted return
                    let price = delivery.pricing ? (parseFloat(delivery.pricing[lead.quote.currentValue]) || 0) : (parseFloat(delivery.leadPrice) || 0)
                    let productIndex = products.findIndex((item) => item.product === lead.product)
                    let returnRequest = allReturns.find(request => (request.product === lead.product && request.phone === lead.phone))
                    if (returnRequest && returnRequest.status === 'accepted') {
                        console.log('accepted request: ', lead.phone)
                    }
                    else {
                        totals.revenue += price
                        products[productIndex].revenue += price
                    }
                }
            }

            for (let lead of rejectedLeads) {
                let productIndex = products.findIndex((item) => item.product === lead.product)
                if (productIndex > -1) {
                    products[productIndex].rejected += 1
                }
            }

            // add returns to products
            products = products.map((item) => {
                let requestedReturns = allReturns.filter((request) => request.product === item.product)
                return {
                    ...item,
                    requestedReturns: requestedReturns.length,
                    acceptedReturns: requestedReturns.filter((request) => request.status === 'accepted').length
                }
            })
            
            // get profitability weeks in current period
            const fsWeeks = await api.getProfitabilityWeeks()
            const solarWeeks = await eApi.getProfitabilityWeeks()
            let periodWeeks = []
            for (let week of fsWeeks) {
                if (moment(week.date).isBetween(fromDate, toDate)) {
                    periodWeeks.push(week)
                    totals.adSpend += parseFloat(week.cost)
                }
            }
            for (let week of solarWeeks) {
                if (moment(week.date).isBetween(fromDate, toDate)) {
                    periodWeeks.push(week)
                    totals.adSpend += parseFloat(week.cost)
                }
            }
            for (let week of periodWeeks) {
                for (let product of Object.keys(week.details)) {
                    let productTotal = 0
                    //console.log(product)
                    let productIndex = products.findIndex((item) => item.product === product)
                    //console.log('week details: ', Object.values(week.details[product]))
                    for (let platform of Object.keys(week.details[product])) {
                        if (platform !== 'revenue' && platform !== 'volume') {
                            productTotal += parseFloat(week.details[product][platform]) || 0
                        }
                    }
                    //productTotal = Object.values(week.details[product]).filter((platform) => platform !== 'revenue').reduce((acc, value) => acc + value, 0);
                    //console.log('product total: ', productTotal)
                    if (productIndex > -1) {
                        products[productIndex].adSpend += parseFloat(productTotal) || 0
                    }
                }
            }
            console.log('totals: ', totals)
            console.log('products: ', products)
            //console.log('companies: ', companies)

            // get payments for current period
            const fsPayments = await api.getPayments()
            const solarPayments = await eApi.getPayments()
            let periodPayments = []
            for (var payment of fsPayments) {
                if (moment(payment.date).isBetween(fromDate, toDate)) {
                    periodPayments.push(payment)
                }
            }
            for (var payment of solarPayments) {
                if (moment(payment.date).isBetween(fromDate, toDate)) {
                    periodPayments.push(payment)
                }
            }
            console.log('periodPayments: ', periodPayments)

            const filteredFsCompanies = fsCompanies.filter((company) => soldLeads.find((lead) => lead.companyId === company.companyId))
            console.log('filtered fs companies: ', filteredFsCompanies)
            filteredFsCompanies.sort((a, b) => a.name.localeCompare(b.name))

            const filteredSolarCompanies = solarCompanies.filter((company) => soldLeads.find((lead) => lead.companyId === company.companyId))
            console.log('filtered solar companies: ', filteredSolarCompanies)
            filteredSolarCompanies.sort((a, b) => a.name.localeCompare(b.name))

            setSoldLeads(soldLeads)
            setReturns(allReturns)
            setFsDeliveries(fsDeliveries)
            setBalanceDeliveries(balanceDeliveries)
            setSfDeliveries(sfDeliveries)
            setEeDeliveries(eeDeliveries)
            setFromDate(fromDate)
            setToDate(toDate)
            setTotals(totals)
            setProducts(products)
            setFsCompanies(filteredFsCompanies)
            setSolarCompanies(filteredSolarCompanies)
            setProfitabilityWeeks(periodWeeks)
            setPayments(periodPayments)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid
                                container
                                component={Box}
                                alignItems='center'
                                justifyContent='flex-start'
                                style={{marginBottom: '2rem'}}
                                direction='row'
                            >
                                <Grid item xs={12}>
                                    <DashboardPeriodSelect
                                        updatePeriod={(fromDate, toDate) => getData(fromDate, toDate)}
                                        fromDate={fromDate}
                                        toDate={toDate}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <BusinessTotals 
                                totals={totals}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <ProductsTable 
                                products={products}
                                fsProducts={fsProducts}
                                solarProducts={solarProducts}
                                fromDate={fromDate}
                                toDate={toDate}
                                returns={returns}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <CompaniesTable 
                                leads={soldLeads}
                                returns={returns}
                                fsCompanies={fsCompanies}
                                solarCompanies={solarCompanies}
                                fsDeliveries={fsDeliveries}
                                balanceDeliveries={balanceDeliveries}
                                sfDeliveries={sfDeliveries}
                                eeDeliveries={eeDeliveries}
                                fromDate={fromDate}
                                toDate={toDate}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CashflowSummary 
                                payments={payments}
                                profitabilityWeeks={profitabilityWeeks}
                                fromDate={fromDate}
                                toDate={toDate}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default Dashboard