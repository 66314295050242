import { Component } from 'react'
import { API } from 'aws-amplify'
//import { v4 as uuidv4 } from 'uuid'
import config from './Config'

const apiPath = config.apiGatewayEnergy.PATH

class EnergyClient extends Component {
	getCompanies() {
		return API.post(apiPath, 'companies/list', {})
	}

	addCompany(companyId, name, active, billingAccount, billingPeriod, billingMethod, billingEmail, vatRate, counties) {
        const data = {
			companyId,
			name,
			active,
			billingAccount,
			billingPeriod,
			billingMethod,
            billingEmail,
			vatRate,
			counties
		}
		console.log(data)

        return API.post(apiPath, 'companies/add', {
            body: data
        })
    }

    updateCompany(companyId, active, billingAccount, billingPeriod, billingMethod, billingEmail, vatRate, counties) {
        const data = {
			companyId,
			active,
			billingAccount,
			billingPeriod,
			billingMethod,
			billingEmail,
			vatRate,
			counties
		}
		console.log(data)

        return API.post(apiPath, 'companies/update', {
            body: data
        })
    }

	getCompanyDetails(companyId) {
		const data = {
			companyId
		}
		console.log(data)

		return API.post(apiPath, 'companies/get', {
			body: data
		})
	}

	getDeliveries() {
		return API.post(apiPath, 'deliveries/list', {})
	}
	
	getSfDeliveries() {
		return API.post(apiPath, 'deliveries/list-sf', {})
	}

	addDelivery(companyId, companyName, product, leadPrice, webhook, weight, active) {
		const data = {
			companyId,
			companyName,
			product,
			leadPrice,
			webhook,
			weight,
			active
		}
		console.log(data)

        return API.post(apiPath, 'deliveries/add', {
            body: data
        })
	}

	addSfDelivery(companyId, companyName, product, leadPrice, webhook, weight, weeklyCap, active) {
		const data = {
			companyId,
			companyName,
			product,
			leadPrice,
			webhook,
			weight,
			weeklyCap,
			active
		}
		console.log(data)

        return API.post(apiPath, 'deliveries/add-sf', {
            body: data
        })
	}

	updateDelivery(companyId, product, leadPrice, webhook, weight, active) {
		const data = {
			companyId,
			product,
			leadPrice,
			webhook,
			weight,
			active
		}
		console.log(data)

        return API.post(apiPath, 'deliveries/update', {
            body: data
        })
	}

	updateSfDelivery(companyId, product, leadPrice, webhook, weight, weeklyCap, active) {
		const data = {
			companyId,
			product,
			leadPrice,
			webhook,
			weight,
			weeklyCap,
			active
		}
		console.log(data)

        return API.post(apiPath, 'deliveries/update-sf', {
            body: data
        })
	}

	updateEeDeliveries(deliveries) {
		const data = {
			deliveries
		}
		console.log(data)

        return API.post(apiPath, 'deliveries/update-ee-deliveries', {
            body: data
        })
	}

	updateSfDeliveries(deliveries) {
		const data = {
			deliveries
		}
		console.log(data)

        return API.post(apiPath, 'deliveries/update-sf-deliveries', {
            body: data
        })
	}

	getLeads() {
		return API.post(apiPath, 'leads/list-all', {})
	}

	getEeLeadsPeriod(product, fromDate, toDate) {
		const data = {
			product,
			fromDate,
			toDate
		}
		console.log(data)
		return API.post(apiPath, 'leads/list-ee-period', {
			body: data
		})
	}

	getSfLeads() {
		return API.post(apiPath, 'leads/list-sf', {})
	}

	getSfLeadsPeriod(product, fromDate, toDate) {
		const data = {
			product,
			fromDate,
			toDate
		}
		console.log(data)
		return API.post(apiPath, 'leads/list-sf-period', {
			body: data
		})
	}

	allocateLead(companyId, lead, site) {
		const data = {
			companyId,
			lead,
			site
		}
		console.log(data)

        return API.post(apiPath, 'leads/allocate', {
            body: data
        })
	}

	rejectLead(site, date, leadId) {
		const data = {
			site,
			date,
			leadId
		}
		console.log(data)

        return API.post(apiPath, 'leads/reject', {
            body: data
        })
	}

	updateLeadDetails(date, leadId, details, site) {
		const data = {
			date,
			leadId,
			details,
			site
		}
		console.log(data)

		return API.post(apiPath, 'leads/update-details', {
			body: data
		})
	}

	getPayments() {
		return API.post(apiPath, 'payments/list', {})
	}

	addPayment(companyId, companyName, date, billingMethod, billingAccount, billingPeriod, vatRate, allocation, subtotal, total, invoiced, paid, notes) {
		const data = {
			companyId,
			companyName,
			date,
			billingMethod,
			billingAccount,
			billingPeriod,
			vatRate,
			allocation, 
			subtotal, 
			total,
			invoiced,
			paid,
			notes
		}
		console.log(data)

        return API.post(apiPath, 'payments/add', {
            body: data
        })
	}

	updatePayment(companyId, date, billingAccount, vatRate, allocation, subtotal, total, invoiced, paid, notes, reference) {
		const data = {
			companyId,
			date,
			billingAccount,
			vatRate,
			allocation, 
			subtotal, 
			total,
			invoiced,
			paid,
			notes,
			reference
		}
		console.log(data)

        return API.post(apiPath, 'payments/update', {
            body: data
        })
	}

	deletePayment(companyId, date) {
		const data = {
			companyId,
			date
		}
		console.log(data)

        return API.post(apiPath, 'payments/delete', {
            body: data
        })
	}

	getProfitabilityWeeks() {
		return API.post(apiPath, 'profitability/list', {})
	}

	addProfitabilityWeek(country, date, details, leads, revenue, cost, notes) {
		const data = {
			country,
			date,
			details,
			leads,
			revenue,
			cost, 
			notes
		}
		console.log('data: ', data)

        return API.post(apiPath, 'profitability/add', {
            body: data
        })
	}

	updateProfitabilityWeek(country, date, details, leads, revenue, cost, notes) {
		const data = {
			country,
			date,
			details,
			leads,
			revenue,
			cost, 
			notes
		}
		console.log(data)

        return API.post(apiPath, 'profitability/update', {
            body: data
        })
	}

	deleteProfitabilityWeek(country, date) {
		const data = {
			country,
			date
		}
		console.log(data)

        return API.post(apiPath, 'profitability/delete', {
            body: data
        })
	}
}

export default EnergyClient