import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import SolarDetails from './SolarDetails'
import EVChargerDetails from './EVChargerDetails'
import InsulationDetails from './InsulationDetails'
import PersonalDetails from './PersonalDetails'
import Notes from 'components/admin/Notes'
import EnergyAPI from 'config/api/EnergyAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import Loader from 'components/general/Loader'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import componentStylesButton from 'assets/theme/components/button.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)
const useStylesButton = makeStyles(componentStylesButton)

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='down' ref={ref} {...props} />
})

export default function LeadModal(props) {
	const classes = {...useStyles(), ...useStylesDialog(), ...useStylesButton()}
	const api = new EnergyAPI()
    const companies = props.companies

	const [lead, setLead] = useState(props.lead)
	const [showQuoteContent, setShowQuoteContent] = useState(false)
    const [company, setCompany] = useState(null)
    const [loading, setLoading] = useState(false)
	const [showAlert, setShowAlert] = useState(false)
    const [showRejectLead, setShowRejectLead] = useState(false)

    const site = props.site ? props.site : (lead.source === 'solarfinder.ie' || lead.source === 'quotes.solarfinder.ie' ? 'sf' : 'ee')
    console.log('lead site: ', site)

    async function allocateLead() {
        console.log('update company: ', company)
        try {
            let newLead = await api.allocateLead(company, lead, site)
            //let success = true
            console.log('newLead: ', newLead)

            props.showSuccessAlert('Lead Allocated to ' + company.toUpperCase())
            props.updateLead(newLead)
            props.close()
        } catch(e) {
            onError(e)
            NotificationManager.error('Error allocating lead')
        }
    }

    async function rejectLead() {
        try {
            let newLead = await api.rejectLead(site, lead.date, lead.leadId)
            //let success = true
            console.log('newLead: ', newLead)

            props.showSuccessAlert('Lead Rejected')
            props.updateLead(newLead)
            props.close()
        } catch(e) {
            onError(e)
            NotificationManager.error('Error rejecting lead')
        }
    }

	async function updateCompany(companyId) {
        console.log('update company: ', companyId)
        setCompany(companyId)
        setLoading(true)
        setShowAlert(true)
	}

    async function updateLead(details) {
		setLead(details)
		props.updateLead(details)
	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			maxWidth='lg'
			className='lead-modal'
			//className='fixed-height-modal'
			//fullScreen
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					{lead.firstName + ' ' + lead.lastName} (<a href={'tel:' + lead.phone}>{lead.phone}</a> | <a href={'mailto:' + lead.email}>{lead.email}</a>)
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent style={{paddingTop: '0'}}>
				<Grid container alignItems='center' justifyContent='center' style={{marginBottom: '1rem'}}>
                    <Grid item xs={3}>
                        <FormControl variant='outlined' fullWidth direction='row'>
                            <FormLabel>Company</FormLabel>
                            <Select
                                multiple={false}
                                value={lead.companyId}
                                onChange={(e) => updateCompany(e.target.value)}
                                IconComponent={KeyboardArrowDown}
                                disabled={lead.companyId}
                            >
                                {companies.map((company) => {
                                    return (
                                        <MenuItem value={company.companyId}>{company.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
				</Grid>

                <Grid container alignItems='center' justifyContent='center'>
                    <Grid item xs={10}>
                        <Grid container>
                            <Grid item xs={12}>
                                {lead.product === 'Solar' &&
                                    <SolarDetails
                                        lead={lead}
                                        admin
                                    />
                                }
                                {lead.product === 'EV Charger' &&
                                    <EVChargerDetails
                                        lead={lead}
                                        admin
                                    />
                                }
                                {lead.product === 'Insulation' &&
                                    <InsulationDetails
                                        lead={lead}
                                        admin
                                    />
                                }
                            </Grid>
                            <Grid item lg={12}>
                                <PersonalDetails 
                                    site={site}
                                    lead={lead}
                                    admin
                                    updateLead={(details) => updateLead(details)}
                                />
                            </Grid>
                            <Grid item lg={12}>
                                <Notes
                                    lead={lead}
                                    //updateLead={(details) => updateLead(details)}
                                    admin
                                    site={site}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
			</DialogContent>
			
            <DialogActions>
                <Button
                    onClick={() => props.close()}
                    color='primary'
                >
                    Close
                </Button>
                
                {lead.companyId === null && lead.isValid && 
                    <Box marginLeft='auto!important'>
                        <Button
                            component={Box}
                            onClick={() => setShowRejectLead(true)}
                            color='primary'
                            variant='contained'
                        >
                            Reject Lead
                        </Button>
                    </Box>
                }
            </DialogActions>
            
            {showAlert &&
                <ReactBSAlert
                    warning
                    style={{ display: 'block', marginTop: '-100px' }}
                    title='Are you sure?'
                    onConfirm={() => allocateLead()}
                    onCancel={() => setShowAlert(false)}
                    cancelBtnCssClass='btn-secondary'
                    confirmBtnBsStyle='success'
                    cancelBtnText='Cancel'
                    confirmBtnText='Yes, Allocate Lead'
                    //disabled={loading}
                    showCancel
                    btnSize=''
                >
                    This will allocate this lead to '{company.toUpperCase()}' and notify them that they have a new lead
                </ReactBSAlert>
            }

            {showRejectLead &&
                <ReactBSAlert
                    warning
                    style={{ display: 'block', marginTop: '-100px' }}
                    title='Are you sure?'
                    onConfirm={() => rejectLead()}
                    onCancel={() => setShowRejectLead(false)}
                    cancelBtnCssClass='btn-secondary'
                    confirmBtnBsStyle='danger'
                    cancelBtnText='Cancel'
                    confirmBtnText='Yes, Reject Lead'
                    disabled={loading}
                    showCancel
                    btnSize=''
                >
                    This will move the lead to 'Rejected', they can still be allocated later
                </ReactBSAlert>
            }
		</Dialog>
	)
}