import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import componentStyles from 'assets/theme/views/admin/react-bs-table.js'
import AdminAPI from 'config/api/AdminAPI'
import Loader from 'components/general/Loader'
import { onError } from 'config/lib/errorLib'

const useStyles = makeStyles(componentStyles)

function ReturnStatsTable(props, ref) {
    const classes = { ...useStyles() }
    const requests = props.requests
    const api = new AdminAPI()

    const [companies, setCompanies] = useState([])
    const [leadsLoaded, setLeadsLoaded] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let companyCounts = {}

        for (let request of requests) {
            if (companyCounts[request.companyName]) {
                if (request.status === 'accepted') {
                    companyCounts[request.companyName]['requests']++ 
                    companyCounts[request.companyName]['accepted']++ 
                }
                else if (request.status === 'rejected' || request.status === 'pending') {
                    companyCounts[request.companyName]['requests']++ 
                }
            }
            else {
                if (request.status === 'accepted') {
                    companyCounts[request.companyName] = {
                        requests: 1,
                        accepted: 1,
                        companyId: request.companyId
                    }
                }
                else if (request.status === 'rejected' || request.status === 'pending') {
                    companyCounts[request.companyName] = {
                        requests: 1,
                        accepted: 0,
                        companyId: request.companyId
                    }
                }
            }
        }
        console.log(companyCounts)

        let companies = []

        for (let company of Object.keys(companyCounts)) {
            companies.push({
                companyName: company,
                requests: companyCounts[company]['requests'],
                accepted: companyCounts[company]['accepted'],
                companyId: companyCounts[company]['companyId']
            })
        }
        companies.sort((a, b) => {
            return b.requests - a.requests
        })

        console.log('companies: ', companies)
        setCompanies(companies)
    }, [])

    async function loadCompaniesLeads() {
        setLoading(true)
        // get all possible leads for given period - then update company array
        try {
            let leads = []
            if (props.product === 'all') {
                for (let product of Object.keys(props.productsAvailable)) {
                    if (props.productsAvailable[product]) {
                        let productLeads = await api.getAllLeadsPeriod(product, props.fromDate.valueOf(), props.toDate.valueOf())
                        console.log(product + ': ', productLeads.length)
                        leads = leads.concat(productLeads)
                    }
                }
            }
            else {
                leads = await api.getAllLeadsPeriod(props.product, props.fromDate.valueOf(), props.toDate.valueOf())
            }
            console.log('leads: ', leads)
            console.log('total leads for period: ', leads.length)

            let newCompanies = [...companies]

            for (let lead of leads) {
                let index = newCompanies.findIndex(item => item.companyId === lead.companyId)
                if (index > -1) {
                    if (newCompanies[index]['totalLeads']) {
                        newCompanies[index]['totalLeads']++
                    }
                    else {
                        newCompanies[index]['totalLeads'] = 1
                    }
                }
            }
            console.log('new companies: ', newCompanies)

            setCompanies(newCompanies)
            setLoading(false)
            setLeadsLoaded(true)
        } catch(e) {
            onError(e)
        }
    }

    return (
        <Card>
            <CardHeader
                subheader={
                    <>
                        <Box display='flex' style={{justifyContent: 'space-between'}}>
                            <Box>
                                <Box
                                    component={Typography}
                                    variant='h2'
                                    marginBottom='0!important'
                                >
                                    <Box component='span'>Returns by Company</Box>
                                </Box>
                            </Box>
                        </Box>
                    </>
                }
                classes={{ root: classes.cardHeaderRoot }}
            ></CardHeader>
            
            {!loading ? (
            <div className='table-container'>
                <BootstrapTable 
                    keyField='companyId' 
                    data={ companies } 
                    columns={ 
                        [
                            {
                                dataField: 'companyName',
                                text: 'Name',
                                //sort: true,
                                //filter: textFilter(),
                                formatter: (cell, row) => {
                                    return (<Box>{row.companyName}</Box>)
                                }
                            },
                            {
                                dataField: 'totalLeads',
                                text: 'Leads',
                                //sort: true,
                                //filter: textFilter(),
                                formatter: (cell, row) => {
                                    return (<Box>{row.totalLeads || '...'}</Box>)
                                }
                            },
                            {
                                dataField: 'requests',
                                text: 'Requests',
                                sort: true,
                                //filter: numberFilter(),
                                formatter: (cell, row) => {
                                    return <>{row.requests}</>
                                }
                            },
                            {
                                dataField: 'requestRate',
                                text: 'Request Rate',
                                sort: true,
                                //filter: numberFilter(),
                                formatter: (cell, row) => {
                                    return <>{row.totalLeads ? ((row.requests / row.totalLeads) * 100).toFixed(2) + '%' : '...'}</>
                                }
                            },
                            {
                                dataField: 'accepted',
                                text: 'Accepted',
                                sort: true,
                                //filter: numberFilter(),
                                formatter: (cell, row) => {
                                    return <>{row.accepted}</>
                                }
                            },
                            {
                                dataField: 'acceptRate',
                                text: 'Accept Rate',
                                sort: true,
                                formatter: (cell, row) => {
                                    return <>{((row.accepted / row.requests) * 100).toFixed(2)}%</>
                                },
                            }
                        ]
                    } 
                    filter={ filterFactory() } 
                    noDataIndication={<Box>No return data</Box>}
                />
            </div>
            ) : (
                <Grid container>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '200px'}}>
                            <Loader />
                        </Box>
                    </Grid>
                </Grid>
            )}
            
            {(!loading && !leadsLoaded) &&
                <Box style={{marginLeft: '1rem'}}>
                    <p onClick={() => loadCompaniesLeads()} className='hover-text primary-text'>Load Lead Data for Companies</p>
                </Box>
            }
        </Card>
    );
}

export default ReturnStatsTable