import React from 'react';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close'
import PeopleIcon from '@material-ui/icons/People'
import CheckIcon from '@material-ui/icons/Check'
import { ciPrices } from 'config/constants/Constants'

import CardStats from 'components/argon/Cards/Dashboard/CardStats.js'

const SolarTotals = ( props ) => {

    const leads = props.leads
    const deliveries = props.deliveries

    let revenue = 0

    for (let delivery of deliveries) {
        //console.log('delivery: ', delivery)
        //console.log('revenue: ', parseFloat(delivery.revenue))
        if (delivery.revenue) {
            revenue += parseFloat(delivery.revenue)
        }
    }
    
    return (
        <Grid container direction='row' spacing={1} style={{marginBottom: '2rem'}}>
            <Grid item xs={12} md={4}>
                <CardStats
                    subtitle='Leads (All Valid)'
                    title={leads.filter((lead) => lead.companyId || lead.isValid).length}
                    icon={PeopleIcon}
                    color='bgPrimary'
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <CardStats
                    subtitle='Revenue'
                    title={'€' + revenue}
                    icon={CheckIcon}
                    color='bgSuccess'
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <CardStats
                    subtitle='Rejected'
                    title={leads.filter((lead) => lead.companyId === null && !lead.isValid).length}
                    icon={CloseIcon}
                    color='bgError'
                />
            </Grid>
        </Grid>
    )
}

export default SolarTotals