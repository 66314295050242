import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PhoneInput from 'components/general/PhoneInput'
import CountySelect from 'components/general/CountySelect'
import ProductSelect from 'components/general/ProductSelect'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import { defaultProducts } from 'config/constants/Constants'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  	PaperProps: {
    	style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
    	},
  	},
};

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function AddBrokerModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()
  	const api = new BrokerAPI()
	const companyDetails = useContext(DetailsContext) 
	const companyId = props.admin ? props.company.companyId : companyDetails.companyId
	const productsAvailable = props.admin ? (props.company.productsAvailable || defaultProducts) : (companyDetails.productsAvailable || defaultProducts)
	//console.log(productsAvailable)

  	const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
  	const [email, setEmail] = useState('')
  	const [phone, setPhone] = useState('')
	const [counties, setCounties] = useState(['All'])
	const [products, setProducts] = useState(['All'])
  	const [permissions, setPermissions] = useState('broker')
  	//const [password, setPassword] = useState('')

  	async function addBroker() {
  		try {
			let formattedEmail = email.toLowerCase()
			let formattedPhone = phone.replaceAll(' ', '')
            let broker = await api.addBroker(companyId, firstName, lastName, formattedEmail, formattedPhone, counties, permissions, products)
            console.log('broker: ', broker)

  			props.refreshBrokers()
  			props.showSuccessAlert('Broker added')
  			props.close()
  		} catch(e) {
  			onError(e)
  		}
  	}

  	function checkFields() {
  		if (firstName.length > 0 && lastName.length > 0 && email.length > 0 && phone.length > 0 && permissions) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Add Broker
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent>
				<FormGroup>
                  	<FormLabel>First Name</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                    	placeholder='John'
                    	onChange={(e) => setFirstName(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Last Name</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                    	placeholder='Smith'
                    	onChange={(e) => setLastName(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Work Email</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='email'
                    	placeholder='john@example.com'
                    	onChange={(e) => setEmail(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Phone</FormLabel>
                  	<PhoneInput 
						phone={phone}
						updatePhone={(phone) => setPhone(phone)}  
					/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Permissions</FormLabel>
                  	<FormControl variant='outlined' fullWidth>
                    	<Select
                      		multiple={false}
                      		//IconComponent={KeyboardArrowDown}
                      		value={permissions}
                      		onChange={(e) => setPermissions(e.target.value)}
                    	>
                      		<MenuItem value={'broker'}>Broker</MenuItem>
                      		<MenuItem value={'admin'}>Admin</MenuItem>
                    	</Select>
                  	</FormControl>
            	</FormGroup>
				<FormGroup>
					<FormLabel>Counties</FormLabel>
					<FormControl variant='outlined' fullWidth>
						<CountySelect 
							counties={counties}
							updateCounties={(counties) => setCounties(counties)}
						/>
					</FormControl>
				</FormGroup>
				<FormGroup>
					<FormLabel>Products</FormLabel>
					<FormControl variant='outlined'>
						<ProductSelect 
							products={products}
							updateProducts={(products) => setProducts(products)}
							productsAvailable={productsAvailable}
						/>
					</FormControl>
				</FormGroup>
				{/*
            	<FormGroup>
                  	<FormLabel>Set Your Password</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                    	placeholder='csgw@2a44'
                    	onChange={(e) => setPassword(e.target.value)}
                  	/>
            	</FormGroup>
				<Box
                    component='p'
                    marginBottom='1rem'
                    fontWeight='300'
                    lineHeight='1.7'
                    fontSize='1rem'
                >
                    Password must contain at least 6 characters
                </Box>
				*/}
            	<Box
                    component='p'
                    marginBottom='1rem'
                    fontWeight='300'
                    lineHeight='1.7'
                    fontSize='1rem'
                >
                    A confirmation code will be sent to the email above, this is required to confirm the new broker's email when they first login
                </Box>
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Cancel
				</Button>
			
				<Button
					component={Box}
					onClick={() => addBroker()}
					color='primary'
					marginLeft='auto!important'
					variant='contained'
					disabled={checkFields()}
				>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	)
}