import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import componentStyles from 'assets/theme/views/admin/react-bs-table.js'
import { getProductString } from 'config/helpers/Helpers'
import moment from 'moment-timezone'

const useStyles = makeStyles(componentStyles)

function LeadsRemainingTable(props) {
    const classes = { ...useStyles() }

    const columns = [
        {
            dataField: 'company',
            text: 'Company',
            formatter: (cell, row) => {
                return (<Box className='hover-text primary-text'>{row.companyName} ({row.companyId.toUpperCase()})</Box>)
                
            }
        },
        {
            dataField: 'product',
            text: 'Product',
            //sort: true,
            formatter: (cell, row) => {
                return <>{row.product === 'Solar' ? row.product : getProductString(row.product)}</>
            }
        },
        {
            dataField: 'allocation',
            text: 'Allocation',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.product === 'Solar' ? row.weeklyCap : row.allocation}</>
            }
        },
        {
            dataField: 'remaining',
            text: 'Remaining',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.remaining || 0}</>
            }
        }
    ]

    if (props.product === 'Financial') {
        columns.splice(3, 0, {
            dataField: 'extraAllocation',
            text: 'Extra Allocation',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.extraAllocation || 0}</>
            }
        })
        columns.splice(4, 0, {
            dataField: 'delivered',
            text: 'Delivered',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.delivered || 0}</>
            }
        })
    }
    else {
        columns.splice(3, 0, {
            dataField: 'deliveredSf',
            text: 'Delivered SF',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.deliveredSf || 0}</>
            }
        })
        columns.splice(4, 0, {
            dataField: 'deliveredEe',
            text: 'Delivered EE',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.deliveredEe || 0}</>
            }
        })
    }

    return (
        <Card>
            <>
                <CardHeader
                    subheader={
                        <>
                            <Box display='flex' style={{justifyContent: 'space-between'}}>
                                <Box>
                                    <Box
                                        component={Typography}
                                        variant='h2'
                                        marginBottom='0!important'
                                    >
                                        <Box component='span'>Remaining Leads (Week Ending {props.toDate.format('MMMM Do')})</Box>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    }
                    classes={{ root: classes.cardHeaderRoot }}
                ></CardHeader>

                <div className='table-container'>
                    <BootstrapTable 
                        keyField={'name' + 'product'} 
                        data={ props.deliveries } 
                        columns={ columns } 
                        filter={ filterFactory() } 
                        noDataIndication={<Box>No companies</Box>}
                    />
                </div>
            </>
        </Card>
    );
}

export default LeadsRemainingTable