import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import EnergyAPI from 'config/api/EnergyAPI'
import { onError } from 'config/lib/errorLib'
import ValidationList from 'components/admin/ValidationList'
import CompaniesTable from 'components/admin/CompaniesTable'
import SolarCompaniesTable from 'components/admin/Energy/CompaniesTable'

import componentStyles from 'assets/theme/components/cards/cards/card-button.js'

const useStyles = makeStyles(componentStyles)

function Settings() {
    const api = new AdminAPI()
    const eApi = new EnergyAPI()

    const classes = { ...useStyles() }

    const [validationLists, setValidationLists] = useState(null)
    const [showStatus, setShowStatus] = useState('active')
    const [companies, setCompanies] = useState([])
    const [solarCompanies, setSolarCompanies] = useState([])
    const [showCompanies, setShowCompanies] = useState([])
    const [showSolarCompanies, setShowSolarCompanies] = useState([])
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getSettings()
    }, [])

    async function getSettings() {
        try {
            const lists = await api.getValidationLists()
            console.log('validation lists: ', lists)

            const companies = await api.getCompanies()
            console.log('companies: ', companies)
            companies.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
            const activeCompanies = companies.filter((company) => (company.active))

            const solarCompanies = await eApi.getCompanies()
            console.log('solarCompanies: ', solarCompanies)
            solarCompanies.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
            const activeSolarCompanies = solarCompanies.filter((company) => (company.active))

            setValidationLists(lists)
            setCompanies(companies)
            setSolarCompanies(solarCompanies)
            setShowCompanies(activeCompanies)
            setShowSolarCompanies(activeSolarCompanies)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updateCompany(company) {
        const index = companies.findIndex(oldCompany => (oldCompany.companyId === company.companyId))
        console.log('company index: ', index)

        let newCompanies = [...companies]
        newCompanies[index] = company
        let checkStatus = showStatus === 'active' ? true : false
        let showCompanies = newCompanies.filter((company) => (company.active === checkStatus))
        console.log(showCompanies)
        setCompanies(newCompanies)
        setShowCompanies(showCompanies)
    }

    function updateSolarCompany(company) {
        const index = solarCompanies.findIndex(oldCompany => (oldCompany.companyId === company.companyId))
        console.log('solar company index: ', index)

        let newSolarCompanies = [...solarCompanies]
        newSolarCompanies[index] = company
        let checkStatus = showStatus === 'active' ? true : false
        let showSolarCompanies = newSolarCompanies.filter((company) => (company.active === checkStatus))
        console.log(showSolarCompanies)
        setSolarCompanies(newSolarCompanies)
        setShowSolarCompanies(showSolarCompanies)
    }

    function updateShowStatus(showStatus) {
        let checkStatus = showStatus === 'active' ? true : false
        let showCompanies = companies.filter((company) => (company.active === checkStatus))
        let showSolarCompanies = solarCompanies.filter((company) => (company.active === checkStatus))
        setShowCompanies(showCompanies)
        setShowSolarCompanies(showSolarCompanies)
        setShowStatus(showStatus)
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <Grid container>
                        <Grid item xs={12} xl={12}>
                            <Card
                                classes={{
                                    root: classes.cardRoot,
                                }}
                            >
                                <CardHeader
                                    subheader={
                                        <Grid
                                            container
                                            component={Box}
                                            alignItems='center'
                                            justifyContent='space-between'
                                        >
                                            <Grid item xs='auto'>
                                                <Box
                                                    component={Typography}
                                                    variant='h3'
                                                    marginBottom='0!important'
                                                >
                                                    Lead Validation
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }
                                    classes={{ root: classes.cardHeaderRoot }}
                                >
                                </CardHeader>
                                
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={3}>  
                                            <ValidationList 
                                                title={'Profanities (Not Allowed)'}
                                                list={'profanities'}
                                                items={validationLists.profanities.join('\n')}
                                                updateLists={(lists) => setValidationLists(lists)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>  
                                            <ValidationList 
                                                title={'Fake Names (Not Allowed)'}
                                                list={'fakeNames'}
                                                items={validationLists.fakeNames.join('\n')}
                                                updateLists={(lists) => setValidationLists(lists)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>  
                                            <ValidationList 
                                                title={'Famous Names (Not Allowed)'}
                                                list={'famousNames'}
                                                items={validationLists.famousNames.join('\n')}
                                                updateLists={(lists) => setValidationLists(lists)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>  
                                            <ValidationList 
                                                title={'Phone Prefixes (Allowed)'}
                                                list={'phonePrefix'}
                                                items={validationLists.phonePrefix.join('\n')}
                                                updateLists={(lists) => setValidationLists(lists)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{marginTop: '2rem'}}>
                                        <Grid item xs={12}>
                                            <h3>Active Validation Checks</h3>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box style={{color: '#525f7f'}}>
                                                <ul>
                                                    <li>Full Name checked for exact match to Fake Names & Famous Names</li>
                                                    <li>First Name checked for contains Profanities</li>
                                                    <li>Last Name checked for contains Profanities</li>
                                                    <li>Email start (before @) checked for contains Profanities</li>
                                                    <li>Phone checked for starts with Phone Prefixes</li>
                                                    <li>Phone checked for 4 repeated numbers (1111)</li>
                                                    <li>Phone checked for 4 sequential numbers (1234)</li>
                                                </ul>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                component={Box}
                                alignItems='center'
                                justifyContent='flex-start'
                                style={{marginBottom: '2rem'}}
                                direction='row'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '0'}}>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                value={showStatus}
                                                onChange={(e) => updateShowStatus(e.target.value)}
                                            >
                                                <MenuItem value='active'>Active</MenuItem>
                                                <MenuItem value='inactive'>Inactive</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                {/*
                                <Grid item xs={2}>
                                    <a href={getXeroLink()}>Link Xero</a>
                                </Grid>
                                */}
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <CompaniesTable
                                companies={showCompanies}
                                updateCompany={(company) => updateCompany(company)}
                                refreshCompanies={() => getSettings()}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SolarCompaniesTable
                                companies={showSolarCompanies}
                                updateCompany={(company) => updateSolarCompany(company)}
                                refreshCompanies={() => getSettings()}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                            />
                        </Grid>
                    </Grid> 
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default Settings