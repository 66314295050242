import React from 'react'
import Grid from '@material-ui/core/Grid'
import TeamIcon from '@material-ui/icons/Group'
import Check from '@material-ui/icons/Check'
import Add from '@material-ui/icons/Add'
import AccessTime from '@material-ui/icons/AccessTime'
import CardStats from 'components/argon/Cards/Dashboard/CardStats.js'

const AllocationStats = ( props ) => {
    console.log('props: ', props)
    return (
        <Grid container direction='row' spacing={1}>
            <Grid item xs={12} md={props.extraAllocation ? 3 : 4}>
                <CardStats
                    subtitle='Allocation'
                    title={props.allocation}
                    icon={TeamIcon}
                    color='bgWarning'
                />
            </Grid>

            {parseInt(props.extraAllocation) > 0 &&
                <Grid item xs={12} md={props.extraAllocation ? 3 : 4}>
                    <CardStats
                        subtitle='Extra Allocation'
                        title={props.extraAllocation}
                        icon={Add}
                        color='bgPrimary'
                    />
                </Grid>
            }
            
            <Grid item xs={12} md={props.extraAllocation ? 3 : 4}>
                <CardStats
                    subtitle='Delivered'
                    title={props.delivered}
                    icon={Check}
                    color='bgSuccess'
                />
            </Grid>

            <Grid item xs={12} md={props.extraAllocation ? 3 : 4}>
                <CardStats
                    subtitle='Remaining'
                    title={(parseInt(props.allocation) + parseInt(props.extraAllocation)) - parseInt(props.delivered)}
                    icon={AccessTime}
                    color='bgInfo'
                />
            </Grid>
        </Grid>
    )
}

export default AllocationStats
