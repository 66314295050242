import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import { getMoments, getSpApplicationPercentageComplete } from 'config/helpers/Helpers'
import StartPensionStats from 'components/admin/StartPensionStats'
import StartPensionLine from 'components/admin/StartPensionLine'
import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'
import moment from 'moment-timezone'
import PeriodSelect from 'components/general/PeriodSelect'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function StartPension() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const theme = useTheme()
    const api = new AdminAPI()

    const [totals, setTotals] = useState(null)
    const [allApplications, setAllApplications] = useState([])
    const [applications, setApplications] = useState([])
    const [company, setCompany] = useState('al')
    const [period, setPeriod] = useState('thisWeek')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    async function getData() {
        await getApplications(company)
        //setLoading(false)
    }

    async function getApplications(company) {
        setLoading(true)
        try {
            const allApplications = await api.getStartPensionApplications(company)
            console.log('all applications: ', allApplications)

            let dates = getMoments('thisWeek')

            let weekApps = []
            let totals = {
                all: 0,
                loggedIn: 0,
                submitted: 0,
                nph: 0,
                verifiedNph: 0,
                submittedNph: 0,
                pc: 0,
                verifiedPc: 0,
                submittedPc: 0,
                totalContribution: 0,
                totalIncome: 0,
                totalPercentageComplete: 0
            }
            for (var app of allApplications) {
                let created = moment(app.createdAt);
                if (created.isBetween(dates.fromDate, dates.toDate)) {
                    weekApps.push(app)
                    totals.all += 1
                    totals.totalContribution += (parseFloat(app.pensionDetails.contribution) || 0)
                    totals.totalIncome += (parseFloat(app.pensionDetails.annualEarnings) || 0)
                    totals.totalPercentageComplete += getSpApplicationPercentageComplete(app)
                    if (app.lastActive) {
                        totals.loggedIn += 1
                        if (app.source === 'nph') {
                            totals.verifiedNph += 1
                        }
                        if (app.source === 'pc') {
                            totals.verifiedPc += 1
                        }
                    }
                    if (app.submitted) {
                        totals.submitted += 1
                        if (app.source === 'nph') {
                            totals.submittedNph += 1
                        }
                        if (app.source === 'pc') {
                            totals.submittedPc += 1
                        }
                    }
                    if (app.source === 'nph') {
                        totals.nph += 1
                    }
                    if (app.source === 'pc') {
                        totals.pc += 1
                    }
                }
            }
            console.log('week apps: ', weekApps)
            console.log('totals: ', totals)

            setAllApplications(allApplications)
            setApplications(weekApps)
            setTotals(totals)
            setFromDate(dates.fromDate)
            setToDate(dates.toDate)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updatePeriod(period, fromDate=null, toDate=null) {
        setPeriod(period)
        setLoading(true)
        // if custom period and dates supplied, use them, otherwise get based on period
        let dates = period === 'custom' ? {
            fromDate: moment(fromDate).startOf('day'),
            toDate: moment(toDate).endOf('day')
        } : getMoments(period)
        
        let apps = []
        let totals = {
            all: 0,
            loggedIn: 0,
            submitted: 0,
            nph: 0,
            verifiedNph: 0,
            submittedNph: 0,
            pc: 0,
            verifiedPc: 0,
            submittedPc: 0,
            totalContribution: 0,
            totalIncome: 0,
            totalPercentageComplete: 0
        }

        for (var app of allApplications) {
            let created = moment(app.createdAt);
            if (created.isBetween(dates.fromDate, dates.toDate)) {
                apps.push(app)
                totals.all += 1
                totals.totalContribution += (parseFloat(app.pensionDetails.contribution) || 0)
                totals.totalIncome += (parseFloat(app.pensionDetails.annualEarnings) || 0)
                totals.totalPercentageComplete += getSpApplicationPercentageComplete(app)
                if (app.lastActive) {
                    totals.loggedIn += 1
                    if (app.source === 'nph') {
                        totals.verifiedNph += 1
                    }
                    if (app.source === 'pc') {
                        totals.verifiedPc += 1
                    }
                }
                if (app.submitted) {
                    totals.submitted += 1
                    if (app.source === 'nph') {
                        totals.submittedNph += 1
                    }
                    if (app.source === 'pc') {
                        totals.submittedPc += 1
                    }
                }
                if (app.source === 'nph') {
                    totals.nph += 1
                }
                if (app.source === 'pc') {
                    totals.pc += 1
                }
            }
        }
        console.log('period apps: ', apps)

        setTotals(totals)
        setApplications(apps)
        setFromDate(dates.fromDate)
        setToDate(dates.toDate)
        setLoading(false)
    }

    function updateCompany(company) {
        setCompany(company)
        getApplications(company)
        //updatePeriod(period)
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    component={Box}
                                    alignItems='center'
                                    justifyContent='flex-start'
                                    style={{marginBottom: '2rem'}}
                                    direction='row'
                                >
                                    <Grid item xs={12} md={2}>
                                        <FormGroup style={{marginBottom: '0'}}>
                                            <FormControl variant='outlined'>
                                                <Select
                                                    multiple={false}
                                                    defaultValue={null}
                                                    IconComponent={KeyboardArrowDown}
                                                    value={company}
                                                    onChange={(e) => updateCompany(e.target.value)}
                                                >
                                                    <MenuItem value={'al'}>Pension Compare</MenuItem>
                                                    <MenuItem value={'qf'}>Q Financial</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                        <Box
                                            fontSize='12px'
                                            color={theme.palette.gray[600]}
                                            //marginBottom='0.2rem'
                                            //marginLeft='1rem'
                                            marginTop='0.5rem'
                                            textAlign='left'
                                            //className='online-discount'
                                        >
                                            <Box component='small' fontSize='100%'>
                                                Site
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <PeriodSelect
                                            period={period}
                                            updatePeriod={(period, fromDate, toDate) => updatePeriod(period, fromDate, toDate)}
                                            fromDate={fromDate}
                                            toDate={toDate}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <StartPensionStats 
                                    totals={totals}
                                />
                            </Grid>
                        </Grid>
                        <Grid container style={{marginTop: '2rem'}}>
                            <Grid item xs={12}>
                                <p>Total PC: {totals.pc} ({totals.verifiedPc} verified / {totals.submittedPc} submitted)</p>
                                <p>Total NPH: {totals.nph} ({totals.verifiedNph} verified / {totals.submittedNph} submitted)</p>
                                <p>Average Annual Earnings: €{(totals.totalIncome/applications.length).toFixed(2)}</p>
                                <p>Average Pension Contribution: €{(totals.totalContribution/applications.length).toFixed(2)}</p>
                                <p>Average Application Completion: {(totals.totalPercentageComplete/applications.length).toFixed(2)}%</p>
                            </Grid>
                            <Grid item xs={12}>
                                <StartPensionLine 
                                    applications={applications}
                                    period={period}
                                />
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default StartPension