import React from 'react'
import Chart from 'chart.js'
import { Line } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { chartOptions, parseOptions } from 'config/constants/ChartVariables'
import componentStyles from 'assets/theme/components/cards/charts/card-product-comparison.js'
import moment from 'moment-timezone'

const useStyles = makeStyles(componentStyles)

const themeColors = require('assets/theme/colors.js').default

const weekDays = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

var colors = {
    gray: themeColors.gray,
    theme: {
        default: themeColors.dark.main,
        primary: themeColors.primary.main,
        secondary: themeColors.secondary.main,
        info: themeColors.info.main,
        success: themeColors.success.main,
        danger: themeColors.error.main,
        warning: themeColors.warning.main,
    },
    black: themeColors.black.light,
    white: themeColors.white.main,
    transparent: themeColors.transparent.main,
}

const options = {
    scales: {
      yAxes: [
            {
                gridLines: {
                    color: colors.gray[200],
                    zeroLineColor: colors.gray[200],
                },
                ticks: {},
            },
        ],
    },
}

// const options = {
//     scales: {
//       yAxes: [
//             {
//                 gridLines: {
//                     color: colors.gray[200],
//                     zeroLineColor: colors.gray[200],
//                 },
//                 ticks: {},
//             },
//         ],
//     },
// }

function StartPensionLine(props) {
    const classes = useStyles()
    const theme = useTheme()
    if (window.Chart) {
        parseOptions(Chart, chartOptions())
    }

    let verifiedApps = []
    let unverifiedApps = []
    console.log(props.period)
    if (props.period.includes('Week')) {
        for (var day of weekDays) {
            if (props.period === 'thisWeek') {
                if (weekDays.indexOf(day) <= weekDays.indexOf(moment().format('dddd'))) {
                    let verifiedAppTotal = 0
                    let unverifiedAppTotal = 0
                    for (var app of props.applications) {
                        if (moment(app.createdAt).format('dddd') === day) {
                            if (app.lastActive) {
                                verifiedAppTotal += 1
                            }
                            else {
                                unverifiedAppTotal += 1
                            }
                        }
                    }
                    verifiedApps.push(parseFloat(verifiedAppTotal.toFixed(2)))
                    unverifiedApps.push(parseFloat(unverifiedAppTotal.toFixed(2)))
                }
            }
            else {
                let verifiedAppTotal = 0
                let unverifiedAppTotal = 0
                for (var app of props.applications) {
                    if (moment(app.createdAt).format('dddd') === day) {
                        if (app.lastActive) {
                            verifiedAppTotal += 1
                        }
                        else {
                            unverifiedAppTotal += 1
                        }
                    }
                }
                verifiedApps.push(parseFloat(verifiedAppTotal.toFixed(2)))
                unverifiedApps.push(parseFloat(unverifiedAppTotal.toFixed(2)))
            }
        }
    }
    else {
        let monthDays = props.period === 'thisMonth' ? [...Array(moment().daysInMonth()).keys()] : [...Array(moment().subtract(1, 'month').daysInMonth()).keys()]
        for (var day of monthDays) {
            if (props.period === 'thisMonth') {
                if (monthDays.indexOf(day) <= monthDays.indexOf(moment().date())) {
                    let verifiedAppTotal = 0
                    let unverifiedAppTotal = 0
                    for (var app of props.applications) {
                        if (moment(app.createdAt).date() === day) {
                            if (app.lastActive) {
                                verifiedAppTotal += 1
                            }
                            else {
                                unverifiedAppTotal += 1
                            }
                        }
                    }
                    verifiedApps.push(parseFloat(verifiedAppTotal.toFixed(2)))
                    unverifiedApps.push(parseFloat(unverifiedAppTotal.toFixed(2)))
                }
            }
            else {
                let verifiedAppTotal = 0
                let unverifiedAppTotal = 0
                for (var app of props.applications) {
                    if (moment(app.createdAt).date() === day) {
                        if (app.lastActive) {
                            verifiedAppTotal += 1
                        }
                        else {
                            unverifiedAppTotal += 1
                        }
                    }
                }
                verifiedApps.push(parseFloat(verifiedAppTotal.toFixed(2)))
                unverifiedApps.push(parseFloat(unverifiedAppTotal.toFixed(2)))
            }
        }
    }
    console.log('verified: ', verifiedApps)
    console.log('unverified: ', unverifiedApps)

    const data = {
        labels: props.period.includes('Week') ? weekDays : (props.period === 'thisMonth' ? [...Array(moment().daysInMonth()).keys()] : [...Array(moment().subtract(1, 'month').daysInMonth()).keys()]),
        datasets: [
            {
                label: 'Verified Applications',
                data: verifiedApps,
                maxBarThickness: 8,
                borderColor: colors.theme['success'],
                fill: false
                //backgroundColor: colors.theme['info']
            },
            {
                label: 'Unverified Applications',
                data: unverifiedApps,
                maxBarThickness: 8,
                borderColor: colors.theme['info'],
                fill: false
                //backgroundColor: colors.theme['info']
            }
        ]
    }

    function getHeading() {
        if (props.period === 'thisWeek') {
            return 'Applications This Week'
        }
        else if (props.period === 'lastWeek') {
            return 'Applications Last Week'
        }
        else if (props.period === 'thisMonth') {
            return 'Applications This Month'
        }
        else if (props.period === 'lastMonth') {
            return 'Applications Last Month'
        }
    }
  
    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    title={
                        <Box component='span' color={theme.palette.gray[600]}>
                            
                        </Box>
                    }
                    subheader={getHeading()}
                    classes={{ root: classes.cardHeaderRoot }}
                    titleTypographyProps={{
                        component: Box,
                        variant: 'h6',
                        letterSpacing: '2px',
                        marginBottom: '0!important',
                        classes: {
                            root: classes.textUppercase,
                        },
                    }}
                    subheaderTypographyProps={{
                        component: Box,
                        variant: 'h3',
                        marginBottom: '0!important',
                        color: 'initial',
                    }}
                ></CardHeader>
                
                <CardContent>
                    <Box position='relative' height='350px'>
                        <Line
                            data={data}
                            options={options}
                            getDatasetAtEvent={(e) => console.log(e)}
                        />
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}

export default StartPensionLine
