import React from 'react';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close'
import PeopleIcon from '@material-ui/icons/People'
import CheckIcon from '@material-ui/icons/Check'

import CardStats from 'components/argon/Cards/Dashboard/CardStats.js'

const BusinessTotals = ( props ) => {

    const totals = props.totals
    
    return (
        <Grid container direction='row' spacing={1} style={{marginBottom: '2rem'}}>
            <Grid item xs={12} md={3}>
                <CardStats
                    subtitle='Revenue (less returns)'
                    title={'€' + totals.revenue.toFixed(2)}
                    icon={PeopleIcon}
                    color='bgPrimary'
                    // footer={
                    //     <>
                    //         <Box
                    //             component='span'
                    //             fontSize='.875rem'
                    //             color={theme.palette.success.main}
                    //             marginRight='.5rem'
                    //             display='flex'
                    //             alignItems='center'
                    //         >
                    //             <Box
                    //                 component={ArrowUpward}
                    //                 width='1.25rem!important'
                    //                 height='1.25rem!important'
                    //                 marginLeft='-.25rem'
                    //             />{' '}
                    //             10%
                    //         </Box>
                    //         <Box component='span' whiteSpace='nowrap'>
                    //             Since last month
                    //         </Box>
                    //     </>
                    // }
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <CardStats
                    subtitle='Ad Spend'
                    title={'€' + totals.adSpend.toFixed(2)}
                    icon={CheckIcon}
                    color='bgSuccess'
                    // footer={
                    //     <>
                    //         <Box
                    //             component='span'
                    //             fontSize='.875rem'
                    //             color={theme.palette.success.main}
                    //             marginRight='.5rem'
                    //             display='flex'
                    //             alignItems='center'
                    //         >
                    //             <Box
                    //                 component={ArrowUpward}
                    //                 width='1.25rem!important'
                    //                 height='1.25rem!important'
                    //                 marginLeft='-.25rem'
                    //             />{' '}
                    //             20%
                    //         </Box>
                    //         <Box component='span' whiteSpace='nowrap'>
                    //             Since last month
                    //         </Box>
                    //     </>
                    // }
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <CardStats
                    subtitle='Other Costs'
                    title={'€' + totals.otherCosts.toFixed(2)}
                    icon={CloseIcon}
                    color='bgError'
                    disabled={true}
                    // footer={
                    //     <>
                    //         <Box
                    //             component='span'
                    //             fontSize='.875rem'
                    //             color={theme.palette.success.main}
                    //             marginRight='.5rem'
                    //             display='flex'
                    //             alignItems='center'
                    //         >
                    //             <Box
                    //                 component={ArrowUpward}
                    //                 width='1.25rem!important'
                    //                 height='1.25rem!important'
                    //                 marginLeft='-.25rem'
                    //             />{' '}
                    //             10%
                    //         </Box>
                    //         <Box component='span' whiteSpace='nowrap'>
                    //             Since last month
                    //         </Box>
                    //     </>
                    // }
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <CardStats
                    subtitle='Profit'
                    title={'€' + (totals.revenue - (totals.adSpend + totals.otherCosts)).toFixed(2) }
                    icon={CloseIcon}
                    color='bgError'
                    // footer={
                    //     <>
                    //         <Box
                    //             component='span'
                    //             fontSize='.875rem'
                    //             color={theme.palette.success.main}
                    //             marginRight='.5rem'
                    //             display='flex'
                    //             alignItems='center'
                    //         >
                    //             <Box
                    //                 component={ArrowUpward}
                    //                 width='1.25rem!important'
                    //                 height='1.25rem!important'
                    //                 marginLeft='-.25rem'
                    //             />{' '}
                    //             10%
                    //         </Box>
                    //         <Box component='span' whiteSpace='nowrap'>
                    //             Since last month
                    //         </Box>
                    //     </>
                    // }
                />
            </Grid>
        </Grid>
    )
}

export default BusinessTotals
