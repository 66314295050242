import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import ProductsAvailable from 'components/admin/ProductsAvailable'

//import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import componentStylesForms from 'assets/theme/components/forms.js'
import componentStylesButtons from 'assets/theme/components/button.js'

//const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)
const useStylesForms = makeStyles(componentStylesForms)
const useStylesButtons = makeStyles(componentStylesButtons)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function ActiveProductsModal(props) {
	const classes = { ...useStylesDialog(), ...useStylesForms(), ...useStylesButtons() }
  	//const theme = useTheme()
  	const api = new AdminAPI()

    console.log(props.products)

    const [productsAvailable, setProductsAvailable] = useState(props.products)
    
    async function updateProductsAvailable() {
        console.log('products available: ', productsAvailable)
        try {
            let result = await api.updateProductsAvailable(productsAvailable)
            console.log('result: ', result)

  			props.updateProducts(result.productsAvailable)
  			NotificationManager.success('Products updated')
            props.close()
  		} catch(e) {
  			onError(e)
  		}
    }

	return (
        <Dialog
            open={props.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => props.close()}
        >
            {alert}
            <div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Active Products
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
            <DialogContent>
                <FormGroup>
                    <FormControl variant='outlined' fullWidth>
                        <ProductsAvailable 
                            productsAvailable={productsAvailable}
                            updateProductsAvailable={(products) => setProductsAvailable(products)}
                        />
                    </FormControl>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Box width='100%' display='flex' justifyContent='space-between'>
                    <Button onClick={() => props.close()} color='primary'>
                        Close
                    </Button>
                    <Box>
                        <Box display='inline-block' marginRight='0.5rem'>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={() => updateProductsAvailable()}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
	)
}